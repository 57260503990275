.button-base {
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px;
  display: inline-flex;
  gap: 8px;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
}

.button-base .content-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 16px;
  position: relative;
}

.button-base .icon-instance-node {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.button-base .label {
  color: #000000;
  font-family: var(--COMPONENT-BODY-SEMIBOLD-font-family);
  font-size: var(--COMPONENT-BODY-SEMIBOLD-font-size);
  font-style: var(--COMPONENT-BODY-SEMIBOLD-font-style);
  font-weight: var(--COMPONENT-BODY-SEMIBOLD-font-weight);
  letter-spacing: var(--COMPONENT-BODY-SEMIBOLD-letter-spacing);
  line-height: var(--COMPONENT-BODY-SEMIBOLD-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
