.toolbar .property-frame {
  height: 68px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 64px !important;
}

.toolbar {
  align-items: flex-start;
  box-shadow: 0px 4px 16px #9393931f;
  display: inline-flex;
  gap: 24px;
  position: relative;
}

.toolbar .close-wrapper {
  align-items: flex-start;
  background-color: #1f1c1a;
  border-radius: 40px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
  position: relative;
}

.toolbar .img-2 {
  height: 24px;
  position: relative;
  width: 24px;
}

.toolbar .frame-19 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.toolbar .img-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--primary-02);
  border-radius: 40px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
  position: relative;
}
