.text {
  height: 110px;
  width: 843px;
}

.text .lorem-ipsum-is {
  height: 132px;
  top: -12px;
}

.text .span {
  color: #686462;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
}

.text .text-wrapper-11 {
  color: #075a5e;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
}

.text .rectangle {
  height: 44px;
  left: 258px;
  position: absolute;
  top: 12px;
  width: 582px;
}

.text .lorem-ipsum-is-2 {
  color: transparent;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  height: 132px;
  left: 0;
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  position: absolute;
  top: 0;
  width: 843px;
}

.text .text-wrapper-12 {
  color: #504c4a;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
}

.text .highlight {
  align-items: flex-start;
  box-shadow: 8px 12px 24px #00000029;
  display: flex;
  flex-direction: column;
  height: 56px;
  left: 259px;
  position: absolute;
  top: 60px;
  width: 393px;
}

.text .polygon-wrapper {
  height: 4px;
  position: relative;
  width: 39px;
}

.text .polygon {
  height: 4px;
  left: 16px;
  position: absolute;
  top: 0;
  width: 12px;
}

.text .frame-15 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 14px;
  padding: 4px 12px;
  position: relative;
}

.text .frame-16 {
  height: 34px;
  position: relative;
  width: 34px;
}

.text .ellipse {
  background-color: var(--accent-02);
  border-radius: 14px;
  height: 28px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 28px;
}

.text .line {
  height: 44px;
  position: relative;
  width: 1px;
}

.text .ellipse-wrapper {
  height: 34px;
}

.text .ellipse-2 {
  background-color: var(--accent-03);
  border-radius: 14px;
  height: 28px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 28px;
}

.text .ellipse-3 {
  background-color: var(--accent-04);
  border-radius: 14px;
  height: 28px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 28px;
}

.text .ellipse-4 {
  background-color: #cac4c2;
  border-radius: 14px;
  height: 28px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 28px;
}

.text .text-wrapper-13 {
  color: #686462;
  font-family: var(--web-paragraph-DMI-font-family);
  font-size: var(--web-paragraph-DMI-font-size);
  font-style: var(--web-paragraph-DMI-font-style);
  font-weight: var(--web-paragraph-DMI-font-weight);
  letter-spacing: var(--web-paragraph-DMI-letter-spacing);
  line-height: var(--web-paragraph-DMI-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text.property-1-4-default {
  position: relative;
}

.text.property-1-4-variant-4 .lorem-ipsum-is {
  position: relative;
}

.text.property-1-4-variant-2 .lorem-ipsum-is {
  position: relative;
}

.text.property-1-4-variant-3 .lorem-ipsum-is {
  position: relative;
}

.text.property-1-4-variant-5 .lorem-ipsum-is {
  position: relative;
}

.text.property-1-4-default .lorem-ipsum-is {
  color: transparent;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  left: 0;
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  position: absolute;
  width: 843px;
}
