.tab {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 500px;
  justify-content: center;
  padding: 40px 80px 56px;
  position: relative;
  width: 480px;
}

.tab .frame {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  margin-bottom: -4.5px;
  margin-top: -4.5px;
  position: relative;
}

.tab .group-wrapper {
  align-self: stretch;
  height: 190px;
  position: relative;
  width: 100%;
}

.tab .group {
  height: 172px;
  left: 43px;
  position: relative;
  top: 9px;
  width: 235px;
}

.tab .overlap-group {
  height: 172px;
  left: -1px;
  position: relative;
  width: 236px;
}

.tab .vector {
  height: 154px;
  left: 8px;
  position: absolute;
  top: 19px;
  width: 223px;
}

.tab .img {
  height: 172px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 236px;
}

.tab .vector-2 {
  height: 65px;
  left: 61px;
  position: absolute;
  top: 56px;
  width: 121px;
}

.tab .vector-3 {
  height: 4px;
  left: 49px;
  position: absolute;
  top: 121px;
  width: 11px;
}

.tab .vector-4 {
  height: 12px;
  left: 53px;
  position: absolute;
  top: 117px;
  width: 1px;
}

.tab .subtract {
  height: 54px;
  left: 171px;
  position: absolute;
  top: 0;
  width: 32px;
}

.tab .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.tab .frame-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.tab .text-wrapper {
  font-family: var(--web-h5-DMI-font-family);
  font-size: var(--web-h5-DMI-font-size);
  font-style: var(--web-h5-DMI-font-style);
  font-weight: var(--web-h5-DMI-font-weight);
  letter-spacing: var(--web-h5-DMI-letter-spacing);
  line-height: var(--web-h5-DMI-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tab .p {
  align-self: stretch;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  position: relative;
  text-align: center;
}

.tab .button-base-wrapper {
  all: unset;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.tab .div-wrapper {
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  overflow: hidden;
  padding: 16px 14px;
  position: relative;
}

.tab .text-2 {
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.tab.default {
  background-color: #ffffff;
}

.tab.hover {
  background-color: #075a5e;
}

.tab.default .text-wrapper {
  color: #373432;
}

.tab.hover .text-wrapper {
  color: #ffffff;
}

.tab.default .p {
  color: #373432;
}

.tab.hover .p {
  color: #ffffff;
}

.tab.default .div-wrapper {
  border-color: #075a5e;
}

.tab.hover .div-wrapper {
  border-color: #ffffff;
}

.tab.default .text-2 {
  color: #075a5e;
}

.tab.hover .text-2 {
  color: #ffffff;
}
