.component {
  align-items: center;
  display: flex;
  height: 500px;
  justify-content: center;
  padding: 40px 80px 56px;
  position: relative;
  width: 480px;
}

.component .frame-7 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  margin-bottom: -2.5px;
  margin-top: -2.5px;
  position: relative;
}

.component .frame-8 {
  align-self: stretch;
  height: 190px;
  position: relative;
  width: 100%;
}

.component .frame-9 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.component .frame-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.component .content {
  align-self: stretch;
  font-family: var(--web-h5-DMI-font-family);
  font-size: var(--web-h5-DMI-font-size);
  font-style: var(--web-h5-DMI-font-style);
  font-weight: var(--web-h5-DMI-font-weight);
  letter-spacing: var(--web-h5-DMI-letter-spacing);
  line-height: var(--web-h5-DMI-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.component .text-wrapper-3 {
  align-self: stretch;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  position: relative;
  text-align: center;
}

.component .button-3 {
  all: unset;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.component .button-base-3 {
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  overflow: hidden;
  padding: 16px 14px;
  position: relative;
}

.component .text-4 {
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.component.property-1-hover {
  background-color: #075a5e;
}

.component.property-1-default {
  background-color: #ffffff;
}

.component.property-1-hover .content {
  color: #ffffff;
}

.component.property-1-default .content {
  color: #373432;
}

.component.property-1-hover .text-wrapper-3 {
  color: var(--neutral-30);
}

.component.property-1-default .text-wrapper-3 {
  color: var(--neutral-60);
}

.component.property-1-hover .button-base-3 {
  border-color: #ffffff;
}

.component.property-1-default .button-base-3 {
  border-color: #075a5e;
}

.component.property-1-hover .text-4 {
  color: #ffffff;
}

.component.property-1-default .text-4 {
  color: #075a5e;
}
