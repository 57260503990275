.upvote {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 16px 14px;
  position: relative;
}

.upvote .frame-17 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.upvote .favorite {
  height: 24px;
  position: relative;
  width: 24px;
}

.upvote .i-use-this {
  color: var(--neutral-60);
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.upvote .frame-18 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.upvote .text-wrapper-14 {
  color: #f3eff5;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.upvote.property-1-6-default {
  border: 1px solid;
  border-color: #cac4c2;
  height: 82px;
  width: 82px;
}

.upvote.property-1-6-variant-3 {
  border: 1px solid;
  border-color: #cac4c2;
  height: 82px;
  width: 82px;
}

.upvote.property-1-6-selected {
  background-color: var(--primary-02);
  height: 80px;
  width: 80px;
}

.upvote.property-1-6-default .frame-17 {
  margin-left: -1.5px;
}

.upvote.property-1-6-selected .frame-17 {
  margin-left: -1.5px;
  margin-right: -1.5px;
}
