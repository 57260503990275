.button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
}

.button .button-base-5 {
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.button .text-6 {
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.button .icons-2 {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.button.type-outlined {
  display: inline-flex;
}

.button.large {
  border-radius: 4px;
}

.button.type-secondary {
  display: inline-flex;
}

.button.small {
  border-radius: 4px;
  display: inline-flex;
}

.button.medium {
  border-radius: 4px;
}

.button.tiny {
  border-radius: 32px;
  display: inline-flex;
}

.button.type-primary {
  display: inline-flex;
}

.button.x-small {
  border-radius: 4px;
  display: inline-flex;
}

.button.type-error {
  display: inline-flex;
}

.button.big {
  border-radius: 4px;
  display: inline-flex;
}

.button.large.type-primary {
  flex-direction: column;
}

.button.type-text.medium {
  display: flex;
  width: 105px;
}

.button.type-text.large {
  display: flex;
  width: 127px;
}

.button.type-outlined .button-base-5 {
  border: 1px solid;
  border-color: var(--neutral-40);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
}

.button.large .button-base-5 {
  border-radius: 4px;
}

.button.type-secondary .button-base-5 {
  background-color: var(--primary-98);
  border-color: var(--primary-20);
  box-shadow: var(--shadow-xs);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
}

.button.small .button-base-5 {
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 12px 14px;
}

.button.type-text .button-base-5 {
  gap: 8px;
}

.button.medium .button-base-5 {
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
}

.button.tiny .button-base-5 {
  border-radius: 32px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px;
}

.button.type-primary .button-base-5 {
  background-color: var(--primary-30);
  border: 1px solid;
  border-color: var(--primary-30);
  box-shadow: var(--shadow-xs);
  flex: 0 0 auto;
}

.button.x-small .button-base-5 {
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 10px 14px;
}

.button.type-error .button-base-5 {
  background-color: var(--error-60);
  border: 1px solid;
  border-color: var(--error-60);
  box-shadow: var(--shadow-xs);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
}

.button.big .button-base-5 {
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
}

.button.big.type-secondary .button-base-5 {
  border: 1.2px solid;
  padding: 14px;
}

.button.large.type-secondary .button-base-5 {
  border: 1.2px solid;
  padding: 18px 32px;
}

.button.large.type-primary .button-base-5 {
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
  width: 165px;
}

.button.type-text.big .button-base-5 {
  padding: 12px 14px;
}

.button.big.type-error .button-base-5 {
  padding: 14px;
}

.button.x-small.type-secondary .button-base-5 {
  border: 1.2px solid;
}

.button.type-error.medium .button-base-5 {
  padding: 14px;
}

.button.medium.type-primary .button-base-5 {
  padding: 14px;
}

.button.big.type-outlined .button-base-5 {
  padding: 14px;
}

.button.large.type-outlined .button-base-5 {
  padding: 18px 32px;
}

.button.type-text.medium .button-base-5 {
  padding: 12px 14px;
}

.button.medium.type-secondary .button-base-5 {
  border: 1px solid;
  padding: 14px;
}

.button.medium.type-outlined .button-base-5 {
  padding: 14px;
}

.button.type-error.large .button-base-5 {
  padding: 18px 32px;
}

.button.small.type-secondary .button-base-5 {
  border: 1.2px solid;
}

.button.type-text.large .button-base-5 {
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding: 18px 14px;
}

.button.big.type-primary .button-base-5 {
  padding: 16px 14px;
}

.button.tiny.type-secondary .button-base-5 {
  border: 1.2px solid;
}

.button.type-outlined .text-6 {
  color: var(--neutral-40);
  margin-top: -1px;
}

.button.type-secondary .text-6 {
  color: var(--primary-20);
}

.button.small .text-6 {
  font-family: var(--caption-01-SEMI-BLD-font-family);
  font-size: var(--caption-01-SEMI-BLD-font-size);
  font-style: var(--caption-01-SEMI-BLD-font-style);
  font-weight: var(--caption-01-SEMI-BLD-font-weight);
  letter-spacing: var(--caption-01-SEMI-BLD-letter-spacing);
  line-height: var(--caption-01-SEMI-BLD-line-height);
}

.button.type-text .text-6 {
  color: #7c7b7b;
  margin-top: -1px;
}

.button.medium .text-6 {
  font-family: var(--caption-01-SEMI-BLD-font-family);
  font-size: var(--caption-01-SEMI-BLD-font-size);
  font-style: var(--caption-01-SEMI-BLD-font-style);
  font-weight: var(--caption-01-SEMI-BLD-font-weight);
  letter-spacing: var(--caption-01-SEMI-BLD-letter-spacing);
  line-height: var(--caption-01-SEMI-BLD-line-height);
  margin-top: -1px;
}

.button.tiny .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.type-primary .text-6 {
  color: var(--neutral-100);
  margin-top: -1px;
}

.button.x-small .text-6 {
  font-family: var(--caption-02-SEMI-BLD-font-family);
  font-size: var(--caption-02-SEMI-BLD-font-size);
  font-style: var(--caption-02-SEMI-BLD-font-style);
  font-weight: var(--caption-02-SEMI-BLD-font-weight);
  letter-spacing: var(--caption-02-SEMI-BLD-letter-spacing);
  line-height: var(--caption-02-SEMI-BLD-line-height);
}

.button.type-error .text-6 {
  color: var(--neutral-100);
  margin-top: -1px;
}

.button.big.type-secondary .text-6 {
  font-family: var(--paragraph-BLD-font-family);
  font-size: var(--paragraph-BLD-font-size);
  font-style: var(--paragraph-BLD-font-style);
  font-weight: var(--paragraph-BLD-font-weight);
  letter-spacing: var(--paragraph-BLD-letter-spacing);
  line-height: var(--paragraph-BLD-line-height);
  margin-top: -1.2px;
}

.button.large.type-secondary .text-6 {
  font-family: var(--SUBHEAD-BLD-font-family);
  font-size: var(--SUBHEAD-BLD-font-size);
  font-style: var(--SUBHEAD-BLD-font-style);
  font-weight: var(--SUBHEAD-BLD-font-weight);
  letter-spacing: var(--SUBHEAD-BLD-letter-spacing);
  line-height: var(--SUBHEAD-BLD-line-height);
  margin-top: -1.2px;
}

.button.large.type-primary .text-6 {
  font-family: var(--SUBHEAD-BLD-font-family);
  font-size: var(--SUBHEAD-BLD-font-size);
  font-style: var(--SUBHEAD-BLD-font-style);
  font-weight: var(--SUBHEAD-BLD-font-weight);
  letter-spacing: var(--SUBHEAD-BLD-letter-spacing);
  line-height: var(--SUBHEAD-BLD-line-height);
}

.button.type-text.big .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.big.type-error .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.x-small.type-secondary .text-6 {
  margin-top: -1.2px;
}

.button.big.type-outlined .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.large.type-outlined .text-6 {
  font-family: var(--SUBHEAD-BLD-font-family);
  font-size: var(--SUBHEAD-BLD-font-size);
  font-style: var(--SUBHEAD-BLD-font-style);
  font-weight: var(--SUBHEAD-BLD-font-weight);
  letter-spacing: var(--SUBHEAD-BLD-letter-spacing);
  line-height: var(--SUBHEAD-BLD-line-height);
}

.button.type-error.large .text-6 {
  font-family: var(--SUBHEAD-BLD-font-family);
  font-size: var(--SUBHEAD-BLD-font-size);
  font-style: var(--SUBHEAD-BLD-font-style);
  font-weight: var(--SUBHEAD-BLD-font-weight);
  letter-spacing: var(--SUBHEAD-BLD-letter-spacing);
  line-height: var(--SUBHEAD-BLD-line-height);
}

.button.small.type-secondary .text-6 {
  margin-top: -1.2px;
}

.button.type-text.large .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.big.type-primary .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.tiny.type-secondary .text-6 {
  margin-top: -1.2px;
}
