.nav-labels {
  align-items: center;
  border-radius: 32px;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.nav-labels .placeholder-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.nav-labels .placeholder-2 {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.nav-labels .instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.nav-labels .placeholder-3 {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.nav-labels .placeholder-4 {
  color: var(--neutral-60);
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.nav-labels .placeholder-5 {
  color: #999492;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.nav-labels.with-icon-true {
  gap: 4px;
  padding: 4px 12px 4px 8px;
}

.nav-labels.with-icon-false {
  gap: 10px;
  padding: 6px 8px 6px 12px;
}

.nav-labels.state-9-default.theme-light {
  border: 1px solid;
  border-color: #686462;
}

.nav-labels.state-9-hover.theme-light {
  background-color: var(--neutral-20);
  border: 1px solid;
  border-color: #cac4c2;
}

.nav-labels.theme-light.state-9-disabled {
  border: 1px solid;
  border-color: #cac4c2;
}

.nav-labels.theme-dark.state-9-selected {
  background-color: var(--accent-01);
}

.nav-labels.state-9-selected.theme-light {
  background-color: var(--neutral-90);
}

.nav-labels.theme-dark.state-9-hover {
  background-color: var(--overlay-32);
}

.nav-labels.state-9-selected .placeholder-2 {
  font-family: var(--web-caption-DMI-font-family);
  font-size: var(--web-caption-DMI-font-size);
  font-style: var(--web-caption-DMI-font-style);
  font-weight: var(--web-caption-DMI-font-weight);
  letter-spacing: var(--web-caption-DMI-letter-spacing);
  line-height: var(--web-caption-DMI-line-height);
}

.nav-labels.state-9-disabled .placeholder-2 {
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
}

.nav-labels.state-9-hover .placeholder-2 {
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
}

.nav-labels.state-9-default .placeholder-2 {
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
}

.nav-labels.theme-dark.state-9-disabled .placeholder-2 {
  color: var(--neutral-30);
}

.nav-labels.state-9-default.theme-light .placeholder-2 {
  color: var(--neutral-60);
}

.nav-labels.theme-dark.state-9-default .placeholder-2 {
  color: #ffffff;
}

.nav-labels.state-9-selected.theme-light .placeholder-2 {
  color: #ffffff;
}

.nav-labels.theme-light.state-9-disabled .placeholder-2 {
  color: #999492;
}

.nav-labels.state-9-hover.theme-light .placeholder-2 {
  color: var(--neutral-60);
}

.nav-labels.theme-dark.state-9-hover .placeholder-2 {
  color: var(--accent-01);
}

.nav-labels.theme-dark.state-9-selected .placeholder-2 {
  color: #1f1c1a;
}

.nav-labels.state-9-selected .placeholder-3 {
  color: #ffffff;
  font-family: var(--web-caption-DMI-font-family);
  font-size: var(--web-caption-DMI-font-size);
  font-style: var(--web-caption-DMI-font-style);
  font-weight: var(--web-caption-DMI-font-weight);
  letter-spacing: var(--web-caption-DMI-letter-spacing);
  line-height: var(--web-caption-DMI-line-height);
}

.nav-labels.state-9-disabled .placeholder-3 {
  color: #ffffff;
  font-family: var(--web-caption-DMI-font-family);
  font-size: var(--web-caption-DMI-font-size);
  font-style: var(--web-caption-DMI-font-style);
  font-weight: var(--web-caption-DMI-font-weight);
  letter-spacing: var(--web-caption-DMI-letter-spacing);
  line-height: var(--web-caption-DMI-line-height);
}

.nav-labels.state-9-default .placeholder-3 {
  color: #ffffff;
  font-family: var(--web-caption-DMI-font-family);
  font-size: var(--web-caption-DMI-font-size);
  font-style: var(--web-caption-DMI-font-style);
  font-weight: var(--web-caption-DMI-font-weight);
  letter-spacing: var(--web-caption-DMI-letter-spacing);
  line-height: var(--web-caption-DMI-line-height);
}

.nav-labels.state-9-hover .placeholder-3 {
  color: #686462;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
}
