.header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 64px;
  justify-content: center;
  padding: 8px 64px;
  position: relative;
  width: 1280px;
}

.header .container-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.header .div-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.header .humble-logo {
  height: 40px;
  position: relative;
  width: 100px;
}

.header .frame-12 {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 280px;
}

.header .frame-13 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  padding: 8px 12px;
  position: relative;
}

.header .text-wrapper-4 {
  color: #075a5e;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  margin-right: -12px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .text-wrapper-5 {
  color: #075a5e;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .text-wrapper-6 {
  color: #075a5e;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  margin-right: -11px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .frame-14 {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 285px;
}

.header .form-search-form {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.header .div-container {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #cac4c2;
  border-left-style: solid;
  border-left-width: 1px;
  border-radius: 40px 0px 0px 40px;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: inset 0px 1px 2px #eeeeee;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.header .search {
  align-items: flex-start;
  position: relative;
}

.header .div-placeholder {
  position: relative;
}

.header .text-wrapper-7 {
  margin-top: -1px;
  position: relative;
}

.header .search-2 {
  position: relative;
}

.header .CTA-s {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.header .text-wrapper-8 {
  font-family: var(--web-caption-BLD-font-family);
  font-size: var(--web-caption-BLD-font-size);
  font-style: var(--web-caption-BLD-font-style);
  font-weight: var(--web-caption-BLD-font-weight);
  letter-spacing: var(--web-caption-BLD-letter-spacing);
  line-height: var(--web-caption-BLD-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .button-4 {
  all: unset;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.header .button-base-4 {
  all: unset;
  align-items: center;
  background-color: var(--primary-02);
  border-radius: 4px;
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
}

.header .text-5 {
  color: #ffffff;
  font-family: var(--web-caption-BLD-font-family);
  font-size: var(--web-caption-BLD-font-size);
  font-style: var(--web-caption-BLD-font-style);
  font-weight: var(--web-caption-BLD-font-weight);
  letter-spacing: var(--web-caption-BLD-letter-spacing);
  line-height: var(--web-caption-BLD-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.header.primary-dark {
  background-color: #075a5e;
}

.header.light {
  background-color: #ffffff;
}

.header.secondary-light {
  background-color: var(--accent-01);
}

.header.secondary-dark {
  background-color: #373432;
}

.header.primary-dark .form-search-form {
  align-self: stretch;
}

.header.secondary-dark .form-search-form {
  align-self: stretch;
}

.header.primary-dark .div-container {
  align-self: stretch;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 7px 12px 7px 14px;
}

.header.light .div-container {
  padding: 7px 4px 7px 17px;
}

.header.secondary-light .div-container {
  padding: 7px 4px 7px 17px;
}

.header.secondary-dark .div-container {
  align-self: stretch;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 7px 12px 7px 14px;
}

.header.primary-dark .search {
  display: flex;
  flex: 1;
  flex-grow: 1;
}

.header.light .search {
  display: inline-flex;
  flex: 0 0 auto;
  margin-right: -303px;
  padding: 3px 0px 4px;
}

.header.secondary-light .search {
  display: inline-flex;
  flex: 0 0 auto;
  margin-right: -303px;
  padding: 3px 0px 4px;
}

.header.secondary-dark .search {
  display: flex;
  flex: 1;
  flex-grow: 1;
}

.header.primary-dark .div-placeholder {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
}

.header.light .div-placeholder {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 0px 469px 0px 0px;
}

.header.secondary-light .div-placeholder {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 0px 469px 0px 0px;
}

.header.secondary-dark .div-placeholder {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
}

.header.primary-dark .text-wrapper-7 {
  color: #999492;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  height: 24px;
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  width: 45px;
}

.header.light .text-wrapper-7 {
  color: #686462;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  width: fit-content;
}

.header.secondary-light .text-wrapper-7 {
  color: #686462;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  width: fit-content;
}

.header.secondary-dark .text-wrapper-7 {
  color: #999492;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  height: 24px;
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  width: 45px;
}

.header.primary-dark .search-2 {
  align-self: stretch;
  flex: 0 0 auto;
  margin-bottom: -1px;
  margin-right: -1px;
  margin-top: -1px;
}

.header.light .search-2 {
  width: 48px;
}

.header.secondary-light .search-2 {
  width: 48px;
}

.header.secondary-dark .search-2 {
  align-self: stretch;
  flex: 0 0 auto;
  margin-bottom: -1px;
  margin-right: -1px;
  margin-top: -1px;
}

.header.primary-dark .text-wrapper-8 {
  color: #ffffff;
}

.header.light .text-wrapper-8 {
  color: #075a5e;
}

.header.secondary-light .text-wrapper-8 {
  color: #075a5e;
}

.header.secondary-dark .text-wrapper-8 {
  color: #ffffff;
}

.header.primary-dark .button-4 {
  background-color: var(--primary-02);
}

.header.light .button-4 {
  background-color: #ffffff;
}

.header.secondary-light .button-4 {
  background-color: #ffffff;
}

.header.secondary-dark .button-4 {
  background-color: var(--primary-02);
}
