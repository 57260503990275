.avatar {
  height: 55px;
  position: relative;
  width: 216px;
}

.avatar .text-wrapper-9 {
  color: var(--white);
  font-family: var(--h5-font-family);
  font-size: var(--h5-font-size);
  font-style: var(--h5-font-style);
  font-weight: var(--h5-font-weight);
  left: 73px;
  letter-spacing: var(--h5-letter-spacing);
  line-height: var(--h5-line-height);
  position: absolute;
  text-align: center;
  top: -1px;
}

.avatar .text-wrapper-10 {
  color: var(--primary-light);
  font-family: var(--body-medium-font-family);
  font-size: var(--body-medium-font-size);
  font-style: var(--body-medium-font-style);
  font-weight: var(--body-medium-font-weight);
  left: 75px;
  letter-spacing: var(--body-medium-letter-spacing);
  line-height: var(--body-medium-line-height);
  position: absolute;
  text-align: center;
  top: 30px;
  white-space: nowrap;
}

.avatar .image {
  height: 53px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 53px;
}
