:root {
  --accent-01: #f4fff8;
  --accent-02: #e0fbfc;
  --accent-03: #e9e6ff;
  --accent-04: #ffddd2;
  --accent-05: #fffcf9;
  --baseblack: #000;
  --basewhite: #fff;
  --body-medium-font-family: "PlusJakarta Sans-Regular", Helvetica;
  --body-medium-font-size: 16px;
  --body-medium-font-style: normal;
  --body-medium-font-weight: 400;
  --body-medium-letter-spacing: 0px;
  --body-medium-line-height: 150%;
  --caption-01-SEMI-BLD-font-family: "Inter-SemiBold", Helvetica;
  --caption-01-SEMI-BLD-font-size: 14px;
  --caption-01-SEMI-BLD-font-style: normal;
  --caption-01-SEMI-BLD-font-weight: 600;
  --caption-01-SEMI-BLD-letter-spacing: -.056px;
  --caption-01-SEMI-BLD-line-height: 140.0%;
  --caption-02-SEMI-BLD-font-family: "Inter-SemiBold", Helvetica;
  --caption-02-SEMI-BLD-font-size: 12px;
  --caption-02-SEMI-BLD-font-style: normal;
  --caption-02-SEMI-BLD-font-weight: 600;
  --caption-02-SEMI-BLD-letter-spacing: 0px;
  --caption-02-SEMI-BLD-line-height: 140.0%;
  --COMPONENT-BODY-SEMIBOLD-font-family: "Inter-SemiBold", Helvetica;
  --COMPONENT-BODY-SEMIBOLD-font-size: 14px;
  --COMPONENT-BODY-SEMIBOLD-font-style: normal;
  --COMPONENT-BODY-SEMIBOLD-font-weight: 600;
  --COMPONENT-BODY-SEMIBOLD-letter-spacing: 0px;
  --COMPONENT-BODY-SEMIBOLD-line-height: 100%;
  --error-60: #ff6d7d;
  --h5-font-family: "PlusJakarta Sans-Bold", Helvetica;
  --h5-font-size: 18px;
  --h5-font-style: normal;
  --h5-font-weight: 700;
  --h5-letter-spacing: 0px;
  --h5-line-height: normal;
  --inter-semi-bold-16px-font-family: "Inter-Medium", Helvetica;
  --inter-semi-bold-16px-font-size: 16px;
  --inter-semi-bold-16px-font-style: normal;
  --inter-semi-bold-16px-font-weight: 500;
  --inter-semi-bold-16px-letter-spacing: 0px;
  --inter-semi-bold-16px-line-height: 24px;
  --linearwhite: #fff;
  --mobile-caption-BLD-font-family: "ArticulatCF-Bold", Helvetica;
  --mobile-caption-BLD-font-size: 12px;
  --mobile-caption-BLD-font-style: normal;
  --mobile-caption-BLD-font-weight: 700;
  --mobile-caption-BLD-letter-spacing: 0px;
  --mobile-caption-BLD-line-height: 120%;
  --mobile-caption-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --mobile-caption-DMI-font-size: 12px;
  --mobile-caption-DMI-font-style: normal;
  --mobile-caption-DMI-font-weight: 700;
  --mobile-caption-DMI-letter-spacing: 0px;
  --mobile-caption-DMI-line-height: 120%;
  --mobile-caption-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --mobile-caption-REG-font-size: 12px;
  --mobile-caption-REG-font-style: normal;
  --mobile-caption-REG-font-weight: 500;
  --mobile-caption-REG-letter-spacing: 0px;
  --mobile-caption-REG-line-height: 120%;
  --mobile-h2-DMI-font-family: "LeMonde Livre Std-Demi", Helvetica;
  --mobile-h2-DMI-font-size: 28px;
  --mobile-h2-DMI-font-style: normal;
  --mobile-h2-DMI-font-weight: 400;
  --mobile-h2-DMI-letter-spacing: -.784px;
  --mobile-h2-DMI-line-height: 120%;
  --mobile-h3-BLD-font-family: "LeMonde Livre Std-Bold", Helvetica;
  --mobile-h3-BLD-font-size: 25px;
  --mobile-h3-BLD-font-style: normal;
  --mobile-h3-BLD-font-weight: 700;
  --mobile-h3-BLD-letter-spacing: -.35px;
  --mobile-h3-BLD-line-height: 120%;
  --mobile-h4-BLD-font-family: "LeMonde Livre Std-Bold", Helvetica;
  --mobile-h4-BLD-font-size: 24px;
  --mobile-h4-BLD-font-style: normal;
  --mobile-h4-BLD-font-weight: 700;
  --mobile-h4-BLD-letter-spacing: -.12px;
  --mobile-h4-BLD-line-height: 120%;
  --mobile-h5-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --mobile-h5-DMI-font-size: 20px;
  --mobile-h5-DMI-font-style: normal;
  --mobile-h5-DMI-font-weight: 700;
  --mobile-h5-DMI-letter-spacing: -.16px;
  --mobile-h5-DMI-line-height: 120%;
  --mobile-h6-DMI-font-family: "LeMonde Livre Cla Std-Demi", Helvetica;
  --mobile-h6-DMI-font-size: 18px;
  --mobile-h6-DMI-font-style: normal;
  --mobile-h6-DMI-font-weight: 400;
  --mobile-h6-DMI-letter-spacing: -.072px;
  --mobile-h6-DMI-line-height: 140.0%;
  --mobile-h6-REG-font-family: "LeMonde Livre Cla Std-Regular", Helvetica;
  --mobile-h6-REG-font-size: 18px;
  --mobile-h6-REG-font-style: normal;
  --mobile-h6-REG-font-weight: 400;
  --mobile-h6-REG-letter-spacing: 0px;
  --mobile-h6-REG-line-height: 140.0%;
  --mobile-paragraph-BLD-font-family: "ArticulatCF-Bold", Helvetica;
  --mobile-paragraph-BLD-font-size: 14px;
  --mobile-paragraph-BLD-font-style: normal;
  --mobile-paragraph-BLD-font-weight: 700;
  --mobile-paragraph-BLD-letter-spacing: 0px;
  --mobile-paragraph-BLD-line-height: 140.0%;
  --mobile-paragraph-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --mobile-paragraph-DMI-font-size: 14px;
  --mobile-paragraph-DMI-font-style: normal;
  --mobile-paragraph-DMI-font-weight: 700;
  --mobile-paragraph-DMI-letter-spacing: 0px;
  --mobile-paragraph-DMI-line-height: 140.0%;
  --mobile-paragraph-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --mobile-paragraph-REG-font-size: 14px;
  --mobile-paragraph-REG-font-style: normal;
  --mobile-paragraph-REG-font-weight: 500;
  --mobile-paragraph-REG-letter-spacing: 0px;
  --mobile-paragraph-REG-line-height: 140.0%;
  --mobile-tiny-MED-font-family: "ArticulatCF-Medium", Helvetica;
  --mobile-tiny-MED-font-size: 11px;
  --mobile-tiny-MED-font-style: normal;
  --mobile-tiny-MED-font-weight: 500;
  --mobile-tiny-MED-letter-spacing: 0px;
  --mobile-tiny-MED-line-height: 140.0%;
  --mobile-tiny-reg-font-family: "ArticulatCF-Regular", Helvetica;
  --mobile-tiny-reg-font-size: 11px;
  --mobile-tiny-reg-font-style: normal;
  --mobile-tiny-reg-font-weight: 400;
  --mobile-tiny-reg-letter-spacing: 0px;
  --mobile-tiny-reg-line-height: 140.0%;
  --neutral-10: #fff;
  --neutral-100: #fff;
  --neutral-20: #fbfbfb;
  --neutral-30: #eee;
  --neutral-40: #666;
  --neutral-50: #696462;
  --neutral-60: #504c4a;
  --neutral-70: #383432;
  --neutral-80: #1f1c1a;
  --neutral-90: #070402;
  --neutral-dark-grey: #7e7e7e;
  --neutral-grey: #b0b0b0;
  --neutrals-0: #fff;
  --neutrals-10: #adafae;
  --neutrals-30: #5c605e;
  --neutralwhite: #fff;
  --overlay-32: #ffffff3d;
  --paragraph-BLD-font-family: "Inter-Bold", Helvetica;
  --paragraph-BLD-font-size: 16px;
  --paragraph-BLD-font-style: normal;
  --paragraph-BLD-font-weight: 700;
  --paragraph-BLD-letter-spacing: -.064px;
  --paragraph-BLD-line-height: 140.0%;
  --paragraph-SEMI-BLD-font-family: "Inter-SemiBold", Helvetica;
  --paragraph-SEMI-BLD-font-size: 16px;
  --paragraph-SEMI-BLD-font-style: normal;
  --paragraph-SEMI-BLD-font-weight: 600;
  --paragraph-SEMI-BLD-letter-spacing: -.064px;
  --paragraph-SEMI-BLD-line-height: 140.0%;
  --primary-01: #294d4a;
  --primary-02: #44bba4;
  --primary-03: #f3eff5;
  --primary-20: #3c7df3;
  --primary-30: #518cf5;
  --primary-98: #f5f9ff;
  --primary-light: #dcf2ed;
  --secondary-02: #2f52e0;
  --secondary-03: #826aed;
  --secondary-04: #f78764;
  --secondary-05: #f7b32b;
  --shadow-xs: 0px 1px 2px 0px #1018280d;
  --SUBHEAD-BLD-font-family: "Inter-Bold", Helvetica;
  --SUBHEAD-BLD-font-size: 18px;
  --SUBHEAD-BLD-font-style: normal;
  --SUBHEAD-BLD-font-weight: 700;
  --SUBHEAD-BLD-letter-spacing: -.144px;
  --SUBHEAD-BLD-line-height: 120%;
  --tertiary-01: #104212;
  --tertiary-02: #080357;
  --tertiary-05: #c8963e;
  --ui-essentialsneutralntrl100: #fff;
  --web-caption-BLD-font-family: "ArticulatCF-Bold", Helvetica;
  --web-caption-BLD-font-size: 14px;
  --web-caption-BLD-font-style: normal;
  --web-caption-BLD-font-weight: 700;
  --web-caption-BLD-letter-spacing: 0px;
  --web-caption-BLD-line-height: 140.0%;
  --web-caption-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --web-caption-DMI-font-size: 14px;
  --web-caption-DMI-font-style: normal;
  --web-caption-DMI-font-weight: 700;
  --web-caption-DMI-letter-spacing: 0px;
  --web-caption-DMI-line-height: 140.0%;
  --web-caption-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --web-caption-REG-font-size: 14px;
  --web-caption-REG-font-style: normal;
  --web-caption-REG-font-weight: 500;
  --web-caption-REG-letter-spacing: 0px;
  --web-caption-REG-line-height: 140.0%;
  --web-h2-DMI-font-family: "LeMonde Livre Std-Demi", Helvetica;
  --web-h2-DMI-font-size: 48px;
  --web-h2-DMI-font-style: normal;
  --web-h2-DMI-font-weight: 400;
  --web-h2-DMI-letter-spacing: -1.344px;
  --web-h2-DMI-line-height: 120%;
  --web-h3-bold-font-family: "LeMonde Livre Std-Bold", Helvetica;
  --web-h3-bold-font-size: 44px;
  --web-h3-bold-font-style: normal;
  --web-h3-bold-font-weight: 700;
  --web-h3-bold-letter-spacing: -.616px;
  --web-h3-bold-line-height: 120%;
  --web-h5-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --web-h5-DMI-font-size: 28px;
  --web-h5-DMI-font-style: normal;
  --web-h5-DMI-font-weight: 700;
  --web-h5-DMI-letter-spacing: -.224px;
  --web-h5-DMI-line-height: 120%;
  --web-h6-DMI-font-family: "LeMonde Livre Cla Std-Demi", Helvetica;
  --web-h6-DMI-font-size: 20px;
  --web-h6-DMI-font-style: normal;
  --web-h6-DMI-font-weight: 400;
  --web-h6-DMI-letter-spacing: -.08px;
  --web-h6-DMI-line-height: 140.0%;
  --web-paragraph-BLD-font-family: "ArticulatCF-Bold", Helvetica;
  --web-paragraph-BLD-font-size: 16px;
  --web-paragraph-BLD-font-style: normal;
  --web-paragraph-BLD-font-weight: 700;
  --web-paragraph-BLD-letter-spacing: 0px;
  --web-paragraph-BLD-line-height: 140.0%;
  --web-paragraph-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --web-paragraph-DMI-font-size: 16px;
  --web-paragraph-DMI-font-style: normal;
  --web-paragraph-DMI-font-weight: 700;
  --web-paragraph-DMI-letter-spacing: 0px;
  --web-paragraph-DMI-line-height: 140.0%;
  --web-paragraph-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --web-paragraph-REG-font-size: 16px;
  --web-paragraph-REG-font-style: normal;
  --web-paragraph-REG-font-weight: 500;
  --web-paragraph-REG-letter-spacing: 0px;
  --web-paragraph-REG-line-height: 140.0%;
  --web-tiny-MED-font-family: "ArticulatCF-DemiBold", Helvetica;
  --web-tiny-MED-font-size: 12px;
  --web-tiny-MED-font-style: normal;
  --web-tiny-MED-font-weight: 700;
  --web-tiny-MED-letter-spacing: 0px;
  --web-tiny-MED-line-height: 140.0%;
  --web-tiny-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --web-tiny-REG-font-size: 12px;
  --web-tiny-REG-font-style: normal;
  --web-tiny-REG-font-weight: 500;
  --web-tiny-REG-letter-spacing: 0px;
  --web-tiny-REG-line-height: 140.0%;
  --white: #fff;
  --x-20: #e2dfde;
}

.avatar {
  width: 216px;
  height: 55px;
  position: relative;
}

.avatar .text-wrapper-9 {
  color: var(--white);
  font-family: var(--h5-font-family);
  font-size: var(--h5-font-size);
  font-style: var(--h5-font-style);
  font-weight: var(--h5-font-weight);
  letter-spacing: var(--h5-letter-spacing);
  line-height: var(--h5-line-height);
  text-align: center;
  position: absolute;
  top: -1px;
  left: 73px;
}

.avatar .text-wrapper-10 {
  color: var(--primary-light);
  font-family: var(--body-medium-font-family);
  font-size: var(--body-medium-font-size);
  font-style: var(--body-medium-font-style);
  font-weight: var(--body-medium-font-weight);
  letter-spacing: var(--body-medium-letter-spacing);
  line-height: var(--body-medium-line-height);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 30px;
  left: 75px;
}

.avatar .image {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 2px;
  left: 0;
}

.button {
  all: unset;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: relative;
}

.button .button-base-5 {
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.button .text-6 {
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.button .icons-2 {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.button.type-outlined {
  display: inline-flex;
}

.button.large {
  border-radius: 4px;
}

.button.type-secondary {
  display: inline-flex;
}

.button.small {
  border-radius: 4px;
  display: inline-flex;
}

.button.medium {
  border-radius: 4px;
}

.button.tiny {
  border-radius: 32px;
  display: inline-flex;
}

.button.type-primary {
  display: inline-flex;
}

.button.x-small {
  border-radius: 4px;
  display: inline-flex;
}

.button.type-error {
  display: inline-flex;
}

.button.big {
  border-radius: 4px;
  display: inline-flex;
}

.button.large.type-primary {
  flex-direction: column;
}

.button.type-text.medium {
  width: 105px;
  display: flex;
}

.button.type-text.large {
  width: 127px;
  display: flex;
}

.button.type-outlined .button-base-5 {
  border: 1px solid;
  border-color: var(--neutral-40);
  flex: none;
  gap: 8px;
  display: inline-flex;
}

.button.large .button-base-5 {
  border-radius: 4px;
}

.button.type-secondary .button-base-5 {
  background-color: var(--primary-98);
  border-color: var(--primary-20);
  box-shadow: var(--shadow-xs);
  flex: none;
  gap: 8px;
  display: inline-flex;
}

.button.small .button-base-5 {
  border-radius: 4px;
  flex: none;
  gap: 8px;
  padding: 12px 14px;
  display: inline-flex;
}

.button.type-text .button-base-5 {
  gap: 8px;
}

.button.medium .button-base-5 {
  border-radius: 4px;
  flex: none;
  gap: 8px;
  display: inline-flex;
}

.button.tiny .button-base-5 {
  border-radius: 32px;
  flex: none;
  gap: 8px;
  padding: 8px;
  display: inline-flex;
}

.button.type-primary .button-base-5 {
  background-color: var(--primary-30);
  border: 1px solid;
  border-color: var(--primary-30);
  box-shadow: var(--shadow-xs);
  flex: none;
}

.button.x-small .button-base-5 {
  border-radius: 4px;
  flex: none;
  gap: 8px;
  padding: 10px 14px;
  display: inline-flex;
}

.button.type-error .button-base-5 {
  background-color: var(--error-60);
  border: 1px solid;
  border-color: var(--error-60);
  box-shadow: var(--shadow-xs);
  flex: none;
  gap: 8px;
  display: inline-flex;
}

.button.big .button-base-5 {
  border-radius: 4px;
  flex: none;
  gap: 8px;
  display: inline-flex;
}

.button.big.type-secondary .button-base-5 {
  border: 1.2px solid;
  padding: 14px;
}

.button.large.type-secondary .button-base-5 {
  border: 1.2px solid;
  padding: 18px 32px;
}

.button.large.type-primary .button-base-5 {
  flex-direction: column;
  width: 165px;
  padding: 20px 12px;
  display: flex;
}

.button.type-text.big .button-base-5 {
  padding: 12px 14px;
}

.button.big.type-error .button-base-5 {
  padding: 14px;
}

.button.x-small.type-secondary .button-base-5 {
  border: 1.2px solid;
}

.button.type-error.medium .button-base-5, .button.medium.type-primary .button-base-5, .button.big.type-outlined .button-base-5 {
  padding: 14px;
}

.button.large.type-outlined .button-base-5 {
  padding: 18px 32px;
}

.button.type-text.medium .button-base-5 {
  padding: 12px 14px;
}

.button.medium.type-secondary .button-base-5 {
  border: 1px solid;
  padding: 14px;
}

.button.medium.type-outlined .button-base-5 {
  padding: 14px;
}

.button.type-error.large .button-base-5 {
  padding: 18px 32px;
}

.button.small.type-secondary .button-base-5 {
  border: 1.2px solid;
}

.button.type-text.large .button-base-5 {
  flex: 1;
  padding: 18px 14px;
  display: flex;
}

.button.big.type-primary .button-base-5 {
  padding: 16px 14px;
}

.button.tiny.type-secondary .button-base-5 {
  border: 1.2px solid;
}

.button.type-outlined .text-6 {
  color: var(--neutral-40);
  margin-top: -1px;
}

.button.type-secondary .text-6 {
  color: var(--primary-20);
}

.button.small .text-6 {
  font-family: var(--caption-01-SEMI-BLD-font-family);
  font-size: var(--caption-01-SEMI-BLD-font-size);
  font-style: var(--caption-01-SEMI-BLD-font-style);
  font-weight: var(--caption-01-SEMI-BLD-font-weight);
  letter-spacing: var(--caption-01-SEMI-BLD-letter-spacing);
  line-height: var(--caption-01-SEMI-BLD-line-height);
}

.button.type-text .text-6 {
  color: #7c7b7b;
  margin-top: -1px;
}

.button.medium .text-6 {
  font-family: var(--caption-01-SEMI-BLD-font-family);
  font-size: var(--caption-01-SEMI-BLD-font-size);
  font-style: var(--caption-01-SEMI-BLD-font-style);
  font-weight: var(--caption-01-SEMI-BLD-font-weight);
  letter-spacing: var(--caption-01-SEMI-BLD-letter-spacing);
  line-height: var(--caption-01-SEMI-BLD-line-height);
  margin-top: -1px;
}

.button.tiny .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.type-primary .text-6 {
  color: var(--neutral-100);
  margin-top: -1px;
}

.button.x-small .text-6 {
  font-family: var(--caption-02-SEMI-BLD-font-family);
  font-size: var(--caption-02-SEMI-BLD-font-size);
  font-style: var(--caption-02-SEMI-BLD-font-style);
  font-weight: var(--caption-02-SEMI-BLD-font-weight);
  letter-spacing: var(--caption-02-SEMI-BLD-letter-spacing);
  line-height: var(--caption-02-SEMI-BLD-line-height);
}

.button.type-error .text-6 {
  color: var(--neutral-100);
  margin-top: -1px;
}

.button.big.type-secondary .text-6 {
  font-family: var(--paragraph-BLD-font-family);
  font-size: var(--paragraph-BLD-font-size);
  font-style: var(--paragraph-BLD-font-style);
  font-weight: var(--paragraph-BLD-font-weight);
  letter-spacing: var(--paragraph-BLD-letter-spacing);
  line-height: var(--paragraph-BLD-line-height);
  margin-top: -1.2px;
}

.button.large.type-secondary .text-6 {
  font-family: var(--SUBHEAD-BLD-font-family);
  font-size: var(--SUBHEAD-BLD-font-size);
  font-style: var(--SUBHEAD-BLD-font-style);
  font-weight: var(--SUBHEAD-BLD-font-weight);
  letter-spacing: var(--SUBHEAD-BLD-letter-spacing);
  line-height: var(--SUBHEAD-BLD-line-height);
  margin-top: -1.2px;
}

.button.large.type-primary .text-6 {
  font-family: var(--SUBHEAD-BLD-font-family);
  font-size: var(--SUBHEAD-BLD-font-size);
  font-style: var(--SUBHEAD-BLD-font-style);
  font-weight: var(--SUBHEAD-BLD-font-weight);
  letter-spacing: var(--SUBHEAD-BLD-letter-spacing);
  line-height: var(--SUBHEAD-BLD-line-height);
}

.button.type-text.big .text-6, .button.big.type-error .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.x-small.type-secondary .text-6 {
  margin-top: -1.2px;
}

.button.big.type-outlined .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.large.type-outlined .text-6, .button.type-error.large .text-6 {
  font-family: var(--SUBHEAD-BLD-font-family);
  font-size: var(--SUBHEAD-BLD-font-size);
  font-style: var(--SUBHEAD-BLD-font-style);
  font-weight: var(--SUBHEAD-BLD-font-weight);
  letter-spacing: var(--SUBHEAD-BLD-letter-spacing);
  line-height: var(--SUBHEAD-BLD-line-height);
}

.button.small.type-secondary .text-6 {
  margin-top: -1.2px;
}

.button.type-text.large .text-6, .button.big.type-primary .text-6 {
  font-family: var(--paragraph-SEMI-BLD-font-family);
  font-size: var(--paragraph-SEMI-BLD-font-size);
  font-style: var(--paragraph-SEMI-BLD-font-style);
  font-weight: var(--paragraph-SEMI-BLD-font-weight);
  letter-spacing: var(--paragraph-SEMI-BLD-letter-spacing);
  line-height: var(--paragraph-SEMI-BLD-line-height);
}

.button.tiny.type-secondary .text-6 {
  margin-top: -1.2px;
}

.button-base {
  background-color: #fff;
  border-radius: 6px;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button-base .content-2 {
  flex: none;
  align-items: center;
  gap: 8px;
  height: 16px;
  display: inline-flex;
  position: relative;
}

.button-base .icon-instance-node {
  width: 16px !important;
  height: 16px !important;
  position: relative !important;
}

.button-base .label {
  color: #000;
  font-family: var(--COMPONENT-BODY-SEMIBOLD-font-family);
  font-size: var(--COMPONENT-BODY-SEMIBOLD-font-size);
  font-style: var(--COMPONENT-BODY-SEMIBOLD-font-style);
  font-weight: var(--COMPONENT-BODY-SEMIBOLD-font-weight);
  letter-spacing: var(--COMPONENT-BODY-SEMIBOLD-letter-spacing);
  line-height: var(--COMPONENT-BODY-SEMIBOLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.button-primary-with {
  background-color: var(--baseblack);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 190px;
  padding: 16px;
  display: flex;
  position: relative;
}

.button-primary-with .frame-11 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9px;
  display: inline-flex;
  position: relative;
}

.button-primary-with .button-name {
  color: #fff;
  font-family: var(--inter-semi-bold-16px-font-family);
  font-size: var(--inter-semi-bold-16px-font-size);
  font-style: var(--inter-semi-bold-16px-font-style);
  font-weight: var(--inter-semi-bold-16px-font-weight);
  letter-spacing: var(--inter-semi-bold-16px-letter-spacing);
  line-height: var(--inter-semi-bold-16px-line-height);
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.button-primary-with .icon {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.button-primary-with .icon-outline-arrow {
  width: 18px !important;
  height: 18px !important;
  position: relative !important;
}

.component {
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 500px;
  padding: 40px 80px 56px;
  display: flex;
  position: relative;
}

.component .frame-7 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 32px;
  margin-top: -2.5px;
  margin-bottom: -2.5px;
  display: flex;
  position: relative;
}

.component .frame-8 {
  align-self: stretch;
  width: 100%;
  height: 190px;
  position: relative;
}

.component .frame-9 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.component .frame-10 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.component .content {
  font-family: var(--web-h5-DMI-font-family);
  font-size: var(--web-h5-DMI-font-size);
  font-style: var(--web-h5-DMI-font-style);
  font-weight: var(--web-h5-DMI-font-weight);
  letter-spacing: var(--web-h5-DMI-letter-spacing);
  line-height: var(--web-h5-DMI-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.component .text-wrapper-3 {
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.component .button-3 {
  all: unset;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.component .button-base-3 {
  box-shadow: var(--shadow-xs);
  border: 1px solid;
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 16px 14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.component .text-4 {
  letter-spacing: .18px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  position: relative;
}

.component.property-1-hover {
  background-color: #075a5e;
}

.component.property-1-default {
  background-color: #fff;
}

.component.property-1-hover .content {
  color: #fff;
}

.component.property-1-default .content {
  color: #373432;
}

.component.property-1-hover .text-wrapper-3 {
  color: var(--neutral-30);
}

.component.property-1-default .text-wrapper-3 {
  color: var(--neutral-60);
}

.component.property-1-hover .button-base-3 {
  border-color: #fff;
}

.component.property-1-default .button-base-3 {
  border-color: #075a5e;
}

.component.property-1-hover .text-4 {
  color: #fff;
}

.component.property-1-default .text-4 {
  color: #075a5e;
}

.divider {
  width: 1320px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.nav-link {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px;
  display: inline-flex;
  position: relative;
}

.nav-link .container {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 0;
  display: inline-flex;
  position: relative;
}

.nav-link .placeholder {
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.nav-link .selected {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.nav-link .disabled {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.nav-link .dark-false.selected {
  border-color: var(--primary-02);
}

.nav-link .selected.dark-true {
  border-color: #fff;
}

.nav-link .container.disabled .placeholder {
  color: #cac4c2;
}

.nav-link .container.default.dark-true .placeholder {
  color: #fff;
}

.nav-link .container.default.dark-false .placeholder {
  color: #1f1c1a;
}

.nav-link .container.hover.dark-false .placeholder {
  color: #0003;
}

.nav-link .container.dark-false.selected .placeholder {
  color: var(--primary-02);
}

.nav-link .container.selected.dark-true .placeholder {
  color: #fff;
}

.nav-link .container.hover.dark-true .placeholder {
  color: var(--accent-02);
}

.header {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 1280px;
  height: 64px;
  padding: 8px 64px;
  display: flex;
  position: relative;
}

.header .container-2 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.header .div-2 {
  flex: none;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.header .humble-logo {
  width: 100px;
  height: 40px;
  position: relative;
}

.header .frame-12 {
  align-items: flex-start;
  width: 280px;
  display: flex;
  position: relative;
}

.header .frame-13 {
  flex: 1;
  align-items: flex-start;
  gap: 10px;
  padding: 8px 12px;
  display: flex;
  position: relative;
}

.header .text-wrapper-4 {
  color: #075a5e;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -12px;
  position: relative;
}

.header .text-wrapper-5 {
  color: #075a5e;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.header .text-wrapper-6 {
  color: #075a5e;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -11px;
  position: relative;
}

.header .frame-14 {
  align-items: flex-start;
  width: 285px;
  display: flex;
  position: relative;
}

.header .form-search-form {
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.header .div-container {
  background-color: #fff;
  border-color: #cac4c2;
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
  border-radius: 40px 0 0 40px;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
  box-shadow: inset 0 1px 2px #eee;
}

.header .search {
  align-items: flex-start;
  position: relative;
}

.header .div-placeholder {
  position: relative;
}

.header .text-wrapper-7 {
  margin-top: -1px;
  position: relative;
}

.header .search-2 {
  position: relative;
}

.header .CTA-s {
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.header .text-wrapper-8 {
  font-family: var(--web-caption-BLD-font-family);
  font-size: var(--web-caption-BLD-font-size);
  font-style: var(--web-caption-BLD-font-style);
  font-weight: var(--web-caption-BLD-font-weight);
  letter-spacing: var(--web-caption-BLD-letter-spacing);
  line-height: var(--web-caption-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.header .button-4 {
  all: unset;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.header .button-base-4 {
  all: unset;
  background-color: var(--primary-02);
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.header .text-5 {
  color: #fff;
  font-family: var(--web-caption-BLD-font-family);
  font-size: var(--web-caption-BLD-font-size);
  font-style: var(--web-caption-BLD-font-style);
  font-weight: var(--web-caption-BLD-font-weight);
  letter-spacing: var(--web-caption-BLD-letter-spacing);
  line-height: var(--web-caption-BLD-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.header.primary-dark {
  background-color: #075a5e;
}

.header.light {
  background-color: #fff;
}

.header.secondary-light {
  background-color: var(--accent-01);
}

.header.secondary-dark {
  background-color: #373432;
}

.header.primary-dark .form-search-form, .header.secondary-dark .form-search-form {
  align-self: stretch;
}

.header.primary-dark .div-container {
  align-self: stretch;
  margin: -1px;
  padding: 7px 12px 7px 14px;
}

.header.light .div-container, .header.secondary-light .div-container {
  padding: 7px 4px 7px 17px;
}

.header.secondary-dark .div-container {
  align-self: stretch;
  margin: -1px;
  padding: 7px 12px 7px 14px;
}

.header.primary-dark .search {
  flex: 1;
  display: flex;
}

.header.light .search, .header.secondary-light .search {
  flex: none;
  margin-right: -303px;
  padding: 3px 0 4px;
  display: inline-flex;
}

.header.secondary-dark .search {
  flex: 1;
  display: flex;
}

.header.primary-dark .div-placeholder {
  flex: 1;
  align-items: center;
  display: flex;
}

.header.light .div-placeholder, .header.secondary-light .div-placeholder {
  flex: none;
  align-items: flex-start;
  padding: 0 469px 0 0;
  display: inline-flex;
}

.header.secondary-dark .div-placeholder {
  flex: 1;
  align-items: center;
  display: flex;
}

.header.primary-dark .text-wrapper-7 {
  color: #999492;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  width: 45px;
  height: 24px;
}

.header.light .text-wrapper-7, .header.secondary-light .text-wrapper-7 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Roboto, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.header.secondary-dark .text-wrapper-7 {
  color: #999492;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  width: 45px;
  height: 24px;
}

.header.primary-dark .search-2 {
  flex: none;
  align-self: stretch;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
}

.header.light .search-2, .header.secondary-light .search-2 {
  width: 48px;
}

.header.secondary-dark .search-2 {
  flex: none;
  align-self: stretch;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
}

.header.primary-dark .text-wrapper-8 {
  color: #fff;
}

.header.light .text-wrapper-8, .header.secondary-light .text-wrapper-8 {
  color: #075a5e;
}

.header.secondary-dark .text-wrapper-8 {
  color: #fff;
}

.header.primary-dark .button-4 {
  background-color: var(--primary-02);
}

.header.light .button-4, .header.secondary-light .button-4 {
  background-color: #fff;
}

.header.secondary-dark .button-4 {
  background-color: var(--primary-02);
}

.icon-search-fill {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.nav-labels {
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.nav-labels .placeholder-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.nav-labels .placeholder-2 {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.nav-labels .instance-node {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.nav-labels .placeholder-3 {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.nav-labels .placeholder-4 {
  color: var(--neutral-60);
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.nav-labels .placeholder-5 {
  color: #999492;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.nav-labels.with-icon-true {
  gap: 4px;
  padding: 4px 12px 4px 8px;
}

.nav-labels.with-icon-false {
  gap: 10px;
  padding: 6px 8px 6px 12px;
}

.nav-labels.state-9-default.theme-light {
  border: 1px solid #686462;
}

.nav-labels.state-9-hover.theme-light {
  background-color: var(--neutral-20);
  border: 1px solid #cac4c2;
}

.nav-labels.theme-light.state-9-disabled {
  border: 1px solid #cac4c2;
}

.nav-labels.theme-dark.state-9-selected {
  background-color: var(--accent-01);
}

.nav-labels.state-9-selected.theme-light {
  background-color: var(--neutral-90);
}

.nav-labels.theme-dark.state-9-hover {
  background-color: var(--overlay-32);
}

.nav-labels.state-9-selected .placeholder-2 {
  font-family: var(--web-caption-DMI-font-family);
  font-size: var(--web-caption-DMI-font-size);
  font-style: var(--web-caption-DMI-font-style);
  font-weight: var(--web-caption-DMI-font-weight);
  letter-spacing: var(--web-caption-DMI-letter-spacing);
  line-height: var(--web-caption-DMI-line-height);
}

.nav-labels.state-9-disabled .placeholder-2, .nav-labels.state-9-hover .placeholder-2, .nav-labels.state-9-default .placeholder-2 {
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
}

.nav-labels.theme-dark.state-9-disabled .placeholder-2 {
  color: var(--neutral-30);
}

.nav-labels.state-9-default.theme-light .placeholder-2 {
  color: var(--neutral-60);
}

.nav-labels.theme-dark.state-9-default .placeholder-2, .nav-labels.state-9-selected.theme-light .placeholder-2 {
  color: #fff;
}

.nav-labels.theme-light.state-9-disabled .placeholder-2 {
  color: #999492;
}

.nav-labels.state-9-hover.theme-light .placeholder-2 {
  color: var(--neutral-60);
}

.nav-labels.theme-dark.state-9-hover .placeholder-2 {
  color: var(--accent-01);
}

.nav-labels.theme-dark.state-9-selected .placeholder-2 {
  color: #1f1c1a;
}

.nav-labels.state-9-selected .placeholder-3, .nav-labels.state-9-disabled .placeholder-3, .nav-labels.state-9-default .placeholder-3 {
  color: #fff;
  font-family: var(--web-caption-DMI-font-family);
  font-size: var(--web-caption-DMI-font-size);
  font-style: var(--web-caption-DMI-font-style);
  font-weight: var(--web-caption-DMI-font-weight);
  letter-spacing: var(--web-caption-DMI-letter-spacing);
  line-height: var(--web-caption-DMI-line-height);
}

.nav-labels.state-9-hover .placeholder-3 {
  color: #686462;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
}

.shopicons-regular-plus-4 {
  width: 48px !important;
  height: 48px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.state-default-wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 500px;
  padding: 40px 75px 56px;
  display: inline-flex;
  position: relative;
}

.state-default-wrapper .frame-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  margin-top: -4.5px;
  margin-bottom: -4.5px;
  display: inline-flex;
  position: relative;
}

.state-default-wrapper .frame-4 {
  align-self: stretch;
  width: 100%;
  height: 191.15px;
  margin-top: -.44px;
  position: relative;
}

.state-default-wrapper .frame-5 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.state-default-wrapper .frame-6 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.state-default-wrapper .text-wrapper-2 {
  font-family: var(--web-h5-DMI-font-family);
  font-size: var(--web-h5-DMI-font-size);
  font-style: var(--web-h5-DMI-font-style);
  font-weight: var(--web-h5-DMI-font-weight);
  letter-spacing: var(--web-h5-DMI-letter-spacing);
  line-height: var(--web-h5-DMI-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.state-default-wrapper .discover-the-best {
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  text-align: center;
  width: 330px;
  position: relative;
}

.state-default-wrapper .button-2 {
  all: unset;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.state-default-wrapper .button-base-2 {
  box-shadow: var(--shadow-xs);
  border: 1px solid;
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 16px 14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.state-default-wrapper .text-3 {
  letter-spacing: .18px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  position: relative;
}

.state-default-wrapper.state-3-default {
  background-color: #fff;
}

.state-default-wrapper.state-3-hover {
  background-color: #075a5e;
}

.state-default-wrapper.state-3-default .text-wrapper-2 {
  color: #1f1c1a;
}

.state-default-wrapper.state-3-hover .text-wrapper-2 {
  color: #fff;
}

.state-default-wrapper.state-3-default .discover-the-best {
  color: #686462;
}

.state-default-wrapper.state-3-hover .discover-the-best {
  color: var(--neutral-30);
}

.state-default-wrapper.state-3-default .button-base-2 {
  border-color: #075a5e;
}

.state-default-wrapper.state-3-hover .button-base-2 {
  border-color: #fff;
}

.state-default-wrapper.state-3-default .text-3 {
  color: #075a5e;
}

.state-default-wrapper.state-3-hover .text-3 {
  color: #fff;
}

.tab {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 480px;
  height: 500px;
  padding: 40px 80px 56px;
  display: flex;
  position: relative;
}

.tab .frame {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 32px;
  margin-top: -4.5px;
  margin-bottom: -4.5px;
  display: flex;
  position: relative;
}

.tab .group-wrapper {
  align-self: stretch;
  width: 100%;
  height: 190px;
  position: relative;
}

.tab .group {
  width: 235px;
  height: 172px;
  position: relative;
  top: 9px;
  left: 43px;
}

.tab .overlap-group {
  width: 236px;
  height: 172px;
  position: relative;
  left: -1px;
}

.tab .vector {
  width: 223px;
  height: 154px;
  position: absolute;
  top: 19px;
  left: 8px;
}

.tab .img {
  width: 236px;
  height: 172px;
  position: absolute;
  top: 1px;
  left: 0;
}

.tab .vector-2 {
  width: 121px;
  height: 65px;
  position: absolute;
  top: 56px;
  left: 61px;
}

.tab .vector-3 {
  width: 11px;
  height: 4px;
  position: absolute;
  top: 121px;
  left: 49px;
}

.tab .vector-4 {
  width: 1px;
  height: 12px;
  position: absolute;
  top: 117px;
  left: 53px;
}

.tab .subtract {
  width: 32px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 171px;
}

.tab .div {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.tab .frame-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.tab .text-wrapper {
  font-family: var(--web-h5-DMI-font-family);
  font-size: var(--web-h5-DMI-font-size);
  font-style: var(--web-h5-DMI-font-style);
  font-weight: var(--web-h5-DMI-font-weight);
  letter-spacing: var(--web-h5-DMI-letter-spacing);
  line-height: var(--web-h5-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.tab .p {
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.tab .button-base-wrapper {
  all: unset;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.tab .div-wrapper {
  box-shadow: var(--shadow-xs);
  border: 1px solid;
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 16px 14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tab .text-2 {
  letter-spacing: .18px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  position: relative;
}

.tab.default {
  background-color: #fff;
}

.tab.hover {
  background-color: #075a5e;
}

.tab.default .text-wrapper {
  color: #373432;
}

.tab.hover .text-wrapper {
  color: #fff;
}

.tab.default .p {
  color: #373432;
}

.tab.hover .p {
  color: #fff;
}

.tab.default .div-wrapper {
  border-color: #075a5e;
}

.tab.hover .div-wrapper {
  border-color: #fff;
}

.tab.default .text-2 {
  color: #075a5e;
}

.tab.hover .text-2 {
  color: #fff;
}

.text {
  width: 843px;
  height: 110px;
}

.text .lorem-ipsum-is {
  height: 132px;
  top: -12px;
}

.text .span {
  color: #686462;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
}

.text .text-wrapper-11 {
  color: #075a5e;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
}

.text .rectangle {
  width: 582px;
  height: 44px;
  position: absolute;
  top: 12px;
  left: 258px;
}

.text .lorem-ipsum-is-2 {
  color: #0000;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  width: 843px;
  height: 132px;
  position: absolute;
  top: 0;
  left: 0;
}

.text .text-wrapper-12 {
  color: #504c4a;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
}

.text .highlight {
  flex-direction: column;
  align-items: flex-start;
  width: 393px;
  height: 56px;
  display: flex;
  position: absolute;
  top: 60px;
  left: 259px;
  box-shadow: 8px 12px 24px #00000029;
}

.text .polygon-wrapper {
  width: 39px;
  height: 4px;
  position: relative;
}

.text .polygon {
  width: 12px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 16px;
}

.text .frame-15 {
  background-color: #fff;
  border-radius: 4px;
  flex: none;
  align-items: center;
  gap: 14px;
  padding: 4px 12px;
  display: inline-flex;
  position: relative;
}

.text .frame-16 {
  width: 34px;
  height: 34px;
  position: relative;
}

.text .ellipse {
  background-color: var(--accent-02);
  border-radius: 14px;
  width: 28px;
  height: 28px;
  position: relative;
  top: 3px;
  left: 3px;
}

.text .line {
  width: 1px;
  height: 44px;
  position: relative;
}

.text .ellipse-wrapper {
  height: 34px;
}

.text .ellipse-2 {
  background-color: var(--accent-03);
  border-radius: 14px;
  width: 28px;
  height: 28px;
  position: relative;
  top: 3px;
  left: 3px;
}

.text .ellipse-3 {
  background-color: var(--accent-04);
  border-radius: 14px;
  width: 28px;
  height: 28px;
  position: relative;
  top: 3px;
  left: 3px;
}

.text .ellipse-4 {
  background-color: #cac4c2;
  border-radius: 14px;
  width: 28px;
  height: 28px;
  position: relative;
  top: 3px;
  left: 4px;
}

.text .text-wrapper-13 {
  color: #686462;
  font-family: var(--web-paragraph-DMI-font-family);
  font-size: var(--web-paragraph-DMI-font-size);
  font-style: var(--web-paragraph-DMI-font-style);
  font-weight: var(--web-paragraph-DMI-font-weight);
  letter-spacing: var(--web-paragraph-DMI-letter-spacing);
  line-height: var(--web-paragraph-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.text.property-1-4-default, .text.property-1-4-variant-4 .lorem-ipsum-is, .text.property-1-4-variant-2 .lorem-ipsum-is, .text.property-1-4-variant-3 .lorem-ipsum-is, .text.property-1-4-variant-5 .lorem-ipsum-is {
  position: relative;
}

.text.property-1-4-default .lorem-ipsum-is {
  color: #0000;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  width: 843px;
  position: absolute;
  left: 0;
}

.toolbar .property-frame {
  width: 64px !important;
  height: 68px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.toolbar {
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 4px 16px #9393931f;
}

.toolbar .close-wrapper {
  background-color: #1f1c1a;
  border-radius: 40px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
  display: inline-flex;
  position: relative;
}

.toolbar .img-2 {
  width: 24px;
  height: 24px;
  position: relative;
}

.toolbar .frame-19 {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.toolbar .img-wrapper {
  border: 1px solid;
  border-color: var(--primary-02);
  background-color: #fff;
  border-radius: 40px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
  display: inline-flex;
  position: relative;
}

.upvote {
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 16px 14px;
  display: flex;
  position: relative;
}

.upvote .frame-17 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.upvote .favorite {
  width: 24px;
  height: 24px;
  position: relative;
}

.upvote .i-use-this {
  color: var(--neutral-60);
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.upvote .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.upvote .text-wrapper-14 {
  color: #f3eff5;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.upvote.property-1-6-default, .upvote.property-1-6-variant-3 {
  border: 1px solid #cac4c2;
  width: 82px;
  height: 82px;
}

.upvote.property-1-6-selected {
  background-color: var(--primary-02);
  width: 80px;
  height: 80px;
}

.upvote.property-1-6-default .frame-17 {
  margin-left: -1.5px;
}

.upvote.property-1-6-selected .frame-17 {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.element {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.element .div-3 {
  background-color: #fff;
  width: 1920px;
  height: 20813px;
  position: relative;
}

.element .overlap {
  width: 1921px;
  height: 1709px;
  position: absolute;
  top: 0;
  left: -1px;
}

.element .overlap-group-wrapper {
  background-color: #f9f9f9;
  width: 1921px;
  height: 1709px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-group-2 {
  width: 2611px;
  height: 2164px;
  position: relative;
  top: -203px;
  left: 1px;
}

.element .ellipse-5 {
  filter: blur(400px);
  background-color: #44bba4;
  border-radius: 906.5px;
  width: 1813px;
  height: 1813px;
  position: absolute;
  top: 0;
  left: 798px;
}

.element .apple-multi-device {
  object-fit: cover;
  width: 1920px;
  height: 1677px;
  position: absolute;
  top: 487px;
  left: 0;
}

.element .frame-20 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  width: 1920px;
  padding: 120px;
  display: flex;
  position: absolute;
  top: 0;
  left: 1px;
}

.element .heading-wrapper {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 260px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .heading {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 1181px;
  display: flex;
  position: relative;
}

.element .text-wrapper-15 {
  color: #000;
  letter-spacing: -.48px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 96px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-16 {
  color: #000;
  letter-spacing: -.16px;
  width: 563px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  position: relative;
}

.element .group-2 {
  width: 564px;
  height: 108px;
  position: relative;
}

.element .frame-21 {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 0;
}

.element .UIX {
  width: 143px;
  height: 56px;
  position: relative;
}

.element .frame-wrapper {
  background-color: #a7a7a71f;
  border-radius: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  position: relative;
}

.element .frame-22 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.element .group-3 {
  background-color: #fff;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  position: relative;
}

.element .overlap-group-3 {
  width: 22px;
  height: 22px;
  position: relative;
  top: 13px;
  left: 13px;
}

.element .line-2 {
  width: 5px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 9px;
}

.element .line-3 {
  width: 22px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.element .text-wrapper-17 {
  color: #000;
  letter-spacing: .46px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.9px;
  position: relative;
}

.element .UIX-2 {
  width: 206px;
  height: 56px;
  position: relative;
}

.element .UIX-3 {
  width: 181px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-18 {
  color: #000;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-23 {
  background-color: #396965;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  width: 1919px;
  padding: 100px 120px;
  display: flex;
  position: absolute;
  top: 19434px;
  left: 0;
}

.element .text-wrapper-19 {
  color: #fff;
  letter-spacing: -.8px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  position: relative;
}

.element .div-4 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-24 {
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.element .frame-25 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-20 {
  color: #fff;
  letter-spacing: -.56px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-21 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-22 {
  color: #fff;
  letter-spacing: -.48px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
  position: relative;
}

.element .frame-26 {
  background-color: #075a5e;
  width: 1920px;
  height: 890px;
  position: absolute;
  top: 2281px;
  left: 0;
  overflow: hidden;
}

.element .overlap-2 {
  width: 931px;
  height: 1350px;
  position: absolute;
  top: 0;
  left: 121px;
}

.element .homepage {
  width: 606px;
  height: 890px;
  position: absolute;
  top: 0;
  left: 325px;
}

.element .rectangle-2 {
  filter: blur(100px);
  background-color: #00000052;
  width: 298px;
  height: 1176px;
  position: absolute;
  top: 174px;
  left: 452px;
}

.element .homepage-2 {
  width: 606px;
  height: 796px;
  position: absolute;
  top: 94px;
  left: 0;
}

.element .frame-27 {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 556px;
  display: flex;
  position: absolute;
  top: 196px;
  left: 1229px;
}

.element .frame-28 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-23 {
  color: #fff;
  letter-spacing: -.39px;
  width: 608px;
  margin-top: -1px;
  margin-right: -52px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .a-community-driven {
  color: #fff;
  letter-spacing: -.3px;
  align-self: stretch;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 38.4px;
  position: relative;
}

.element .frame-29 {
  background-color: #afdedc;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 1920px;
  padding: 120px 160px;
  display: flex;
  position: absolute;
  top: 4199px;
  left: 0;
}

.element .text-wrapper-24 {
  color: #000;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 38.4px;
  position: relative;
}

.element .text-wrapper-25 {
  color: #000;
  letter-spacing: .7px;
  text-align: center;
  width: 1386px;
  height: 364px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.element .frame-30 {
  background-color: #1b1b1b;
  width: 1920px;
  height: 1200px;
  position: absolute;
  top: 5864px;
  left: 0;
}

.element .text-wrapper-26 {
  color: #fff;
  letter-spacing: -.3px;
  white-space: nowrap;
  height: 77px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 48px;
  font-weight: 500;
  line-height: 76.8px;
  position: absolute;
  top: 111px;
  left: 124px;
}

.element .overlap-3 {
  width: 1920px;
  height: 1125px;
  position: absolute;
  top: 265px;
  left: 0;
}

.element .img-3 {
  object-fit: cover;
  width: 613px;
  height: 563px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .img-4 {
  object-fit: cover;
  width: 736px;
  height: 563px;
  position: absolute;
  top: 562px;
  left: 0;
}

.element .img-5 {
  object-fit: cover;
  width: 377px;
  height: 563px;
  position: absolute;
  top: 0;
  left: 1543px;
}

.element .img-6 {
  object-fit: cover;
  width: 255px;
  height: 563px;
  position: absolute;
  top: 562px;
  left: 1665px;
}

.element .img-7 {
  object-fit: cover;
  width: 950px;
  height: 563px;
  position: absolute;
  top: 0;
  left: 603px;
}

.element .img-8 {
  object-fit: cover;
  width: 950px;
  height: 563px;
  position: absolute;
  top: 562px;
  left: 725px;
}

.element .ipad-pro {
  width: 1020px;
  height: 904px;
  position: absolute;
  top: 3231px;
  left: 0;
}

.element .ipad-pro-2 {
  object-fit: cover;
  width: 854px;
  height: 904px;
  position: absolute;
  top: 3231px;
  left: 1066px;
}

.element .frame-31 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  width: 1920px;
  padding: 80px 120px;
  display: flex;
  position: absolute;
  top: 1709px;
  left: 0;
}

.element .frame-32 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-33 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-27 {
  color: #5e5e5e;
  letter-spacing: -.17px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .frame-34 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element .every-entrepreneur {
  color: #5e5e5e;
  letter-spacing: -.24px;
  width: 700px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.element .as-a-creator-and {
  color: #5e5e5e;
  letter-spacing: -.24px;
  width: 734px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: relative;
}

.element .frame-35 {
  background-color: #f3eff5;
  width: 1920px;
  height: 920px;
  position: absolute;
  top: 4937px;
  left: 0;
  overflow: hidden;
}

.element .home-page {
  background-color: var(--neutral-100);
  border-radius: 12px;
  width: 554px;
  height: 2174px;
  position: absolute;
  top: -187px;
  left: -448px;
  overflow: hidden;
  transform: rotate(8.4deg);
}

.element .overlap-4 {
  width: 554px;
  height: 369px;
  position: absolute;
  top: 1805px;
  left: 0;
}

.element .footers {
  background-color: #c8c8c8;
  width: 554px;
  height: 145px;
  position: absolute;
  top: 224px;
  left: 0;
  overflow: hidden;
}

.element .frame-36 {
  flex-direction: column;
  align-items: center;
  gap: 12.3px;
  display: inline-flex;
  position: relative;
  top: 23px;
}

.element .frame-37 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 491.4px;
  display: flex;
  position: relative;
}

.element .frame-38 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12.69px;
  display: inline-flex;
  position: relative;
}

.element .image-wrapper {
  width: 52.68px;
  height: 16.95px;
  position: relative;
}

.element .image-2 {
  object-fit: cover;
  width: 53px;
  height: 17px;
  position: absolute;
  top: -5304px;
  left: 805px;
  transform: rotate(-8.4deg);
}

.element .social-buttons {
  flex: none;
  margin-top: -5278.96px;
  margin-right: -809.47px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-39 {
  flex: none;
  align-items: flex-start;
  gap: 30.76px;
  display: inline-flex;
  position: relative;
}

.element .div-5 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.15px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-28 {
  color: #1c1c1c;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-29 {
  color: #0c0c0c;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.8px;
  font-weight: 400;
  line-height: 8px;
  position: relative;
}

.element .text-wrapper-30 {
  color: #0c0c0c;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 400;
  line-height: 8.6px;
  position: relative;
}

.element .divider-2 {
  width: 553.69px;
  height: 1px;
  margin-top: -5260.54px;
  margin-right: -844.38px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-31 {
  color: #121212;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.8px;
  font-weight: 400;
  line-height: 8px;
  position: relative;
}

.element .frame-40 {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.85px;
  width: 554px;
  padding: 15.38px 15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-41 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12.3px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-32 {
  color: #252323;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 17.7px;
  font-weight: 700;
  line-height: 24.8px;
  position: relative;
}

.element .frame-42 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12.3px;
  width: 522.55px;
  display: flex;
  position: relative;
}

.element .frame-43 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.15px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .line-4 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -5143.75px;
  margin-right: -817.72px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-44 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 4.61px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-33 {
  color: #181818;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 6.9px;
  font-weight: 700;
  line-height: 8.3px;
  position: relative;
}

.element .there-are-over {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 474.87px;
  font-family: Inter, Helvetica;
  font-size: 6.9px;
  font-weight: 400;
  line-height: 9.7px;
  position: relative;
}

.element .text-wrapper-34 {
  font-weight: 700;
}

.element .text-wrapper-35 {
  letter-spacing: -.02px;
  font-size: 6.2px;
  font-weight: 500;
  line-height: 8.6px;
}

.element .frame-45 {
  width: 15.38px;
  height: 15.38px;
  position: relative;
  overflow: hidden;
}

.element .overlap-5 {
  width: 27px;
  height: 27px;
  position: absolute;
  top: -6px;
  left: -7px;
}

.element .ellipse-6 {
  background-color: #676767;
  border-radius: 6.87px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 7px;
  left: 7px;
}

.element .frame-46 {
  width: 27px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .rectangle-3 {
  background-color: #676767;
  width: 6px;
  height: 2px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.element .rectangle-4 {
  background-color: #676767;
  width: 6px;
  height: 2px;
  position: absolute;
  top: 15px;
  left: 11px;
}

.element .overlap-group-4 {
  width: 7px;
  height: 7px;
  position: absolute;
  top: -5122px;
  left: 318px;
}

.element .vector-5 {
  width: 7px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-8.4deg);
}

.element .vector-6 {
  width: 4px;
  height: 4px;
  position: absolute;
  top: 2px;
  left: 2px;
  transform: rotate(-8.4deg);
}

.element .line-5 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -5091.4px;
  margin-right: -825.46px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-36 {
  color: #181818;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.9px;
  font-weight: 600;
  line-height: 8.3px;
  position: relative;
}

.element .ellipse-7 {
  background-color: #e1e1e1;
  border-radius: 6.87px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.element .line-6 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -5064.01px;
  margin-right: -829.5px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .line-7 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -5036.63px;
  margin-right: -833.55px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-47 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .line-8 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -5009.24px;
  margin-right: -837.59px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .overlap-6 {
  width: 554px;
  height: 369px;
  position: absolute;
  top: 301px;
  left: 0;
}

.element .rectangle-5 {
  background-color: #fbfbfb;
  width: 554px;
  height: 369px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-48 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.15px;
  display: inline-flex;
  position: absolute;
  top: 17px;
  left: 15px;
}

.element .text-wrapper-37 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 223.4px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: relative;
}

.element .frame-49 {
  flex-direction: column;
  align-items: center;
  gap: 7.69px;
  display: inline-flex;
  position: absolute;
  top: 85px;
  left: 15px;
}

.element .frame-50 {
  flex: none;
  align-items: flex-start;
  gap: 7.69px;
  display: inline-flex;
  position: relative;
}

.element .frame-51 {
  background-color: #d9d9d9;
  border: .38px solid #959595;
  border-radius: 4.61px;
  width: 124.97px;
  height: 114.58px;
  position: relative;
}

.element .frame-52 {
  align-items: center;
  gap: 6.92px;
  display: inline-flex;
  position: absolute;
  top: 8px;
  left: 4px;
}

.element .rectangle-6 {
  background-color: #ebebeb;
  border-radius: 3.08px;
  width: 26.92px;
  height: 26.92px;
  position: relative;
}

.element .frame-53 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.31px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-38 {
  color: #000;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 10.8px;
  position: relative;
}

.element .text-wrapper-39 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  width: 81.9px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: relative;
}

.element .text-wrapper-40 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  height: 8px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: absolute;
  top: 76px;
  left: 40px;
}

.element .text-wrapper-41 {
  color: #000;
  letter-spacing: -.02px;
  text-align: center;
  width: 82px;
  height: 18px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 600;
  line-height: 8.6px;
  position: absolute;
  top: 50px;
  left: 21px;
}

.element .overlap-group-5 {
  width: 123px;
  height: 19px;
  position: absolute;
  top: -3560px;
  left: 834px;
}

.element .line-9 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: 9px;
  left: -1px;
  transform: rotate(-8.4deg);
}

.element .rectangle-7 {
  width: 28px;
  height: 8px;
  position: absolute;
  top: 9px;
  left: 93px;
  transform: rotate(-8.4deg);
}

.element .frame-54 {
  background-color: #c6c6c6;
  border-radius: 9.23px;
  justify-content: center;
  align-items: center;
  gap: 3.85px;
  padding: 1.54px 3.08px;
  display: inline-flex;
  position: absolute;
  top: 98px;
  left: 4px;
  overflow: hidden;
}

.element .text-wrapper-42 {
  color: #000;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 600;
  line-height: 7.5px;
  position: relative;
}

.element .overlap-group-6 {
  width: 116px;
  height: 34px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.element .frame-55 {
  align-items: center;
  gap: 6.92px;
  display: inline-flex;
  position: absolute;
  top: 5px;
  left: 0;
}

.element .text-wrapper-43 {
  color: #858585;
  letter-spacing: -.02px;
  white-space: nowrap;
  height: 6px;
  font-family: Inter, Helvetica;
  font-size: 4.6px;
  font-weight: 600;
  line-height: 6.5px;
  position: absolute;
  top: 0;
  left: 104px;
}

.element .text-wrapper-44 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  height: 8px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: absolute;
  top: 60px;
  left: 40px;
}

.element .text-wrapper-45 {
  color: #000;
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  height: 9px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 600;
  line-height: 8.6px;
  position: absolute;
  top: 50px;
  left: 22px;
}

.element .overlap-7 {
  width: 123px;
  height: 19px;
  position: absolute;
  top: -3560px;
  left: 701px;
}

.element .overlap-8 {
  width: 85px;
  height: 37px;
  position: absolute;
  top: 75px;
  left: 40px;
}

.element .overlap-9 {
  width: 85px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-46 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  height: 8px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: absolute;
  top: 1px;
  left: 0;
}

.element .line-10 {
  width: 45px;
  height: 19px;
  position: absolute;
  top: 3px;
  left: 39px;
  transform: rotate(-8.4deg);
}

.element .rectangle-8 {
  width: 29px;
  height: 12px;
  position: absolute;
  top: 23px;
  left: 52px;
  transform: rotate(-8.4deg);
}

.element .overlap-group-7 {
  width: 124px;
  height: 37px;
  position: absolute;
  top: 75px;
  left: 0;
}

.element .text-wrapper-47 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  height: 8px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: absolute;
  top: 1px;
  left: 40px;
}

.element .line-11 {
  width: 123px;
  height: 19px;
  position: absolute;
  top: 9px;
  left: 1px;
  transform: rotate(-8.4deg);
}

.element .frame-56 {
  background-color: #c6c6c6;
  border-radius: 9.23px;
  justify-content: center;
  align-items: center;
  gap: 3.85px;
  padding: 1.54px 3.08px;
  display: inline-flex;
  position: absolute;
  top: 24px;
  left: 4px;
  overflow: hidden;
}

.element .rectangle-9 {
  width: 29px;
  height: 12px;
  position: absolute;
  top: 23px;
  left: 92px;
  transform: rotate(-8.4deg);
}

.element .overlap-group-8 {
  width: 123px;
  height: 19px;
  position: absolute;
  top: -3682px;
  left: 834px;
}

.element .overlap-10 {
  width: 123px;
  height: 19px;
  position: absolute;
  top: -3682px;
  left: 701px;
}

.element .line-12 {
  width: 27px;
  height: 19px;
  position: absolute;
  top: 76px;
  left: 96px;
  transform: rotate(-8.4deg);
}

.element .rectangle-10 {
  width: 22px;
  height: 12px;
  position: absolute;
  top: 96px;
  left: 99px;
  transform: rotate(-8.4deg);
}

.element .button-5 {
  all: unset;
  box-sizing: border-box;
  border-radius: 1.54px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 88.44px;
  display: flex;
  position: relative;
}

.element .button-base-6 {
  background-color: #9b9b9b;
  border-radius: 1.54px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.92px;
  padding: 6.92px 15.38px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .text-7 {
  color: var(--neutral-100);
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 700;
  line-height: 8.6px;
  position: relative;
}

.element .frame-57 {
  background-color: #f6f6f6;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.85px;
  width: 554px;
  padding: 8.46px 15.38px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 0;
}

.element .frame-58 {
  flex: none;
  align-items: flex-end;
  gap: 16.53px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-48 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 600;
  line-height: 8.6px;
  position: relative;
}

.element .text-wrapper-49 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.element .frame-59 {
  flex: none;
  align-items: center;
  gap: 9.23px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-50 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.element .button-6 {
  all: unset;
  box-sizing: border-box;
  border-radius: 1.54px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.element .button-base-7 {
  all: unset;
  box-sizing: border-box;
  background-color: #9b9b9b;
  border-radius: 1.54px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 1.92px;
  padding: 4.61px 5.38px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .text-8 {
  color: var(--neutral-100);
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 700;
  line-height: 7.5px;
  position: relative;
}

.element .frame-60 {
  background-color: #d9d9d9;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.85px;
  width: 554px;
  padding: 7.69px 15.38px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-61 {
  flex: none;
  align-items: center;
  gap: 12.3px;
  display: inline-flex;
  position: relative;
}

.element .image-3 {
  object-fit: cover;
  width: 53px;
  height: 17px;
  position: absolute;
  top: -3260px;
  left: 821px;
  transform: rotate(-8.4deg);
}

.element .searchbar {
  background-color: var(--neutral-100);
  border: .38px solid #d3d3d3;
  border-radius: 1.54px;
  align-items: center;
  gap: 3.08px;
  width: 201.48px;
  height: 18.46px;
  padding: 4.61px 6.15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .frame-62 {
  flex: none;
  align-items: center;
  gap: 3.08px;
  display: inline-flex;
  position: relative;
}

.element .icon-search-fill-false {
  left: unset !important;
  top: unset !important;
  width: 9.23px !important;
  height: 9.23px !important;
  margin-top: -3261.02px !important;
  margin-right: -593.52px !important;
  position: relative !important;
  transform: rotate(-8.4deg) !important;
}

.element .text-wrapper-51 {
  color: #7c7b7b;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: 153.03px;
  height: 8.46px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 400;
  line-height: 8.6px;
  position: relative;
}

.element .frame-63 {
  flex: none;
  align-items: center;
  gap: 2.31px;
  display: inline-flex;
  position: relative;
}

.element .frame-64 {
  width: 7.69px;
  height: 7.69px;
  margin-top: -3261.87px;
  margin-right: -315.71px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-65 {
  flex-direction: column;
  align-items: flex-start;
  gap: 9.23px;
  display: inline-flex;
  position: absolute;
  top: 114px;
  left: 17px;
}

.element .text-wrapper-52 {
  color: #252323;
  letter-spacing: -.09px;
  width: 265.7px;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.3px;
  position: relative;
}

.element .text-wrapper-53 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 265.7px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 11.8px;
  position: relative;
}

.element .button-base-8 {
  all: unset;
  box-sizing: border-box;
  background-color: #9b9b9b;
  border-radius: 1.54px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.92px;
  padding: 6.92px 15.38px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .frame-66 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.15px;
  display: inline-flex;
  position: absolute;
  top: 1481px;
  left: 15px;
}

.element .frame-67 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.69px;
  display: inline-flex;
  position: absolute;
  top: 1548px;
  left: 15px;
}

.element .frame-68 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 7.69px;
  display: inline-flex;
  position: relative;
}

.element .line-13 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: -4714px;
  left: 833px;
  transform: rotate(-8.4deg);
}

.element .line-14 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: -4714px;
  left: 700px;
  transform: rotate(-8.4deg);
}

.element .line-15 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: -4714px;
  left: 568px;
  transform: rotate(-8.4deg);
}

.element .line-16 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: -4714px;
  left: 435px;
  transform: rotate(-8.4deg);
}

.element .line-17 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: -4836px;
  left: 833px;
  transform: rotate(-8.4deg);
}

.element .line-18 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: -4836px;
  left: 700px;
  transform: rotate(-8.4deg);
}

.element .line-19 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: -4836px;
  left: 568px;
  transform: rotate(-8.4deg);
}

.element .line-20 {
  width: 124px;
  height: 1px;
  position: absolute;
  top: -4836px;
  left: 435px;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-54 {
  color: #252323;
  letter-spacing: -.07px;
  white-space: nowrap;
  height: 25px;
  font-family: Inter, Helvetica;
  font-size: 17.7px;
  font-weight: 700;
  line-height: 24.8px;
  position: absolute;
  top: 1277px;
  left: 15px;
}

.element .overlap-11 {
  width: 523px;
  height: 571px;
  position: absolute;
  top: 685px;
  left: 15px;
}

.element .frame-69 {
  align-items: flex-start;
  gap: 12.3px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 2px;
}

.element .frame-70 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 21.53px;
  display: inline-flex;
  position: relative;
}

.element .frame-71 {
  flex: none;
  align-items: flex-end;
  gap: 19.99px;
  display: inline-flex;
  position: relative;
}

.element .frame-72 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4.61px;
  width: 234.55px;
  display: flex;
  position: relative;
}

.element .frame-73 {
  flex: none;
  align-items: center;
  gap: 2.69px;
  display: inline-flex;
  position: relative;
}

.element .profile-user {
  width: 12.3px;
  height: 12.3px;
  margin-top: -3936.15px;
  margin-right: -622.45px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-55 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: relative;
}

.element .ellipse-8 {
  background-color: #d9d9d9;
  border-radius: 1.15px;
  width: 2.31px;
  height: 2.31px;
  position: relative;
}

.element .star {
  width: 9.23px;
  height: 9.23px;
  margin-top: -3955.63px;
  margin-right: -763.2px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-74 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3.08px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-56 {
  color: #000;
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 12.3px;
  font-weight: 700;
  line-height: 17.2px;
  position: relative;
}

.element .frame-75 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 9.23px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-76 {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.element .frame-77 {
  background-color: #c6c6c6;
  border-radius: 9.23px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.85px;
  padding: 1.54px 3.08px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-57 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: relative;
}

.element .share {
  width: 15.38px;
  margin-top: -4021.03px;
  margin-right: -817.29px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .image-gallery-wrapper {
  background-color: #d9d9d9;
  border-radius: 4.61px;
  width: 80.75px;
  height: 69.98px;
  position: relative;
}

.element .image-gallery {
  width: 17px;
  height: 17px;
  position: absolute;
  top: -3913px;
  left: 601px;
  transform: rotate(-8.4deg);
}

.element .profile-user-2 {
  width: 12.3px;
  height: 12.3px;
  margin-top: -4027.66px;
  margin-right: -622.45px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .star-2 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4047.14px;
  margin-right: -763.2px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .share-2 {
  width: 15.38px;
  margin-top: -4112.54px;
  margin-right: -817.29px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .image-gallery-2 {
  width: 17px;
  height: 17px;
  position: absolute;
  top: -4004px;
  left: 601px;
  transform: rotate(-8.4deg);
}

.element .profile-user-3 {
  width: 12.3px;
  height: 12.3px;
  margin-top: -4119.17px;
  margin-right: -622.45px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .star-3 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4138.66px;
  margin-right: -763.2px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .share-3 {
  width: 15.38px;
  margin-top: -4204.06px;
  margin-right: -817.29px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .image-gallery-3 {
  width: 17px;
  height: 17px;
  position: absolute;
  top: -4096px;
  left: 601px;
  transform: rotate(-8.4deg);
}

.element .profile-user-4 {
  width: 12.3px;
  height: 12.3px;
  margin-top: -4210.69px;
  margin-right: -622.45px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .star-4 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4230.17px;
  margin-right: -763.2px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .share-4 {
  width: 15.38px;
  margin-top: -4295.57px;
  margin-right: -817.29px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .image-gallery-4 {
  width: 17px;
  height: 17px;
  position: absolute;
  top: -4187px;
  left: 601px;
  transform: rotate(-8.4deg);
}

.element .profile-user-5 {
  width: 12.3px;
  height: 12.3px;
  margin-top: -4302.2px;
  margin-right: -622.45px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .star-5 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4321.68px;
  margin-right: -763.2px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .share-5 {
  width: 15.38px;
  margin-top: -4387.08px;
  margin-right: -817.29px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .image-gallery-5 {
  width: 17px;
  height: 17px;
  position: absolute;
  top: -4279px;
  left: 601px;
  transform: rotate(-8.4deg);
}

.element .line-21 {
  width: 483.33px;
  height: 1px;
  margin-top: -4019.31px;
  margin-right: -1122.02px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-78 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 7.31px;
  display: inline-flex;
  position: relative;
}

.element .frame-79 {
  flex: none;
  align-items: flex-start;
  gap: 6.15px;
  display: inline-flex;
  position: relative;
}

.element .button-base-9 {
  border: .38px solid #b9b9b9;
  border-radius: 1.54px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 1.92px;
  padding: 2.69px 9.23px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .text-9 {
  color: #b9b9b9;
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 4.6px;
  font-weight: 400;
  line-height: 6.5px;
  position: relative;
}

.element .line-22 {
  width: 133.78px;
  height: 23.81px;
  margin-top: -13.92px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-58 {
  color: #000;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 10.8px;
  position: relative;
}

.element .frame-80 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.15px;
  width: 160.34px;
  display: flex;
  position: relative;
}

.element .frame-81 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4.61px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-59 {
  color: #000;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 6.9px;
  font-weight: 700;
  line-height: 9.7px;
  position: relative;
}

.element .text-wrapper-60 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  width: 112.66px;
  height: 15px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: relative;
}

.element .add {
  width: 7.69px;
  height: 7.69px;
  margin-top: -4072.69px;
  margin-right: -439.17px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-61 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: relative;
}

.element .frame-82 {
  flex: none;
  align-items: center;
  gap: 1.54px;
  display: inline-flex;
  position: relative;
}

.element .frame-83 {
  width: 28.45px;
  height: 12.3px;
  position: relative;
}

.element .overlap-group-9 {
  width: 28px;
  height: 12px;
  position: relative;
}

.element .ellipse-9 {
  background-color: #d9d9d9;
  border-radius: 6.15px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .ellipse-10 {
  background-color: #d0d0d0;
  border-radius: 6.15px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 7px;
}

.element .ellipse-11 {
  background-color: #c4c4c4;
  border-radius: 6.15px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 16px;
}

.element .text-wrapper-62 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 4.6px;
  font-weight: 500;
  line-height: 6.5px;
  position: relative;
}

.element .share-6 {
  width: 17.46px;
  margin-top: -1.04px;
  margin-bottom: -1.04px;
  margin-right: -1.04px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .add-2 {
  width: 7.69px;
  height: 7.69px;
  margin-top: -4128.06px;
  margin-right: -439.17px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .add-3 {
  width: 7.69px;
  height: 7.69px;
  margin-top: -4183.43px;
  margin-right: -439.17px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-84 {
  background-color: #d9d9d9;
  border-radius: 4.61px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.85px;
  width: 523px;
  padding: 26.92px 12.3px;
  display: flex;
  position: absolute;
  top: 467px;
  left: 0;
}

.element .frame-85 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 12.3px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-63 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 231.86px;
  font-family: Inter, Helvetica;
  font-size: 6.9px;
  font-weight: 500;
  line-height: 9.7px;
  position: relative;
}

.element .frame-86 {
  flex: none;
  align-items: center;
  gap: 6.15px;
  display: inline-flex;
  position: relative;
}

.element .frame-87 {
  background-color: var(--neutralwhite);
  border: .19px solid;
  border-color: var(--neutral-grey);
  border-radius: 3.08px;
  align-items: flex-start;
  gap: 3.85px;
  width: 154.96px;
  padding: 6.15px 5.38px;
  display: flex;
  position: relative;
}

.element .text-wrapper-64 {
  color: var(--neutral-dark-grey);
  letter-spacing: .11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.19px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 6.2px;
  font-weight: 400;
  line-height: 9.2px;
  position: relative;
}

.element .button-7 {
  all: unset;
  box-sizing: border-box;
  border-radius: 1.54px;
  justify-content: center;
  align-items: center;
  width: 88.44px;
  display: flex;
  position: relative;
}

.element .button-base-10 {
  background-color: #9b9b9b;
  border-radius: 1.54px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.92px;
  padding: 6.54px 15.38px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .frame-88 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.69px;
  display: inline-flex;
  position: absolute;
  top: 1311px;
  left: 15px;
}

.element .frame-89 {
  background-color: #d9d9d9;
  border-radius: 4.61px;
  width: 124.97px;
  height: 69.98px;
  position: relative;
}

.element .line-23 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4567px;
  left: 820px;
  transform: rotate(-8.4deg);
}

.element .line-24 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4585px;
  left: 942px;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-65 {
  color: #000;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 10.8px;
  position: absolute;
  top: 29px;
  left: 33px;
}

.element .line-25 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4567px;
  left: 688px;
  transform: rotate(-8.4deg);
}

.element .line-26 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4585px;
  left: 810px;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-66 {
  color: #000;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 10.8px;
  position: absolute;
  top: 29px;
  left: 25px;
}

.element .frame-90 {
  background-color: #c6c6c6;
  border-radius: 9.23px;
  justify-content: center;
  align-items: center;
  gap: 3.85px;
  padding: 1.54px 3.08px;
  display: inline-flex;
  position: absolute;
  top: 5px;
  left: 91px;
  overflow: hidden;
}

.element .line-27 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4567px;
  left: 555px;
  transform: rotate(-8.4deg);
}

.element .line-28 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4585px;
  left: 677px;
  transform: rotate(-8.4deg);
}

.element .line-29 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4567px;
  left: 422px;
  transform: rotate(-8.4deg);
}

.element .line-30 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4585px;
  left: 544px;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-67 {
  color: #000;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 10.8px;
  position: absolute;
  top: 29px;
  left: 37px;
}

.element .line-31 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4645px;
  left: 820px;
  transform: rotate(-8.4deg);
}

.element .line-32 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4663px;
  left: 942px;
  transform: rotate(-8.4deg);
}

.element .line-33 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4645px;
  left: 688px;
  transform: rotate(-8.4deg);
}

.element .line-34 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4663px;
  left: 810px;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-68 {
  color: #000;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 10.8px;
  position: absolute;
  top: 29px;
  left: 28px;
}

.element .line-35 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4645px;
  left: 555px;
  transform: rotate(-8.4deg);
}

.element .line-36 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4663px;
  left: 677px;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-69 {
  color: #000;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 10.8px;
  position: absolute;
  top: 29px;
  left: 34px;
}

.element .line-37 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4645px;
  left: 422px;
  transform: rotate(-8.4deg);
}

.element .line-38 {
  width: 140px;
  height: 1px;
  position: absolute;
  top: -4663px;
  left: 544px;
  transform: rotate(-8.4deg);
}

.element .rectangle-11 {
  background-color: #d9d9d9;
  width: 236px;
  height: 232px;
  position: absolute;
  top: 69px;
  left: 317px;
}

.element .product-details-page {
  background-color: var(--neutral-100);
  border-radius: 12px;
  width: 554px;
  height: 1481px;
  position: absolute;
  top: -93px;
  left: 206px;
  overflow: hidden;
  transform: rotate(8.4deg);
}

.element .overlap-12 {
  width: 554px;
  height: 213px;
  position: absolute;
  top: 34px;
  left: 0;
}

.element .frame-91 {
  background-color: #f6f6f6;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.85px;
  width: 554px;
  padding: 8.46px 15.38px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .rectangle-12 {
  background-color: #d9d9d9;
  width: 554px;
  height: 180px;
  position: absolute;
  top: 34px;
  left: 0;
}

.element .there-are-over-2 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 346px;
  height: 28px;
  font-family: Inter, Helvetica;
  font-size: 6.9px;
  font-weight: 400;
  line-height: 9.7px;
  position: absolute;
  top: 144px;
  left: 15px;
}

.element .humble-products {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  height: 9px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  line-height: 8.6px;
  position: absolute;
  top: 48px;
  left: 15px;
}

.element .rectangle-13 {
  background-color: silver;
  border-radius: 4.61px;
  width: 58px;
  height: 58px;
  position: absolute;
  top: 72px;
  left: 15px;
}

.element .text-wrapper-70 {
  color: #4c4c4c33;
  letter-spacing: -.02px;
  white-space: nowrap;
  height: 9px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  line-height: 8.6px;
  position: absolute;
  top: 96px;
  left: 37px;
}

.element .frame-92 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4.61px;
  display: inline-flex;
  position: absolute;
  top: 77px;
  left: 89px;
}

.element .text-wrapper-71 {
  color: #000;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 19.4px;
  position: relative;
}

.element .rectangle-14 {
  background-color: #c4c4c4;
  border-radius: 4.61px;
  width: 168px;
  height: 154px;
  position: absolute;
  top: 47px;
  left: 369px;
}

.element .frame-93 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12.3px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 384px;
}

.element .text-wrapper-72 {
  color: #000;
  letter-spacing: -.04px;
  text-align: center;
  width: 139.19px;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 9.2px;
  font-weight: 600;
  line-height: 12.9px;
  position: relative;
}

.element .button-8 {
  all: unset;
  box-sizing: border-box;
  border-radius: 1.54px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 139.19px;
  display: flex;
  position: relative;
}

.element .button-base-11 {
  background-color: #9b9b9b;
  border-radius: 1.54px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.92px;
  padding: 5.77px 15.38px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .button-9 {
  all: unset;
  box-sizing: border-box;
  border-radius: 1.54px;
  justify-content: center;
  align-items: center;
  width: 139px;
  display: flex;
  position: absolute;
  top: 150px;
  left: 384px;
}

.element .button-base-12 {
  border: .38px solid #000;
  border-radius: 1.54px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 3.08px;
  padding: 5.77px 15.38px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .arrow-up {
  width: 8.95px;
  height: 8.95px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-10 {
  color: #000;
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 700;
  line-height: 8.6px;
  position: relative;
}

.element .text-wrapper-73 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  height: 8px;
  font-family: Inter, Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  line-height: 7.5px;
  position: absolute;
  top: 135px;
  left: 432px;
}

.element .frame-94 {
  justify-content: space-between;
  align-items: center;
  width: 139px;
  display: flex;
  position: absolute;
  top: 179px;
  left: 384px;
}

.element .img-9 {
  width: 6.99px;
  height: 6.99px;
  margin-left: -.42px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .share-7 {
  width: 7.86px;
  height: 7.86px;
  margin-left: -.47px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .stats {
  width: 8.73px;
  height: 8.73px;
  margin-top: -.37px;
  margin-bottom: -.37px;
  margin-left: -.52px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .image-4 {
  object-fit: cover;
  width: 53px;
  height: 17px;
  position: absolute;
  top: -3262px;
  left: 211px;
  transform: rotate(-8.4deg);
}

.element .icon-search-fill-instance {
  left: unset !important;
  top: unset !important;
  width: 9.23px !important;
  height: 9.23px !important;
  margin-top: -3262.79px !important;
  position: relative !important;
  transform: rotate(-8.4deg) !important;
}

.element .frame-95 {
  width: 7.69px;
  height: 7.69px;
  margin-top: -3263.64px;
  margin-left: -284.53px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .lorem-ipsum-is-3 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 398px;
  height: 55px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 593px;
  left: 66px;
}

.element .overlap-13 {
  width: 479px;
  height: 183px;
  position: absolute;
  top: 662px;
  left: 60px;
}

.element .overlap-14 {
  width: 479px;
  height: 183px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .lorem-ipsum-is-4 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 398px;
  height: 55px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 0;
  left: 7px;
}

.element .kevin-karthik-been {
  color: #0000;
  letter-spacing: -.03px;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 141px;
  left: 44px;
}

.element .text-wrapper-74 {
  color: #7c7c7c;
}

.element .text-wrapper-75 {
  color: #4c4c4c;
}

.element .overlap-15 {
  width: 479px;
  height: 139px;
  position: absolute;
  top: 32px;
  left: 0;
}

.element .text-wrapper-76 {
  color: #7c7c7c;
  letter-spacing: -.03px;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 87px;
  left: 130px;
}

.element .text-wrapper-77 {
  color: #4c4c4c;
  letter-spacing: -.04px;
  white-space: nowrap;
  height: 13px;
  font-family: Inter, Helvetica;
  font-size: 9.2px;
  font-weight: 700;
  line-height: 12.9px;
  position: absolute;
  top: 86px;
  left: 45px;
}

.element .line-39 {
  width: 473px;
  height: 70px;
  position: absolute;
  top: 34px;
  left: 3px;
  transform: rotate(-8.4deg);
}

.element .user-wrapper {
  background-color: #d9d9d9;
  border-radius: 16.15px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 81px;
  left: 0;
}

.element .user {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 11px;
  left: 11px;
  transform: rotate(-8.4deg);
}

.element .frame-96 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 7px;
}

.element .frame-97 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 69px;
}

.element .frame-98 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 128px;
}

.element .frame-99 {
  align-items: flex-start;
  gap: 3.46px;
  display: inline-flex;
  position: absolute;
  top: 43px;
  left: 185px;
}

.element .ellipse-12 {
  background-color: #d9d9d9;
  border-radius: 2.31px;
  width: 4.61px;
  height: 4.61px;
  position: relative;
}

.element .frame-100 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 167px;
  left: 44px;
}

.element .frame-101 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 167px;
  left: 107px;
}

.element .frame-102 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 167px;
  left: 165px;
}

.element .frame-103 {
  align-items: flex-start;
  gap: 3.46px;
  display: inline-flex;
  position: absolute;
  top: 172px;
  left: 222px;
}

.element .kevin-thanks-for {
  color: #0000;
  letter-spacing: -.03px;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 574px;
  left: 65px;
}

.element .kevin-karthik-been-2 {
  color: #0000;
  letter-spacing: -.03px;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 894px;
  left: 104px;
}

.element .kevin-karthik-been-3 {
  color: #0000;
  letter-spacing: -.03px;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 985px;
  left: 104px;
}

.element .text-wrapper-78 {
  color: #7c7c7c;
  letter-spacing: -.03px;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 872px;
  left: 189px;
}

.element .text-wrapper-79 {
  color: #7c7c7c;
  letter-spacing: -.03px;
  white-space: nowrap;
  height: 11px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 963px;
  left: 189px;
}

.element .text-wrapper-80 {
  color: #4c4c4c;
  letter-spacing: -.04px;
  white-space: nowrap;
  height: 13px;
  font-family: Inter, Helvetica;
  font-size: 9.2px;
  font-weight: 700;
  line-height: 12.9px;
  position: absolute;
  top: 544px;
  left: 65px;
}

.element .text-wrapper-81 {
  color: #4c4c4c;
  letter-spacing: -.04px;
  white-space: nowrap;
  height: 13px;
  font-family: Inter, Helvetica;
  font-size: 9.2px;
  font-weight: 700;
  line-height: 12.9px;
  position: absolute;
  top: 871px;
  left: 104px;
}

.element .text-wrapper-82 {
  color: #4c4c4c;
  letter-spacing: -.04px;
  white-space: nowrap;
  height: 13px;
  font-family: Inter, Helvetica;
  font-size: 9.2px;
  font-weight: 700;
  line-height: 12.9px;
  position: absolute;
  top: 962px;
  left: 104px;
}

.element .overlap-16 {
  width: 522px;
  height: 247px;
  position: absolute;
  top: 275px;
  left: 15px;
}

.element .right-arrow {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 39px;
  left: 3px;
  transform: rotate(-8.4deg);
}

.element .overlap-17 {
  width: 522px;
  height: 247px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .rectangle-15 {
  background-color: silver;
  border-radius: 4.61px;
  width: 146px;
  height: 102px;
  position: absolute;
  top: 0;
  left: 34px;
}

.element .rectangle-16 {
  background-color: silver;
  border-radius: 4.61px;
  width: 146px;
  height: 102px;
  position: absolute;
  top: 0;
  left: 189px;
}

.element .rectangle-17 {
  background-color: silver;
  border-radius: 4.61px;
  width: 146px;
  height: 102px;
  position: absolute;
  top: 0;
  left: 344px;
}

.element .image-gallery-6 {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 41px;
  left: 96px;
  transform: rotate(-8.4deg);
}

.element .image-gallery-7 {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 41px;
  left: 251px;
  transform: rotate(-8.4deg);
}

.element .image-gallery-8 {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 41px;
  left: 406px;
  transform: rotate(-8.4deg);
}

.element .frame-104 {
  align-items: center;
  gap: 280.69px;
  display: inline-flex;
  position: absolute;
  top: 127px;
  left: 33px;
}

.element .frame-105 {
  width: 31.53px;
  height: 34.22px;
  position: relative;
}

.element .overlap-group-10 {
  background-color: #d9d9d9;
  border-radius: 15.76px;
  width: 32px;
  height: 32px;
  position: relative;
}

.element .user-2 {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 11px;
  left: 11px;
  transform: rotate(-8.4deg);
}

.element .button-base-13 {
  border: .38px solid #000;
  border-radius: 1.54px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.08px;
  padding: 5.77px 15.38px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .line-40 {
  width: 517px;
  height: 77px;
  position: absolute;
  top: 78px;
  left: 3px;
  transform: rotate(-8.4deg);
}

.element .line-41 {
  width: 517px;
  height: 77px;
  position: absolute;
  top: 133px;
  left: 3px;
  transform: rotate(-8.4deg);
}

.element .right-arrow-2 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 39px;
  left: 496px;
  transform: rotate(-8.4deg);
}

.element .overlap-18 {
  width: 44px;
  height: 503px;
  position: absolute;
  top: 539px;
  left: 15px;
}

.element .ellipse-13 {
  background-color: #d9d9d9;
  border-radius: 21.92px;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .user-3 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 14px;
  left: 14px;
  transform: rotate(-8.4deg);
}

.element .rectangle-18 {
  background-color: #d9d9d9;
  width: 2px;
  height: 461px;
  position: absolute;
  top: 42px;
  left: 21px;
}

.element .frame-106 {
  background-color: #d9d9d9;
  border-radius: 16.15px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 866px;
  left: 60px;
}

.element .frame-107 {
  background-color: #d9d9d9;
  border-radius: 16.15px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 957px;
  left: 60px;
}

.element .frame-108 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 544px;
  left: 167px;
}

.element .frame-109 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 920px;
  left: 104px;
}

.element .frame-110 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 1011px;
  left: 104px;
}

.element .frame-111 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 920px;
  left: 166px;
}

.element .frame-112 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 1011px;
  left: 166px;
}

.element .frame-113 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 920px;
  left: 225px;
}

.element .frame-114 {
  background-color: #d9d9d9;
  border-radius: 11.54px;
  align-items: flex-start;
  gap: 3.85px;
  padding: 3.46px 13.46px;
  display: inline-flex;
  position: absolute;
  top: 1011px;
  left: 225px;
}

.element .frame-115 {
  align-items: center;
  gap: 19.23px;
  width: 256px;
  display: flex;
  position: absolute;
  top: 1119px;
  left: 17px;
}

.element .frame-116 {
  flex: none;
  margin-top: -4373.73px;
  margin-right: -25.87px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-117 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 9.23px;
  display: flex;
  position: relative;
}

.element .frame-118 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.08px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-83 {
  color: #000;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  position: relative;
}

.element .all-in-one-solution {
  color: #4c4c4c;
  letter-spacing: -.03px;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: relative;
}

.element .frame-119 {
  flex: none;
  align-items: center;
  gap: 17.3px;
  display: inline-flex;
  position: relative;
}

.element .frame-120 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4423.17px;
  margin-right: -105.17px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-84 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.27px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.element .frame-121 {
  flex: none;
  align-items: flex-start;
  gap: 9.23px;
  display: inline-flex;
  position: relative;
}

.element .frame-122 {
  align-items: center;
  gap: 19.23px;
  width: 256px;
  display: flex;
  position: absolute;
  top: 1119px;
  left: 282px;
}

.element .frame-123 {
  flex: none;
  margin-top: -4373.73px;
  margin-left: -52.18px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-124 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4423.17px;
  margin-left: -144.68px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-125 {
  align-items: center;
  gap: 19.23px;
  width: 256px;
  display: flex;
  position: absolute;
  top: 1202px;
  left: 17px;
}

.element .frame-126 {
  flex: none;
  margin-top: -4457.55px;
  margin-right: -25.87px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-127 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4506.99px;
  margin-right: -105.17px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-128 {
  align-items: center;
  gap: 19.23px;
  width: 256px;
  display: flex;
  position: absolute;
  top: 1202px;
  left: 282px;
}

.element .frame-129 {
  flex: none;
  margin-top: -4457.55px;
  margin-left: -52.18px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-130 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4506.99px;
  margin-left: -144.68px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-131 {
  align-items: flex-start;
  gap: 3.46px;
  display: inline-flex;
  position: absolute;
  top: 926px;
  left: 282px;
}

.element .frame-132 {
  align-items: flex-start;
  gap: 3.46px;
  display: inline-flex;
  position: absolute;
  top: 1016px;
  left: 282px;
}

.element .frame-133 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.15px;
  display: inline-flex;
  position: absolute;
  top: 1070px;
  left: 17px;
}

.element .text-wrapper-85 {
  color: #252323;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 19.4px;
  position: relative;
}

.element .footers-2 {
  background-color: #c8c8c8;
  width: 554px;
  height: 145px;
  position: absolute;
  top: 1336px;
  left: 0;
  overflow: hidden;
}

.element .image-5 {
  object-fit: cover;
  width: 53px;
  height: 17px;
  position: absolute;
  top: -4613px;
  left: 195px;
  transform: rotate(-8.4deg);
}

.element .social-buttons-2 {
  flex: none;
  margin-top: -4587.84px;
  margin-right: -198.92px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .divider-3 {
  width: 553.69px;
  height: 1px;
  margin-top: -4569.42px;
  margin-right: -233.83px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .product-content-page {
  background-color: var(--neutral-100);
  border-radius: 12px;
  width: 554px;
  height: 1117px;
  position: absolute;
  top: 162px;
  left: 1439px;
  overflow: hidden;
  transform: rotate(8.4deg);
}

.element .overlap-19 {
  width: 554px;
  height: 246px;
  position: absolute;
  top: 34px;
  left: 0;
}

.element .rectangle-19 {
  background-color: #d9d9d9;
  width: 554px;
  height: 212px;
  position: absolute;
  top: 34px;
  left: 0;
}

.element .frame-134 {
  align-items: flex-start;
  gap: 6.15px;
  width: 139px;
  display: flex;
  position: absolute;
  top: 90px;
  left: 373px;
}

.element .button-base-14 {
  background-color: #9b9b9b;
  border-radius: 1.54px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.92px;
  padding: 6.54px 12.3px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .38px .77px #1018280d;
}

.element .frame-135 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4.61px;
  display: inline-flex;
  position: absolute;
  top: 83px;
  left: 89px;
}

.element .frame-136 {
  justify-content: space-between;
  align-items: center;
  width: 139px;
  display: flex;
  position: absolute;
  top: 220px;
  left: 18px;
}

.element .lorem-ipsum-is-5 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 395px;
  height: 55px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 151px;
  left: 18px;
}

.element .frame-137 {
  background-color: #fff;
  border: .38px solid #000;
  border-radius: 4.61px;
  flex-direction: column;
  align-items: center;
  gap: 1.92px;
  padding: 13.07px 13.84px;
  display: inline-flex;
  position: absolute;
  top: 154px;
  left: 461px;
}

.element .frame-138 {
  width: 12.3px;
  height: 12.3px;
  margin-top: -3515.91px;
  margin-left: -1456.6px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-86 {
  color: #000;
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.2px;
  font-weight: 500;
  line-height: 12.9px;
  position: relative;
}

.element .image-6 {
  object-fit: cover;
  width: 55px;
  height: 24px;
  position: absolute;
  top: -4px;
  left: -1px;
  transform: rotate(-8.4deg);
}

.element .frame-139 {
  width: 10.48px !important;
  height: 10.48px !important;
  margin-top: -.62px !important;
  margin-bottom: -.62px !important;
  margin-left: -.62px !important;
  position: relative !important;
  transform: rotate(-8.4deg) !important;
}

.element .frame-140 {
  width: 7.69px;
  height: 7.69px;
  margin-top: -3337.71px;
  margin-left: -1514.34px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .footers-3 {
  background-color: #c8c8c8;
  width: 554px;
  height: 145px;
  position: absolute;
  top: 972px;
  left: 0;
  overflow: hidden;
}

.element .image-7 {
  object-fit: cover;
  width: 53px;
  height: 17px;
  position: absolute;
  top: -4323px;
  left: -1035px;
  transform: rotate(-8.4deg);
}

.element .social-buttons-3 {
  flex: none;
  margin-top: -4297.78px;
  margin-left: -1030.89px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .divider-4 {
  width: 553.69px;
  height: 1px;
  margin-top: -4279.36px;
  margin-left: -995.98px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .right-arrow-3 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 365px;
  left: 18px;
  transform: rotate(-8.4deg);
}

.element .overlap-20 {
  border-radius: 4.61px;
  width: 146px;
  height: 102px;
  position: absolute;
  top: 326px;
  left: 49px;
}

.element .rectangle-20 {
  background-color: silver;
  border-radius: 4.61px;
  width: 146px;
  height: 102px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .image-gallery-9 {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 41px;
  left: 63px;
  transform: rotate(-8.4deg);
}

.element .overlap-21 {
  border-radius: 4.61px;
  width: 146px;
  height: 102px;
  position: absolute;
  top: 326px;
  left: 204px;
}

.element .overlap-22 {
  border-radius: 4.61px;
  width: 146px;
  height: 102px;
  position: absolute;
  top: 326px;
  left: 359px;
}

.element .right-arrow-4 {
  width: 22px;
  height: 22px;
  position: absolute;
  top: -3038px;
  left: -442px;
  transform: rotate(-8.4deg);
}

.element .frame-141 {
  align-items: center;
  gap: 178.41px;
  display: inline-flex;
  position: absolute;
  top: 461px;
  left: 18px;
}

.element .text-wrapper-87 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  width: 288px;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.element .frame-142 {
  align-items: flex-start;
  gap: 6.15px;
  width: 234px;
  display: flex;
  position: absolute;
  top: 505px;
  left: 18px;
}

.element .frame-143 {
  width: 13.97px;
  height: 13.97px;
  margin-top: -.83px;
  margin-left: -.83px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-144 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3.08px;
  width: 215.71px;
  display: flex;
  position: relative;
}

.element .text-wrapper-88 {
  color: #000;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: relative;
}

.element .text-wrapper-89 {
  color: #4c4c4c;
  letter-spacing: -.02px;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.element .frame-145 {
  align-items: flex-start;
  gap: 6.15px;
  width: 238px;
  display: flex;
  position: absolute;
  top: 505px;
  left: 282px;
}

.element .frame-146 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3.08px;
  width: 202.64px;
  display: flex;
  position: relative;
}

.element .frame-147 {
  align-items: flex-start;
  gap: 6.15px;
  width: 235px;
  display: flex;
  position: absolute;
  top: 566px;
  left: 17px;
}

.element .frame-148 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3.08px;
  width: 216.48px;
  display: flex;
  position: relative;
}

.element .frame-149 {
  align-items: flex-start;
  gap: 6.15px;
  width: 235px;
  display: flex;
  position: absolute;
  top: 629px;
  left: 17px;
}

.element .frame-150 {
  align-items: flex-start;
  gap: 6.15px;
  width: 224px;
  display: flex;
  position: absolute;
  top: 567px;
  left: 281px;
}

.element .frame-151 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 3.08px;
  display: flex;
  position: relative;
}

.element .take-advantage-of {
  color: #000;
  letter-spacing: -.03px;
  align-self: stretch;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: relative;
}

.element .frame-152 {
  align-items: flex-start;
  gap: 6.15px;
  width: 224px;
  display: flex;
  position: absolute;
  top: 630px;
  left: 281px;
}

.element .frame-153 {
  align-items: center;
  gap: 19.23px;
  width: 256px;
  display: flex;
  position: absolute;
  top: 769px;
  left: 17px;
}

.element .frame-154 {
  flex: none;
  margin-top: -4.42px;
  margin-left: -8.33px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-155 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4147.72px;
  margin-left: -1109.56px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-156 {
  align-items: center;
  gap: 19.23px;
  width: 256px;
  display: flex;
  position: absolute;
  top: 769px;
  left: 282px;
}

.element .frame-157 {
  flex: none;
  margin-top: -4098.28px;
  margin-left: -1281.99px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-158 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4147.72px;
  margin-left: -1374.49px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-159 {
  align-items: center;
  gap: 19.23px;
  width: 256px;
  display: flex;
  position: absolute;
  top: 857px;
  left: 17px;
}

.element .frame-160 {
  flex: none;
  margin-top: -4185.95px;
  margin-left: -1017.07px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-161 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4235.39px;
  margin-left: -1109.56px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-162 {
  align-items: center;
  gap: 19.23px;
  width: 256px;
  display: flex;
  position: absolute;
  top: 857px;
  left: 282px;
}

.element .frame-163 {
  flex: none;
  margin-top: -4185.95px;
  margin-left: -1281.99px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-164 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4235.39px;
  margin-left: -1374.49px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .text-wrapper-90 {
  color: #252323;
  letter-spacing: -.06px;
  white-space: nowrap;
  height: 19px;
  font-family: Inter, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 19.4px;
  position: absolute;
  top: 703px;
  left: 17px;
}

.element .text-wrapper-91 {
  color: #4c4c4c;
  letter-spacing: -.03px;
  width: 223px;
  height: 22px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.8px;
  position: absolute;
  top: 729px;
  left: 17px;
}

.element .product-page {
  background-color: var(--neutral-100);
  border-radius: 12px;
  width: 554px;
  height: 1160px;
  position: absolute;
  top: 8px;
  left: 820px;
  overflow: hidden;
  transform: rotate(8.4deg);
}

.element .overlap-23 {
  width: 554px;
  height: 106px;
  position: absolute;
  top: 34px;
  left: 0;
}

.element .frame-165 {
  background-color: #d9d9d9;
  flex-direction: column;
  align-items: center;
  gap: 3.85px;
  width: 554px;
  padding: 15.76px 117.66px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 0;
}

.element .frame-166 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4.61px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-92 {
  color: #000;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 17.7px;
  font-weight: 700;
  line-height: 24.8px;
  position: relative;
}

.element .image-8 {
  object-fit: cover;
  width: 55px;
  height: 9px;
  position: absolute;
  top: 12px;
  left: 0;
  transform: rotate(-8.4deg);
}

.element .icons-instance {
  width: 10.48px !important;
  height: 9.29px !important;
  margin-bottom: -.62px !important;
  margin-left: -.54px !important;
  position: relative !important;
  transform: rotate(-8.4deg) !important;
}

.element .frame-167 {
  width: 8.73px;
  height: 8.73px;
  margin-right: -.52px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-168 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.15px;
  display: inline-flex;
  position: absolute;
  top: 158px;
  left: 15px;
}

.element .overlap-24 {
  width: 550px;
  height: 115px;
  position: absolute;
  top: 223px;
  left: 1px;
}

.element .frame-169 {
  align-items: flex-start;
  gap: 7.69px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 14px;
}

.element .right-arrow-5 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 45px;
  left: 2px;
  transform: rotate(-8.4deg);
}

.element .right-arrow-6 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 45px;
  left: 523px;
  transform: rotate(-8.4deg);
}

.element .overlap-25 {
  width: 569px;
  height: 786px;
  position: absolute;
  top: 375px;
  left: 0;
}

.element .rectangle-21 {
  background-color: #fbfbfb;
  width: 554px;
  height: 644px;
  position: absolute;
  top: 0;
  left: 15px;
}

.element .frame-170 {
  align-items: center;
  gap: 178.41px;
  display: inline-flex;
  position: absolute;
  top: 12px;
  left: 15px;
}

.element .text-wrapper-93 {
  color: #000;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 9.2px;
  font-weight: 700;
  line-height: 12.9px;
  position: relative;
}

.element .frame-171 {
  width: 10.48px;
  height: 10.48px;
  margin-right: -.62px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-172 {
  flex-direction: column;
  align-items: flex-end;
  gap: 9.23px;
  display: inline-flex;
  position: absolute;
  top: 97px;
  left: 22px;
}

.element .frame-173 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 9.23px;
  display: inline-flex;
  position: relative;
}

.element .frame-174 {
  flex: none;
  align-items: center;
  gap: 159.96px;
  width: 509.48px;
  display: flex;
  position: relative;
}

.element .frame-175 {
  flex: none;
  align-items: center;
  gap: 19.23px;
  display: inline-flex;
  position: relative;
}

.element .frame-176 {
  flex: none;
  margin-top: -4.69px;
  margin-bottom: -4.69px;
  margin-left: -4.69px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-177 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.23px;
  display: inline-flex;
  position: relative;
}

.element .frame-178 {
  background-color: #fff;
  border: .38px solid #000;
  border-radius: 4.61px;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 1.92px;
  padding: 13.07px 13.84px;
  display: inline-flex;
  position: relative;
}

.element .frame-179 {
  width: 13.97px;
  height: 13.97px;
  margin-top: -.83px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .line-42 {
  width: 503.68px;
  height: 74.79px;
  margin-bottom: -37.2px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-180 {
  width: 10.48px;
  height: 10.48px;
  margin-top: -.62px;
  margin-bottom: -.63px;
  margin-left: -.62px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .line-43 {
  width: 503.68px;
  height: 52.27px;
  margin-bottom: -14.8px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-181 {
  flex: none;
  margin-top: -4.49px;
  margin-left: -7.3px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-182 {
  width: 9.23px;
  height: 9.23px;
  margin-top: -4220.61px;
  margin-left: -482.83px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .frame-183 {
  width: 12.3px;
  height: 12.3px;
  margin-top: -4183.22px;
  margin-left: -841.54px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .line-44 {
  width: 509.09px;
  height: 1px;
  margin-top: -4130.9px;
  margin-left: -385.91px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .footers-4 {
  background-color: #c8c8c8;
  width: 554px;
  height: 145px;
  position: absolute;
  top: 641px;
  left: 0;
  overflow: hidden;
}

.element .image-9 {
  object-fit: cover;
  width: 53px;
  height: 17px;
  position: absolute;
  top: -4304px;
  left: -403px;
  transform: rotate(-8.4deg);
}

.element .social-buttons-4 {
  flex: none;
  margin-top: -4278.51px;
  margin-left: -399.16px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .divider-5 {
  width: 553.69px;
  height: 1px;
  margin-top: -4260.09px;
  margin-left: -364.25px;
  position: relative;
  transform: rotate(-8.4deg);
}

.element .concrete-urban-wall {
  width: 1678px;
  height: 876px;
  position: absolute;
  top: 8574px;
  left: 122px;
}

.element .notebook {
  width: 636px;
  height: 690px;
  position: absolute;
  top: 7820px;
  left: 1164px;
}

.element .tab-instance {
  width: 534px !important;
  height: 505px !important;
  padding: 32px 80px !important;
  position: absolute !important;
  top: 7251px !important;
  left: 693px !important;
}

.element .design-component-instance-node {
  margin-bottom: unset !important;
  margin-top: unset !important;
}

.element .tab-2 {
  width: 533px !important;
  height: 505px !important;
  padding: 32px 75px !important;
  display: flex !important;
  position: absolute !important;
  top: 7251px !important;
  left: 120px !important;
}

.element .component-1 {
  width: 533px !important;
  height: 505px !important;
  padding: 32px 80px !important;
  position: absolute !important;
  top: 7251px !important;
  left: 1267px !important;
}

.element .shadow-overlayed {
  width: 980px;
  height: 690px;
  position: absolute;
  top: 7820px;
  left: 122px;
}

.element .frame-184 {
  background-image: url("rectangle-24.312d49d2.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 3880px;
  position: absolute;
  top: 9538px;
  left: 0;
  overflow: hidden;
}

.element .homepage-3 {
  width: 484px;
  height: 2267px;
  position: absolute;
  top: 199px;
  left: 0;
}

.element .humble {
  background-color: var(--neutral-90);
  border: 8px solid #5151511f;
  border-radius: 12px;
  width: 836px;
  height: 1490px;
  position: absolute;
  top: 111px;
  left: 542px;
  overflow: hidden;
}

.element .overlap-26 {
  width: 820px;
  height: 725px;
  position: absolute;
  top: 41px;
  left: 0;
}

.element .frame-185 {
  background-color: var(--neutral-90);
  width: 820px;
  height: 645px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.element .overlap-27 {
  width: 486px;
  height: 408px;
  position: absolute;
  top: 0;
  left: 576px;
}

.element .ellipse-14 {
  background-color: var(--tertiary-05);
  filter: blur(341.55px);
  border-radius: 193.55px;
  width: 387px;
  height: 387px;
  position: absolute;
  top: 21px;
  left: 99px;
}

.element .frame-186 {
  width: 244px;
  height: 251px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-group-11 {
  width: 564px;
  height: 265px;
  position: absolute;
  top: 6px;
  left: 0;
}

.element .frame-187 {
  flex-direction: column;
  align-items: center;
  gap: 18.22px;
  display: inline-flex;
  position: absolute;
  top: 69px;
  left: 257px;
}

.element .frame-188 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 4.55px;
  display: inline-flex;
  position: relative;
}

.element .frame-189 {
  background-color: var(--tertiary-05);
  border-radius: 18.22px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  padding: 1.14px 6.83px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-94 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 8px;
  font-weight: 700;
  line-height: 11.2px;
  position: relative;
}

.element .frame-190 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13.66px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-95 {
  color: var(--neutral-20);
  letter-spacing: -1.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Demi, Helvetica;
  font-size: 31.9px;
  font-weight: 400;
  line-height: 38.3px;
  position: relative;
}

.element .text-wrapper-96 {
  color: var(--neutral-20);
  letter-spacing: 0;
  text-align: center;
  width: 299.43px;
  font-family: Le Monde Livre Cla Std-Regular, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 15.9px;
  position: relative;
}

.element .CTA {
  all: unset;
  background-color: var(--neutral-20);
  box-sizing: border-box;
  border-radius: 2.28px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  padding: 6.83px 13.66px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-97 {
  color: var(--neutral-90);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 8px;
  font-weight: 700;
  line-height: 11.2px;
  position: relative;
}

.element .frame-191 {
  width: 266px;
  height: 265px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .vector-7 {
  width: 820px;
  height: 276px;
  position: absolute;
  top: 449px;
  left: 0;
}

.element .frame-192 {
  flex-direction: column;
  align-items: flex-start;
  gap: 27.32px;
  display: inline-flex;
  position: absolute;
  top: 403px;
  left: 46px;
}

.element .frame-193 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.11px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-98 {
  color: #fff;
  letter-spacing: -.1px;
  width: 230.55px;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 20.5px;
  font-weight: 700;
  line-height: 24.6px;
  position: relative;
}

.element .text-wrapper-99 {
  color: #fff;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .frame-194 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.66px;
  width: 233.96px;
  display: flex;
  position: relative;
}

.element .frame-195 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 9.11px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-196 {
  flex: 1;
  align-items: center;
  gap: 6.83px;
  display: flex;
  position: relative;
}

.element .frame-197 {
  background-color: #1f1c1a;
  border-radius: 11.39px;
  width: 22.77px;
  height: 22.77px;
  position: relative;
}

.element .overlap-group-12 {
  width: 15px;
  height: 13px;
  position: relative;
  top: 5px;
  left: 2px;
}

.element .group-4 {
  width: 12px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 3px;
}

.element .vector-8 {
  width: 5px;
  height: 4px;
  position: absolute;
  top: 3px;
  left: 0;
}

.element .vector-9 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 6px;
  left: 3px;
}

.element .text-wrapper-100 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.4px;
  font-weight: 500;
  line-height: 15.9px;
  position: relative;
}

.element .frame-198 {
  width: 22.77px;
  height: 22.77px;
  position: relative;
}

.element .frame-199 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.11px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .vector-wrapper {
  background-image: url("group-37.56a49b24.png");
  background-size: 100% 100%;
  width: 19px;
  height: 17px;
  position: relative;
  top: 3px;
  left: 2px;
}

.element .vector-10 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 7px;
  left: 3px;
}

.element .group-5 {
  width: 18px;
  height: 13px;
  position: relative;
  top: 5px;
  left: 3px;
}

.element .overlap-group-13 {
  height: 13px;
  position: relative;
}

.element .vector-11 {
  width: 17px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: 1px;
}

.element .vector-12 {
  width: 18px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .vector-13 {
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.element .vector-14 {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 9px;
  left: 4px;
}

.element .subtract-2 {
  width: 2px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 13px;
}

.element .frame-wrapper-2 {
  background-color: var(--neutral-90);
  border: .57px solid;
  border-color: var(--neutral-60);
  border-radius: 6.83px;
  align-items: center;
  padding: 18.22px;
  display: inline-flex;
  position: absolute;
  top: 312px;
  left: 324px;
  overflow: hidden;
}

.element .price {
  width: 80.55px;
  height: 38px;
  position: relative;
}

.element .frame-200 {
  align-items: flex-end;
  gap: 2.28px;
  display: inline-flex;
  position: relative;
}

.element .div-6 {
  flex: none;
  align-items: flex-start;
  gap: 2.28px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-101 {
  color: var(--basewhite);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 20.5px;
  font-weight: 700;
  line-height: 24.6px;
  position: relative;
}

.element .text-wrapper-102 {
  color: var(--basewhite);
  letter-spacing: -1.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Demi, Helvetica;
  font-size: 31.9px;
  font-weight: 400;
  line-height: 38.3px;
  position: relative;
}

.element .text-wrapper-103 {
  color: #999492;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .frame-201 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 13.66px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-104 {
  color: #cac4c2;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .line-45 {
  width: 165.65px;
  height: 1px;
  position: relative;
}

.element .frame-202 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.83px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-203 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.28px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .img-10 {
  width: 13.66px;
  height: 13.66px;
  position: relative;
}

.element .text-wrapper-105 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.24px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .text-wrapper-106 {
  color: #fff;
  letter-spacing: 0;
  flex: 1;
  margin-top: -.24px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .frame-204 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 2.28px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-107 {
  color: #fff;
  letter-spacing: 0;
  flex: 1;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .button-primary-with-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 5.69px;
  padding: 6.83px 5.69px;
  display: inline-flex;
  position: relative;
}

.element .button-primary-with-icon {
  background-color: #fff !important;
  border-radius: 2.85px !important;
  flex: none !important;
  gap: 5.69px !important;
  width: 166.22px !important;
  padding: 6.83px 9.11px !important;
}

.element .button-primary-with-instance {
  gap: 5.12px !important;
}

.element .button-primary-with-icon-instance {
  color: var(--neutral-90) !important;
  font-family: var(--web-paragraph-BLD-font-family) !important;
  font-size: var(--web-paragraph-BLD-font-size) !important;
  font-style: var(--web-paragraph-BLD-font-style) !important;
  font-weight: var(--web-paragraph-BLD-font-weight) !important;
  letter-spacing: var(--web-paragraph-BLD-letter-spacing) !important;
  line-height: var(--web-paragraph-BLD-line-height) !important;
  margin-top: -.57px !important;
}

.element .frame-wrapper-3 {
  background-color: var(--neutral-90);
  border: .57px solid #0000;
  border-image: linear-gradient(#c8963e, #c8963e) 1;
  border-radius: 6.83px;
  align-items: center;
  padding: 18.22px;
  display: inline-flex;
  position: absolute;
  top: 294px;
  left: 565px;
}

.element .price-2 {
  width: 177.61px;
  height: 38px;
  position: relative;
}

.element .frame-205 {
  align-items: center;
  gap: 13.66px;
  width: 178px;
  display: flex;
  position: relative;
}

.element .frame-206 {
  flex: none;
  align-items: flex-end;
  gap: 2.28px;
  display: inline-flex;
  position: relative;
}

.element .frame-207 {
  flex: none;
  align-items: center;
  gap: 2.28px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-108 {
  color: var(--neutral-60);
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Le Monde Livre Std-Regular, Helvetica;
  font-size: 20.5px;
  font-weight: 400;
  line-height: 24.6px;
  position: relative;
}

.element .text-wrapper-109 {
  color: var(--neutral-60);
  letter-spacing: -.35px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 30.1px;
  text-decoration: line-through;
  position: relative;
}

.element .line-46 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.43px;
  position: relative;
}

.element .text-wrapper-110 {
  color: var(--basewhite);
  letter-spacing: 0;
  flex: 1;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .text-wrapper-111 {
  color: var(--basewhite);
  letter-spacing: 0;
  flex: 1;
  margin-top: -.24px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .button-primary-with-2 {
  flex: none;
  width: 166.22px;
  position: relative;
}

.element .header-instance {
  height: unset !important;
  gap: 5.69px !important;
  width: 820px !important;
  padding: 9.11px 45.54px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.element .header-2 {
  gap: 18.22px !important;
}

.element .header-3 {
  width: 56.93px !important;
  height: 22.77px !important;
}

.element .header-4 {
  width: 159.39px !important;
}

.element .header-5 {
  gap: 5.69px !important;
  padding: 1.14px 0 !important;
}

.element .header-6 {
  margin-top: -.57px !important;
}

.element .header-7 {
  gap: 5.69px !important;
  width: 39.85px !important;
  padding: 2.28px !important;
  display: flex !important;
}

.element .header-8 {
  width: 162.24px !important;
}

.element .header-9 {
  border-top-width: .57px !important;
  border-bottom-width: .57px !important;
  border-left-width: .57px !important;
  border-radius: 22.77px 0 0 22.77px !important;
  margin: -.57px !important;
  padding: 3.98px 6.83px 3.98px 7.97px !important;
  box-shadow: inset 0 .57px 1.14px #eee !important;
}

.element .header-10 {
  white-space: nowrap !important;
  width: 25.62px !important;
  height: 13.66px !important;
  margin-top: -.57px !important;
}

.element .header-11 {
  margin-top: -.57px !important;
  margin-bottom: -.57px !important;
  margin-right: -.57px !important;
}

.element .header-12 {
  gap: 13.66px !important;
}

.element .header-13 {
  border-radius: 2.28px !important;
}

.element .header-14 {
  border-radius: 2.28px !important;
  gap: 2.85px !important;
  padding: 4.55px 6.83px !important;
}

.element .quotes {
  background-color: #075a5e;
  width: 820px;
  height: 297px;
  position: absolute;
  top: 766px;
  left: 0;
}

.element .for-me-the-access-to {
  color: var(--white);
  letter-spacing: -.13px;
  text-align: center;
  width: 473px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 15.9px;
  font-weight: 700;
  line-height: 19.1px;
  position: absolute;
  top: 106px;
  left: 173px;
}

.element .position-indicator {
  width: 116px;
  height: 2px;
  position: absolute;
  top: 276px;
  left: 352px;
}

.element .active {
  background-color: var(--white);
  border-radius: 1.14px;
  width: 27px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .default-2 {
  background-color: var(--primary-light);
  border-radius: 1.14px;
  width: 27px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 30px;
}

.element .default-3 {
  background-color: var(--white);
  border-radius: 1.14px;
  width: 27px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 59px;
}

.element .default-4 {
  background-color: var(--white);
  border-radius: 1.14px;
  width: 27px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 88px;
}

.element .avatar-instance {
  width: 123px !important;
  height: 31px !important;
  position: absolute !important;
  top: 208px !important;
  left: 348px !important;
}

.element .avatar-2 {
  white-space: nowrap !important;
  left: 42px !important;
}

.element .avatar-3 {
  top: 17px !important;
  left: 43px !important;
}

.element .avatar-4 {
  width: 30px !important;
  height: 30px !important;
  top: 1px !important;
}

.element .text-wrapper-112 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  height: 25px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 20.5px;
  font-weight: 700;
  line-height: 24.6px;
  position: absolute;
  top: 57px;
  left: 251px;
}

.element .frame-208 {
  background-color: var(--secondary-05);
  flex-direction: column;
  align-items: center;
  gap: 18.22px;
  width: 820px;
  padding: 36.43px 0;
  display: flex;
  position: absolute;
  top: 1063px;
  left: 0;
}

.element .text-wrapper-113 {
  color: var(--neutral-90);
  letter-spacing: -1.02px;
  text-align: center;
  width: 265.27px;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Demi, Helvetica;
  font-size: 31.9px;
  font-weight: 400;
  line-height: 38.3px;
  position: relative;
}

.element .footers-5 {
  background-color: #1f1c1a;
  width: 820px;
  height: 219px;
  position: absolute;
  top: 1255px;
  left: 0;
}

.element .address {
  width: 120px;
  height: 71px;
  position: absolute;
  top: 88px;
  left: 68px;
}

.element .element-upper-rupert {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: 13.7px;
  position: absolute;
  top: -1px;
  left: 0;
}

.element .text-wrapper-114 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: 13.7px;
  position: absolute;
  top: 17px;
  left: 0;
}

.element .text-wrapper-115 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: 13.7px;
  position: absolute;
  top: 35px;
  left: 1px;
}

.element .company-links {
  align-items: flex-start;
  gap: 9.11px;
  display: inline-flex;
  position: absolute;
  top: 36px;
  left: 642px;
}

.element .text-wrapper-116 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .text-wrapper-117 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 6.8px;
  font-weight: 500;
  line-height: 9.6px;
  position: absolute;
  top: 198px;
  left: 348px;
}

.element .divider-6 {
  width: 820px;
  height: 1px;
  position: absolute;
  top: 188px;
  left: 0;
}

.element .frame-209 {
  width: 73px;
  height: 20px;
  position: absolute;
  top: 42px;
  left: 69px;
}

.element .frame-210 {
  width: 142px;
  height: 23px;
  position: absolute;
  top: 62px;
  left: 631px;
}

.element .sign-up {
  background-color: #fff;
  border: 8px solid #5151511f;
  border-radius: 12px;
  width: 836px;
  height: 738px;
  position: absolute;
  top: 1642px;
  left: 542px;
  overflow: hidden;
}

.element .overlap-28 {
  width: 820px;
  height: 866px;
  position: absolute;
  top: -145px;
  left: 0;
}

.element .overlap-29 {
  width: 820px;
  height: 657px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-30 {
  width: 820px;
  height: 469px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .rectangle-22 {
  width: 217px;
  height: 194px;
  position: absolute;
  top: 145px;
  left: 0;
}

.element .rectangle-23 {
  object-fit: cover;
  width: 217px;
  height: 324px;
  position: absolute;
  top: 145px;
  left: 602px;
}

.element .frame-211 {
  width: 79px;
  height: 21px;
  position: absolute;
  top: 163px;
  left: 46px;
}

.element .rectangle-24 {
  background-color: #075a5e;
  border-radius: 6.83px;
  width: 357px;
  height: 203px;
  position: absolute;
  top: 0;
  left: 231px;
}

.element .container-wrapper {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.69px;
  width: 820px;
  padding: 13.66px 45.54px;
  display: flex;
  position: absolute;
  top: 145px;
  left: 0;
}

.element .quick-links {
  flex: none;
  position: relative;
}

.element .CTA-s-2 {
  flex: none;
  align-items: center;
  gap: 13.66px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-118 {
  color: var(--neutral-60);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .button-10 {
  all: unset;
  background-color: var(--primary-02);
  box-sizing: border-box;
  border-radius: 2.28px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.element .button-base-15 {
  all: unset;
  background-color: var(--primary-02);
  box-sizing: border-box;
  border-radius: 2.28px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 2.85px;
  padding: 6.83px 7.97px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .57px 1.14px #1018280d;
}

.element .text-11 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .rectangle-25 {
  object-fit: cover;
  width: 218px;
  height: 251px;
  position: absolute;
  top: 352px;
  left: 0;
}

.element .frame-212 {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  width: 357px;
  padding: 31.88px 37px;
  display: flex;
  position: absolute;
  top: 216px;
  left: 231px;
}

.element .frame-213 {
  flex-direction: column;
  align-items: flex-start;
  gap: 36.43px;
  width: 252.75px;
  display: flex;
  position: relative;
}

.element .frame-214 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 22.77px;
  margin-right: -.25px;
  display: inline-flex;
  position: relative;
}

.element .frame-215 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.83px;
  display: inline-flex;
  position: relative;
}

.element .ready-to-build {
  color: #0000;
  letter-spacing: -.35px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 30.1px;
  position: relative;
}

.element .text-wrapper-119 {
  color: #373432;
}

.element .text-wrapper-120 {
  color: #44bba4;
}

.element .text-wrapper-121 {
  color: #373432;
  letter-spacing: -.35px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Le Monde Livre Std-Demi, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 30.1px;
  position: relative;
}

.element .frame-216 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.66px;
  display: inline-flex;
  position: relative;
}

.element .frame-217 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.83px;
  width: 252.75px;
  display: flex;
  position: relative;
}

.element .text-wrapper-122 {
  color: #999492;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .frame-218 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.83px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-219 {
  border: .57px solid #cac4c2;
  border-radius: 2.28px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4.55px;
  padding: 9.11px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-12 {
  color: #686462;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.24px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .frame-220 {
  flex: none;
  align-items: center;
  gap: 4.55px;
  width: 252.75px;
  display: flex;
  position: relative;
}

.element .line-47 {
  flex: 1;
  height: 1px;
  position: relative;
}

.element .text-13 {
  color: #cac4c2;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .frame-221 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 18.22px;
  width: 252.75px;
  display: flex;
  position: relative;
}

.element .frame-222 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 11.39px;
  width: 252.75px;
  display: flex;
  position: relative;
}

.element .frame-223 {
  border: .57px solid #cac4c2;
  border-radius: 2.28px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5.69px;
  width: 100%;
  padding: 8.54px 9.11px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-123 {
  color: #999492;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .frame-224 {
  border: .57px solid #cac4c2;
  border-radius: 2.28px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5.69px;
  width: 100%;
  padding: 9.11px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .button-base-16 {
  background-color: var(--primary-02);
  border-radius: 2.28px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 2.85px;
  width: 100%;
  padding: 9.11px 13.66px;
  display: flex;
  position: relative;
  box-shadow: 0 .57px 1.14px #1018280d;
}

.element .text-14 {
  color: #fff;
  letter-spacing: .1px;
  text-align: center;
  flex: 1;
  margin-top: -.57px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 10.2px;
  font-weight: 700;
  line-height: 14.3px;
  position: relative;
}

.element .got-an-account {
  color: #0000;
  letter-spacing: 0;
  width: 281.78px;
  margin-right: -29.03px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: 11.2px;
  position: relative;
}

.element .text-wrapper-124 {
  color: #999492;
  font-weight: 500;
}

.element .text-wrapper-125 {
  color: #44bba4;
  font-family: Articulat CF-Bold, Helvetica;
  font-weight: 700;
}

.element .by-signing-up-you {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: -2.25px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .text-wrapper-126 {
  color: #999492;
}

.element .text-wrapper-127 {
  color: #075a5e;
}

.element .rectangle-26 {
  object-fit: cover;
  width: 217px;
  height: 250px;
  position: absolute;
  top: 617px;
  left: 0;
}

.element .rectangle-27 {
  width: 217px;
  height: 169px;
  position: absolute;
  top: 487px;
  left: 602px;
}

.element .rectangle-28 {
  width: 217px;
  height: 195px;
  position: absolute;
  top: 527px;
  left: 602px;
}

.element .rectangle-29 {
  width: 172px;
  height: 124px;
  position: absolute;
  top: 527px;
  left: 231px;
}

.element .rectangle-30 {
  width: 102px;
  height: 60px;
  position: absolute;
  top: 661px;
  left: 231px;
}

.element .rectangle-31 {
  width: 62px;
  height: 60px;
  position: absolute;
  top: 661px;
  left: 341px;
}

.element .rectangle-32 {
  width: 172px;
  height: 195px;
  position: absolute;
  top: 527px;
  left: 417px;
}

.element .signup-interests {
  background-color: #fff;
  border: 8px solid #5151511f;
  border-radius: 12px;
  width: 836px;
  height: 599px;
  position: absolute;
  top: 301px;
  left: 1435px;
  overflow: hidden;
}

.element .frame-225 {
  border-radius: 6.83px;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  width: 672px;
  padding: 31.88px 22.77px 36.43px;
  display: flex;
  position: absolute;
  top: 117px;
  left: 74px;
  overflow: hidden;
  box-shadow: 13.66px 18.22px 13.66px #00000014;
}

.element .frame-226 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 23.91px;
  display: flex;
  position: relative;
}

.element .frame-227 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 18.22px;
  display: inline-flex;
  position: relative;
}

.element .frame-228 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.83px;
  width: 626.18px;
  display: flex;
  position: relative;
}

.element .text-wrapper-128 {
  color: #1f1c1a;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 20.5px;
  font-weight: 700;
  line-height: 24.6px;
  position: relative;
}

.element .text-15 {
  color: #686462;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Le Monde Livre Cla Std-Regular, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 15.9px;
  position: relative;
}

.element .frame-229 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 19.35px;
  display: inline-flex;
  position: relative;
}

.element .frame-230 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.83px;
  width: 626.18px;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.element .frame-231 {
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 9.11px;
  display: flex;
  position: relative;
}

.element .frame-232 {
  background-color: var(--accent-01);
  border: .57px solid #075a5e;
  border-radius: 18.22px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.83px;
  padding: 5.12px 13.66px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-129 {
  color: #075a5e;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .frame-233 {
  border: .57px solid #cac4c2;
  border-radius: 18.22px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.83px;
  padding: 5.12px 13.66px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-130 {
  color: #686462;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .frame-234 {
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 6.83px;
  display: flex;
  position: relative;
}

.element .frame-235 {
  border: .57px solid #cac4c2;
  border-radius: 18.22px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  padding: 5.12px 18.22px;
  display: inline-flex;
  position: relative;
}

.element .frame-236 {
  border: 1.14px solid;
  border-color: var(--primary-02);
  border-radius: 18.22px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  padding: 5.12px 18.22px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-131 {
  color: var(--primary-02);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.14px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.element .frame-237 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.11px;
  width: 626.18px;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.element .text-16 {
  color: var(--primary-02);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .button-11 {
  all: unset;
  background-color: var(--primary-02);
  box-sizing: border-box;
  border-radius: 2.28px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 140.61px;
  display: flex;
  position: relative;
}

.element .button-base-17 {
  all: unset;
  background-color: var(--primary-02);
  box-sizing: border-box;
  border-radius: 2.28px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 2.85px;
  padding: 6.83px 13.66px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .57px 1.14px #1018280d;
}

.element .text-17 {
  color: #fff;
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 10.2px;
  font-weight: 700;
  line-height: 14.3px;
  position: relative;
}

.element .header-15 {
  width: 477px;
  height: 41px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .content-locked {
  background-color: #fff;
  border: 8px solid #5151511f;
  border-radius: 12px;
  width: 836px;
  height: 1748px;
  position: absolute;
  top: 2421px;
  left: 542px;
  overflow: hidden;
}

.element .overlap-31 {
  width: 820px;
  height: 405px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .blog {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.69px;
  width: 820px;
  height: 364px;
  display: flex;
  position: absolute;
  top: 41px;
  left: 0;
}

.element .frame-238 {
  background-image: url("image-5-1.6a7a3924.png");
  background-size: 100% 100%;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.element .header-16 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .57px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.69px;
  width: 820px;
  padding: 9.11px 45.54px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .div-7 {
  flex: none;
  align-items: center;
  gap: 18.22px;
  display: inline-flex;
  position: relative;
}

.element .humble-logo-2 {
  width: 56.93px;
  height: 22.77px;
  position: relative;
}

.element .frame-239 {
  align-items: flex-start;
  width: 159.39px;
  display: flex;
  position: relative;
}

.element .frame-240 {
  flex: 1;
  align-items: flex-start;
  gap: 5.69px;
  padding: 4.55px 6.83px;
  display: flex;
  position: relative;
}

.element .text-wrapper-132 {
  color: #075a5e;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  margin-right: -6.81px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .text-wrapper-133 {
  color: #075a5e;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .frame-241 {
  align-items: flex-start;
  width: 162.24px;
  display: flex;
  position: relative;
}

.element .div-container-wrapper {
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.element .search-wrapper {
  background-color: #fff;
  border-color: #cac4c2;
  border-top-style: solid;
  border-top-width: .57px;
  border-bottom-style: solid;
  border-bottom-width: .57px;
  border-left-style: solid;
  border-left-width: .57px;
  border-radius: 22.77px 0 0 22.77px;
  flex: 1;
  align-items: flex-start;
  padding: 3.98px 2.28px 3.98px 9.68px;
  display: flex;
  position: relative;
  box-shadow: inset 0 .57px 1.14px #eee;
}

.element .div-placeholder-wrapper {
  flex: 1;
  align-items: flex-start;
  padding: 1.71px 0 2.28px;
  display: flex;
  position: relative;
}

.element .div-placeholder-2 {
  flex: 1;
  align-items: flex-start;
  padding: 0 266.98px 0 0;
  display: flex;
  position: relative;
}

.element .text-wrapper-134 {
  color: #686462;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  margin-right: -172.02px;
  font-family: Roboto, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .search-3 {
  width: 27.32px;
  position: relative;
}

.element .CTA-s-3 {
  flex: none;
  align-items: center;
  gap: 9.11px;
  display: inline-flex;
  position: relative;
}

.element .frame-242 {
  border: .57px solid #075a5e;
  border-radius: 2.28px;
  flex: none;
  align-items: center;
  gap: 2.28px;
  padding: 3.42px 6.83px 3.42px 4.55px;
  display: inline-flex;
  position: relative;
}

.element .edit {
  width: 12.52px;
  height: 12.52px;
  position: relative;
}

.element .text-wrapper-135 {
  color: #075a5e;
  letter-spacing: 0;
  width: 21.06px;
  height: 13.66px;
  margin-top: -.57px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 8px;
  font-weight: 700;
  line-height: 11.2px;
  position: relative;
}

.element .frame-243 {
  background-image: url("ellipse-1-23.7834a27e.png");
  background-position: 50%;
  background-size: cover;
  width: 22.77px;
  height: 22.77px;
  position: relative;
}

.element .overlap-32 {
  width: 925px;
  height: 1573px;
  position: absolute;
  top: 405px;
  left: 0;
}

.element .footers-6 {
  background-color: #1f1c1a;
  width: 820px;
  height: 219px;
  position: absolute;
  top: 1107px;
  left: 0;
  overflow: hidden;
}

.element .divider-7 {
  width: 820px;
  height: 1px;
  position: absolute;
  top: -11661px;
  left: 465px;
}

.element .frame-244 {
  width: 73px;
  height: 19px;
  position: absolute;
  top: -11807px;
  left: 534px;
}

.element .frame-245 {
  width: 142px;
  height: 23px;
  position: absolute;
  top: -11787px;
  left: 1097px;
}

.element .frame-246 {
  align-items: flex-start;
  gap: 16px;
  width: 845px;
  display: flex;
  position: absolute;
  top: 369px;
  left: 80px;
}

.element .frame-247 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 32px;
  display: flex;
  position: relative;
}

.element .div-8 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .label-container {
  flex: none;
  align-items: center;
  gap: 4px;
  width: 150px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed {
  background-color: #826aed;
  border-radius: 4px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 5.5px 8px;
  display: inline-flex;
  position: relative;
}

.element .text-18 {
  color: #fff;
  letter-spacing: .28px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  position: relative;
}

.element .ellipse-15 {
  background-color: var(--neutral-30);
  border-radius: 2px;
  width: 4px;
  height: 4px;
  position: relative;
  transform: rotate(180deg);
}

.element .button-tall-fixed-2 {
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: -2px;
  padding: 5.5px 0;
  display: inline-flex;
  position: relative;
}

.element .text-19 {
  color: #1f1c1a;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .element-types-of {
  color: #1f1c1a;
  font-family: var(--web-h2-DMI-font-family);
  font-size: var(--web-h2-DMI-font-size);
  font-style: var(--web-h2-DMI-font-style);
  font-weight: var(--web-h2-DMI-font-weight);
  letter-spacing: var(--web-h2-DMI-letter-spacing);
  line-height: var(--web-h2-DMI-line-height);
  align-self: stretch;
  position: relative;
}

.element .line-48 {
  object-fit: cover;
  width: 739.73px;
  height: 1px;
  position: relative;
}

.element .frame-248 {
  width: 40px;
  height: 40px;
  position: relative;
}

.element .ellipse-16 {
  background-color: var(--neutral-30);
  border-radius: 18px;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .bookmark-add {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -11103px;
  left: -412px;
}

.element .frame-249 {
  justify-content: space-between;
  align-items: flex-start;
  width: 845px;
  display: flex;
  position: absolute;
  top: 588px;
  left: 80px;
}

.element .frame-250 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.element .frame-251 {
  flex: none;
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.element .overlap-group-14 {
  border: .45px solid #f3eff5;
  border-radius: 18.18px;
  width: 36px;
  height: 36px;
  position: relative;
  top: 2px;
  left: 2px;
}

.element .ellipse-17 {
  object-fit: cover;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.element .text-wrapper-136 {
  color: #373432;
  font-family: var(--web-paragraph-DMI-font-family);
  font-size: var(--web-paragraph-DMI-font-size);
  font-style: var(--web-paragraph-DMI-font-style);
  font-weight: var(--web-paragraph-DMI-font-weight);
  letter-spacing: var(--web-paragraph-DMI-letter-spacing);
  line-height: var(--web-paragraph-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-252 {
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 0 42px;
  display: inline-flex;
  position: relative;
}

.element .text-20 {
  color: #999492;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-21 {
  color: var(--primary-02);
  font-family: var(--web-caption-DMI-font-family);
  font-size: var(--web-caption-DMI-font-size);
  font-style: var(--web-caption-DMI-font-style);
  font-weight: var(--web-caption-DMI-font-weight);
  letter-spacing: var(--web-caption-DMI-letter-spacing);
  line-height: var(--web-caption-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .button-tall-fixed-wrapper {
  flex: none;
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.element .button-tall-fixed-3 {
  background-color: #f3eff5;
  border-radius: 32px;
  flex: none;
  align-items: center;
  gap: 4px;
  padding: 4px 12px 4px 4px;
  display: inline-flex;
  position: relative;
}

.element .img-11 {
  width: 24px;
  height: 24px;
  position: relative;
}

.element .text-22 {
  color: var(--neutrals-30);
  font-family: var(--web-paragraph-DMI-font-family);
  font-size: var(--web-paragraph-DMI-font-size);
  font-style: var(--web-paragraph-DMI-font-style);
  font-weight: var(--web-paragraph-DMI-font-weight);
  letter-spacing: var(--web-paragraph-DMI-letter-spacing);
  line-height: var(--web-paragraph-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-253 {
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  width: 845px;
  display: flex;
  position: absolute;
  top: 674px;
  left: 80px;
}

.element .frame-254 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.element .lorem-ipsum-is-6 {
  color: #686462;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  width: 843px;
  margin-top: -1px;
  position: relative;
}

.element .frame-255 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 834px;
  display: flex;
  position: relative;
}

.element .lorem-ipsum-is-wrapper {
  border-radius: 4px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 4px;
  display: inline-flex;
  position: relative;
}

.element .lorem-ipsum-is-7 {
  color: #0000;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  width: 479px;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-137 {
  color: #686462;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
}

.element .text-wrapper-138 {
  color: #075a5e;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
}

.element .frame-256 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.element .rectangle-33 {
  background-color: var(--primary-02);
  width: 171px;
  height: 10px;
  position: relative;
}

.element .text-wrapper-139 {
  color: #373432;
  font-family: var(--web-h5-DMI-font-family);
  font-size: var(--web-h5-DMI-font-size);
  font-style: var(--web-h5-DMI-font-style);
  font-weight: var(--web-h5-DMI-font-weight);
  letter-spacing: var(--web-h5-DMI-letter-spacing);
  line-height: var(--web-h5-DMI-line-height);
  width: 321px;
  position: relative;
}

.element .lorem-ipsum-is-8 {
  color: #0000;
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  width: 843px;
  position: relative;
}

.element .line-49 {
  object-fit: cover;
  width: 845px;
  height: 1px;
  margin-top: -10712px;
  margin-right: -385.27px;
  position: relative;
}

.element .frame-257 {
  flex: none;
  align-items: center;
  gap: 16px;
  width: 845px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-4 {
  all: unset;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  align-items: center;
  gap: 4px;
  padding: 5.5px 0;
  display: inline-flex;
  position: relative;
}

.element .maps-ugc {
  width: 24px;
  height: 24px;
  margin-top: -12152px;
  margin-right: -308.27px;
  position: relative;
}

.element .text-23 {
  color: #999492;
  font-family: var(--web-paragraph-DMI-font-family);
  font-size: var(--web-paragraph-DMI-font-size);
  font-style: var(--web-paragraph-DMI-font-style);
  font-weight: var(--web-paragraph-DMI-font-weight);
  letter-spacing: var(--web-paragraph-DMI-letter-spacing);
  line-height: var(--web-paragraph-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .ios-share {
  width: 24px;
  height: 24px;
  margin-top: -12152px;
  margin-right: -220.27px;
  position: relative;
}

.element .frame-258 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-24 {
  color: #373432;
  font-family: var(--web-h6-DMI-font-family);
  font-size: var(--web-h6-DMI-font-size);
  font-style: var(--web-h6-DMI-font-style);
  font-weight: var(--web-h6-DMI-font-weight);
  letter-spacing: var(--web-h6-DMI-letter-spacing);
  line-height: var(--web-h6-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-259 {
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.element .frame-260 {
  background-color: var(--neutral-30);
  border-radius: 24px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 6px 12px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-140 {
  color: #373432;
  font-family: var(--web-caption-DMI-font-family);
  font-size: var(--web-caption-DMI-font-size);
  font-style: var(--web-caption-DMI-font-style);
  font-weight: var(--web-caption-DMI-font-weight);
  letter-spacing: var(--web-caption-DMI-letter-spacing);
  line-height: var(--web-caption-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .rectangle-34 {
  background: linear-gradient(#fff0 27.57%, #fff 48.77%);
  width: 820px;
  height: 733px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-wrapper {
  background-color: #f3eff5;
  border-radius: 6.83px;
  width: 479px;
  height: 350px;
  position: absolute;
  top: 343px;
  left: 46px;
  overflow: hidden;
}

.element .overlap-33 {
  width: 447px;
  height: 337px;
  position: relative;
  top: 32px;
  left: 32px;
}

.element .overlap-34 {
  width: 447px;
  height: 337px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-261 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22.77px;
  width: 414.99px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-262 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16.51px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-141 {
  color: #000;
  letter-spacing: -.1px;
  width: 385.95px;
  margin-top: -.57px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 20.5px;
  font-weight: 700;
  line-height: 24.6px;
  position: relative;
}

.element .frame-263 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element .frame-264 {
  flex: none;
  align-items: flex-start;
  gap: 5.69px;
  width: 414.99px;
  padding: 6.83px 0;
  display: flex;
  position: relative;
}

.element .text-wrapper-142 {
  color: #686462;
  letter-spacing: -.09px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 13.7px;
  position: relative;
}

.element .frame-265 {
  flex: none;
  align-items: flex-start;
  gap: 6.83px;
  width: 414.99px;
  padding: 6.83px 0;
  display: flex;
  position: relative;
}

.element .text-wrapper-143 {
  color: #686462;
  letter-spacing: -.09px;
  width: 414.99px;
  margin-top: -.57px;
  margin-right: -20.49px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 13.7px;
  position: relative;
}

.element .text-wrapper-144 {
  color: #686462;
  letter-spacing: -.09px;
  width: 276.09px;
  margin-top: -.57px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 13.7px;
  position: relative;
}

.element .group-6 {
  width: 103px;
  height: 151px;
  position: absolute;
  top: 101px;
  left: 343px;
}

.element .group-7 {
  width: 115px;
  height: 90px;
  position: absolute;
  top: 246px;
  left: 171px;
}

.element .overlap-group-15 {
  height: 90px;
  position: relative;
}

.element .ellipse-18 {
  background-color: var(--overlay-32);
  border-radius: 50.24px / 31.67px;
  width: 100px;
  height: 63px;
  position: absolute;
  top: 13px;
  left: 7px;
  transform: rotate(17.27deg);
}

.element .subtract-3 {
  width: 99px;
  height: 45px;
  position: absolute;
  top: 27px;
  left: 7px;
}

.element .ellipse-19 {
  width: 63px;
  height: 49px;
  position: absolute;
  top: 11px;
  left: 43px;
}

.element .ellipse-20 {
  width: 49px;
  height: 20px;
  position: absolute;
  top: 43px;
  left: 57px;
}

.element .group-8 {
  width: 122px;
  height: 91px;
  position: absolute;
  top: 207px;
  left: 257px;
  transform: rotate(-160.98deg);
}

.element .overlap-35 {
  width: 123px;
  height: 129px;
  position: relative;
  top: -20px;
  left: -1px;
}

.element .vector-15 {
  width: 22px;
  height: 25px;
  position: absolute;
  top: 91px;
  left: 97px;
  transform: rotate(160.98deg);
}

.element .vector-16 {
  width: 9px;
  height: 10px;
  position: absolute;
  top: 102px;
  left: 111px;
  transform: rotate(160.98deg);
}

.element .rectangle-35 {
  width: 83px;
  height: 108px;
  position: absolute;
  top: 11px;
  left: 15px;
  transform: rotate(160.98deg);
}

.element .rectangle-36 {
  background-color: #075a5e;
  border-radius: 4.99px 0 0 4.99px;
  width: 11px;
  height: 20px;
  position: absolute;
  top: 22px;
  left: 6px;
  transform: rotate(36.06deg);
}

.element .button-primary-with-3 {
  width: unset !important;
  background-color: #075a5e !important;
  border-radius: 2.85px !important;
  align-items: flex-start !important;
  gap: 5.69px !important;
  padding: 9.11px !important;
  display: inline-flex !important;
  position: absolute !important;
  top: 207px !important;
  left: 0 !important;
}

.element .button-primary-with-4 {
  color: var(--basewhite) !important;
  font-family: var(--web-paragraph-BLD-font-family) !important;
  font-size: var(--web-paragraph-BLD-font-size) !important;
  font-style: var(--web-paragraph-BLD-font-style) !important;
  font-weight: var(--web-paragraph-BLD-font-weight) !important;
  letter-spacing: var(--web-paragraph-BLD-letter-spacing) !important;
  line-height: var(--web-paragraph-BLD-line-height) !important;
  margin-top: -.57px !important;
}

.element .frame-266 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 385px;
  display: flex;
  position: absolute;
  top: 773px;
  left: 975px;
}

.element .recommended-topics {
  color: #0000;
  font-family: var(--web-h6-DMI-font-family);
  font-size: var(--web-h6-DMI-font-size);
  font-style: var(--web-h6-DMI-font-style);
  font-weight: var(--web-h6-DMI-font-weight);
  letter-spacing: var(--web-h6-DMI-letter-spacing);
  line-height: var(--web-h6-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-145 {
  color: #373432;
  font-family: var(--web-h6-DMI-font-family);
  font-size: var(--web-h6-DMI-font-size);
  font-style: var(--web-h6-DMI-font-style);
  font-weight: var(--web-h6-DMI-font-weight);
  letter-spacing: var(--web-h6-DMI-letter-spacing);
  line-height: var(--web-h6-DMI-line-height);
}

.element .text-wrapper-146 {
  color: #44bba4;
  font-family: var(--web-h6-DMI-font-family);
  font-size: var(--web-h6-DMI-font-size);
  font-style: var(--web-h6-DMI-font-style);
  font-weight: var(--web-h6-DMI-font-weight);
  letter-spacing: var(--web-h6-DMI-letter-spacing);
  line-height: var(--web-h6-DMI-line-height);
}

.element .frame-267 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 385px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-5 {
  background-color: var(--neutral-20);
  border-radius: 30px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  display: inline-flex;
  position: relative;
}

.element .text-25 {
  color: #686462;
  font-family: var(--web-paragraph-DMI-font-family);
  font-size: var(--web-paragraph-DMI-font-size);
  font-style: var(--web-paragraph-DMI-font-style);
  font-weight: var(--web-paragraph-DMI-font-weight);
  letter-spacing: var(--web-paragraph-DMI-letter-spacing);
  line-height: var(--web-paragraph-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-268 {
  background-color: #f6f6f6;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 48px 80px 56px;
  display: inline-flex;
  position: absolute;
  top: 2015px;
  left: 0;
}

.element .frame-269 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .div-9 {
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.element .frame-270 {
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-147 {
  color: #075a5e;
  font-family: var(--web-h5-DMI-font-family);
  font-size: var(--web-h5-DMI-font-size);
  font-style: var(--web-h5-DMI-font-style);
  font-weight: var(--web-h5-DMI-font-weight);
  letter-spacing: var(--web-h5-DMI-letter-spacing);
  line-height: var(--web-h5-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-271 {
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.element .frame-272 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.element .rectangle-37 {
  object-fit: cover;
  width: 411px;
  height: 320px;
  margin-top: -12458px;
  margin-right: -385.27px;
  position: relative;
}

.element .frame-273 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 411px;
  display: flex;
  position: relative;
}

.element .frame-274 {
  align-items: center;
  gap: 16px;
  width: 411px;
  height: 26px;
  display: flex;
  position: relative;
}

.element .frame-275 {
  flex: 1;
  align-items: center;
  gap: 4px;
  margin-top: -2.5px;
  margin-bottom: -2.5px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-6 {
  background-color: #826aed;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 3px 8px;
  display: inline-flex;
  position: relative;
}

.element .text-26 {
  color: var(--neutrals-0);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  position: relative;
}

.element .ellipse-21 {
  background-color: #d9d9d9;
  border-radius: 2px;
  width: 4px;
  height: 4px;
  position: relative;
  transform: rotate(180deg);
}

.element .button-tall-fixed-7 {
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5.5px 0;
  display: inline-flex;
  position: relative;
}

.element .text-27 {
  color: var(--neutrals-30);
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-28 {
  color: var(--neutrals-30);
  font-family: var(--web-tiny-REG-font-family);
  font-size: var(--web-tiny-REG-font-size);
  font-style: var(--web-tiny-REG-font-style);
  font-weight: var(--web-tiny-REG-font-weight);
  letter-spacing: var(--web-tiny-REG-letter-spacing);
  line-height: var(--web-tiny-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-276 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 35px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-277 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-148 {
  color: #373432;
  font-family: var(--web-h6-DMI-font-family);
  font-size: var(--web-h6-DMI-font-size);
  font-style: var(--web-h6-DMI-font-style);
  font-weight: var(--web-h6-DMI-font-weight);
  letter-spacing: var(--web-h6-DMI-letter-spacing);
  line-height: var(--web-h6-DMI-line-height);
  align-self: stretch;
  height: 68px;
  margin-top: -1px;
  position: relative;
}

.element .text-29 {
  color: var(--neutrals-10);
  font-family: var(--web-tiny-MED-font-family);
  font-size: var(--web-tiny-MED-font-size);
  font-style: var(--web-tiny-MED-font-style);
  font-weight: var(--web-tiny-MED-font-weight);
  letter-spacing: var(--web-tiny-MED-letter-spacing);
  line-height: var(--web-tiny-MED-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .rectangle-38 {
  object-fit: cover;
  width: 411px;
  height: 320px;
  margin-top: -12458px;
  margin-left: -49.73px;
  position: relative;
}

.element .rectangle-39 {
  object-fit: cover;
  width: 411px;
  height: 320px;
  margin-top: -12458px;
  margin-left: -484.73px;
  position: relative;
}

.element .newsletter {
  background-color: #075a5e;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 385px;
  padding: 32px 40px;
  display: flex;
  position: absolute;
  top: 1011px;
  left: 975px;
}

.element .text-wrapper-149 {
  color: #f3eff5;
  font-family: var(--web-h6-DMI-font-family);
  font-size: var(--web-h6-DMI-font-size);
  font-style: var(--web-h6-DMI-font-style);
  font-weight: var(--web-h6-DMI-font-weight);
  letter-spacing: var(--web-h6-DMI-letter-spacing);
  line-height: var(--web-h6-DMI-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element .frame-278 {
  border: 1px solid;
  border-color: var(--neutral-30);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 14px 16px;
  display: flex;
  position: relative;
}

.element .text-wrapper-150 {
  color: var(--neutral-30);
  font-family: var(--web-paragraph-DMI-font-family);
  font-size: var(--web-paragraph-DMI-font-size);
  font-style: var(--web-paragraph-DMI-font-style);
  font-weight: var(--web-paragraph-DMI-font-weight);
  letter-spacing: var(--web-paragraph-DMI-letter-spacing);
  line-height: var(--web-paragraph-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-279 {
  background-color: var(--primary-02);
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 18px 16px;
  display: flex;
  position: relative;
}

.element .text-wrapper-151 {
  color: var(--neutral-30);
  font-family: var(--web-paragraph-BLD-font-family);
  font-size: var(--web-paragraph-BLD-font-size);
  font-style: var(--web-paragraph-BLD-font-style);
  font-weight: var(--web-paragraph-BLD-font-weight);
  letter-spacing: var(--web-paragraph-BLD-letter-spacing);
  line-height: var(--web-paragraph-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .line-50 {
  object-fit: cover;
  width: 1202px;
  height: 1px;
  position: absolute;
  top: -9603px;
  left: 1415px;
}

.element .video-unlocked {
  background-color: #fff;
  border: 8px solid #5151511f;
  border-radius: 12px;
  width: 836px;
  height: 1279px;
  position: absolute;
  top: 2506px;
  left: -352px;
  overflow: hidden;
}

.element .blog-2 {
  background: url("blog.afbb7d54.png") 50% / cover;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5.69px;
  width: 482px;
  height: 282px;
  display: flex;
  position: absolute;
  top: 82px;
  left: 46px;
}

.element .frame-280 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.14px;
  width: 482.16px;
  display: flex;
  position: relative;
}

.element .frame-281 {
  width: 180.28px;
  height: 4.55px;
  position: relative;
}

.element .controls {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 4.55px;
  display: flex;
  position: relative;
}

.element .frame-282 {
  flex: none;
  align-items: center;
  gap: 4.55px;
  display: inline-flex;
  position: relative;
}

.element .frame-283 {
  width: 15.94px;
  height: 15.94px;
  position: relative;
}

.element .play-arrow {
  width: 14px;
  height: 14px;
  position: absolute;
  top: -10766px;
  left: 1309px;
}

.element .volume-up {
  width: 14px;
  height: 14px;
  position: absolute;
  top: -10766px;
  left: 1291px;
}

.element .text-wrapper-152 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .frame-284 {
  background-image: url("fullscreen-4.8a9b2b2a.png");
  background-size: 100% 100%;
  width: 13.66px;
  height: 13.66px;
  position: relative;
}

.element .frame-285 {
  background-color: var(--accent-05);
  flex-direction: column;
  align-items: flex-start;
  gap: 13.66px;
  padding: 27.32px 45.54px 31.88px;
  display: inline-flex;
  position: absolute;
  top: 678px;
  left: 0;
}

.element .frame-286 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 4.55px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-153 {
  color: var(--primary-01);
  letter-spacing: -.13px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 15.9px;
  font-weight: 700;
  line-height: 19.1px;
  position: relative;
}

.element .frame-287 {
  flex: none;
  align-items: flex-start;
  gap: 13.66px;
  display: inline-flex;
  position: relative;
}

.element .rectangle-40 {
  object-fit: cover;
  width: 233.96px;
  height: 182.16px;
  margin-top: -11160.4px;
  margin-right: -1313.46px;
  position: relative;
}

.element .frame-288 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 5.69px;
  width: 233.96px;
  display: flex;
  position: relative;
}

.element .frame-289 {
  align-items: center;
  gap: 9.11px;
  width: 233.96px;
  height: 14.8px;
  display: flex;
  position: relative;
}

.element .frame-290 {
  flex: 1;
  align-items: center;
  gap: 2.28px;
  margin-top: -1.23px;
  margin-bottom: -1.23px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-8 {
  background-color: var(--secondary-03);
  border-radius: 2.28px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  padding: 1.71px 4.55px;
  display: inline-flex;
  position: relative;
}

.element .text-30 {
  color: var(--neutrals-0);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Artifika, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: 11.2px;
  position: relative;
}

.element .ellipse-22 {
  background-color: #d9d9d9;
  border-radius: 1.14px;
  width: 2.28px;
  height: 2.28px;
  position: relative;
  transform: rotate(180deg);
}

.element .button-tall-fixed-9 {
  border-radius: 2.28px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  padding: 3.13px 0;
  display: inline-flex;
  position: relative;
}

.element .text-31 {
  color: var(--neutrals-30);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .text-32 {
  color: var(--neutrals-30);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  line-height: 9.6px;
  position: relative;
}

.element .frame-291 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 19.92px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-292 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 17.08px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-154 {
  color: var(--neutral-70);
  letter-spacing: -.05px;
  align-self: stretch;
  height: 38.71px;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.4px;
  font-weight: 700;
  line-height: 15.9px;
  position: relative;
}

.element .text-33 {
  color: var(--neutrals-10);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 6.8px;
  font-weight: 700;
  line-height: 9.6px;
  position: relative;
}

.element .rectangle-41 {
  object-fit: cover;
  width: 183.13px;
  height: 182.16px;
  position: relative;
}

.element .rectangle-42 {
  object-fit: cover;
  width: 233.96px;
  height: 182.16px;
  position: relative;
}

.element .frame-293 {
  flex-direction: column;
  align-items: flex-start;
  gap: 31.88px;
  display: inline-flex;
  position: absolute;
  top: 468px;
  left: 47px;
}

.element .lorem-ipsum-is-9 {
  color: var(--neutral-50);
  letter-spacing: 0;
  width: 479.88px;
  margin-top: -.57px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: 12.8px;
  position: relative;
}

.element .line-51 {
  width: 183.7px;
  height: 1px;
  position: relative;
}

.element .frame-294 {
  flex: none;
  align-items: center;
  gap: 9.11px;
  width: 481.02px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-10 {
  all: unset;
  box-sizing: border-box;
  border-radius: 2.28px;
  flex: none;
  align-items: center;
  gap: 2.28px;
  padding: 3.13px 0;
  display: inline-flex;
  position: relative;
}

.element .maps-ugc-2 {
  width: 13.66px;
  height: 13.66px;
  margin-top: -10991.8px;
  margin-right: -1268.04px;
  position: relative;
}

.element .text-34 {
  color: #999492;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.24px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .ios-share-2 {
  width: 13.66px;
  height: 13.66px;
  margin-top: -10991.8px;
  margin-right: -1218px;
  position: relative;
}

.element .text-35 {
  color: var(--neutral-70);
  letter-spacing: -.05px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.4px;
  font-weight: 700;
  line-height: 15.9px;
  position: relative;
}

.element .frame-295 {
  flex: none;
  align-items: flex-start;
  gap: 6.83px;
  display: inline-flex;
  position: relative;
}

.element .frame-296 {
  background-color: #f3f2f2;
  border-radius: 13.66px;
  flex: none;
  align-items: flex-start;
  gap: 5.69px;
  padding: 3.42px 6.83px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-155 {
  color: var(--neutral-70);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 8px;
  font-weight: 700;
  line-height: 11.2px;
  position: relative;
}

.element .footers-7 {
  background-color: var(--neutral-80);
  width: 820px;
  height: 219px;
  position: absolute;
  top: 1043px;
  left: 0;
  overflow: hidden;
}

.element .text-wrapper-156 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: 12.8px;
  position: relative;
}

.element .text-wrapper-157 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  line-height: 9.6px;
  position: absolute;
  top: 198px;
  left: 348px;
}

.element .divider-8 {
  width: 476px;
  height: 1px;
  position: absolute;
  top: 188px;
  left: 344px;
}

.element .frame-297 {
  width: 73px;
  height: 19px;
  position: absolute;
  top: -11423px;
  left: 1428px;
}

.element .line-52 {
  width: 1px;
  height: 624px;
  position: absolute;
  top: 54px;
  left: 541px;
}

.element .frame-298 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13.66px;
  display: inline-flex;
  position: absolute;
  top: 386px;
  left: 46px;
}

.element .text-wrapper-158 {
  color: #000;
  letter-spacing: -.13px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 15.9px;
  font-weight: 700;
  line-height: 19.1px;
  position: relative;
}

.element .frame-299 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 481.02px;
  display: flex;
  position: relative;
}

.element .frame-300 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element .frame-301 {
  flex: none;
  align-items: flex-start;
  gap: 9.11px;
  display: inline-flex;
  position: relative;
}

.element .frame-302 {
  flex: none;
  align-items: center;
  gap: 1.14px;
  display: inline-flex;
  position: relative;
}

.element .ellipse-23 {
  border: .26px solid;
  border-color: var(--primary-03);
  border-radius: 10.35px;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.element .overlap-group-16 {
  background-image: url("divider.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 19px;
  height: 19px;
  position: absolute;
  top: -10839px;
  left: 1316px;
}

.element .ellipse-24 {
  object-fit: cover;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-159 {
  color: var(--neutral-70);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .stars-wrapper {
  border-radius: 2.28px;
  justify-content: center;
  align-items: center;
  gap: 2.28px;
  width: 12.52px;
  height: 12.52px;
  display: flex;
  position: relative;
}

.element .stars {
  width: 11.39px;
  height: 11.39px;
  margin-top: -10845.4px;
  margin-right: -1227.7px;
  position: relative;
}

.element .subscribe {
  background-color: #0f0f0f;
  border-radius: 10.25px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 6.83px;
  display: inline-flex;
  position: relative;
}

.element .div-10 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-160 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Roboto, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 20.5px;
  position: relative;
}

.element .frame-303 {
  flex: none;
  align-items: center;
  gap: 4.55px;
  padding: 0 23.91px;
  display: inline-flex;
  position: relative;
}

.element .text-36 {
  color: #999492;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .frame-304 {
  flex: none;
  align-items: center;
  gap: 6.83px;
  display: inline-flex;
  position: relative;
}

.element .share-8 {
  border: .57px solid #999492;
  border-radius: 18.22px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.28px;
  padding: 2.28px 6.83px 2.28px 3.42px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .recommend {
  width: 11.39px;
  height: 11.39px;
  margin-top: -10839.9px;
  margin-right: -6.83px;
  position: relative;
}

.element .text-wrapper-161 {
  color: var(--neutral-60);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .share-9 {
  border: .57px solid #999492;
  border-radius: 18.22px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 3.42px;
  padding: 2.28px 6.83px 2.28px 3.42px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .share-10 {
  width: 10.25px;
  height: 10.25px;
  position: relative;
}

.element .text-wrapper-162 {
  color: #1f1c1a;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .share-11 {
  border: .57px solid #999492;
  border-radius: 18.22px;
  flex: none;
  align-items: center;
  gap: 3.42px;
  padding: 2.28px 6.83px 2.28px 3.42px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .frame-305 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.39px;
  display: inline-flex;
  position: absolute;
  top: 82px;
  left: 554px;
}

.element .frame-306 {
  background-image: url("rectangle-63-3.2fd6e64a.svg");
  background-size: 100% 100%;
  width: 79.7px;
  height: 54.65px;
  position: relative;
}

.element .frame-307 {
  background-color: #070402a3;
  border-radius: 6.83px;
  justify-content: center;
  align-items: center;
  gap: 5.69px;
  padding: .57px 2.28px;
  display: inline-flex;
  position: relative;
  top: 41px;
  left: 60px;
}

.element .text-wrapper-163 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  line-height: 8px;
  position: relative;
}

.element .cell-name {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.83px;
  width: 140.61px;
  padding: 2.28px 0 2.28px 6.83px;
  display: flex;
  position: relative;
}

.element .text-wrapper-164 {
  color: var(--neutral-60);
  letter-spacing: 0;
  align-self: stretch;
  height: 23.34px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 8px;
  font-weight: 700;
  line-height: 11.2px;
  position: relative;
}

.element .text-wrapper-165 {
  color: var(--neutral-60);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 6.8px;
  font-weight: 500;
  line-height: 9.6px;
  position: relative;
}

.element .label-2 {
  border-radius: 2.28px;
  justify-content: center;
  align-items: center;
  gap: 2.28px;
  width: 8.54px;
  height: 8.54px;
  display: flex;
  position: relative;
}

.element .stars-2 {
  width: 8.25px;
  height: 8.25px;
  position: relative;
}

.element .frame-308 {
  background-image: url("rectangle-63-2.6c4e9386.svg");
  background-size: 100% 100%;
  width: 79.7px;
  height: 54.65px;
  position: relative;
}

.element .frame-309 {
  background-image: url("rectangle-63-1.0519d9d1.svg");
  background-size: 100% 100%;
  width: 79.7px;
  height: 54.65px;
  position: relative;
}

.element .frame-310 {
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 418px;
  left: 554px;
}

.element .frame-311 {
  background-image: url("rectangle-63.4731539e.svg");
  background-size: 100% 100%;
  width: 79.7px;
  height: 54.65px;
  position: relative;
}

.element .humble-logo-3 {
  width: 56.93px;
  height: 22.77px;
  margin-top: -10431.6px;
  margin-right: -1135.85px;
  position: relative;
}

.element .frame-312 {
  background-image: url("ellipse-1-22.ba20f497.png");
  background-position: 50%;
  background-size: cover;
  width: 22.77px;
  height: 22.77px;
  position: relative;
}

.element .frame-313 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.39px;
  width: 220px;
  display: flex;
  position: absolute;
  top: 287px;
  left: 554px;
}

.element .text-wrapper-166 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .frame-314 {
  width: 22.2px;
  height: 22.2px;
  position: relative;
}

.element .ellipse-25 {
  object-fit: cover;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.element .frame-315 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.14px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-167 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.element .stars-3 {
  width: 11.39px;
  height: 11.38px;
  position: relative;
}

.element .text-wrapper-168 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8px;
  font-weight: 500;
  line-height: 11.2px;
  position: relative;
}

.element .product-categories {
  background-color: #fff;
  border: 8px solid #5151511f;
  border-radius: 12px;
  width: 1086px;
  height: 3253px;
  position: absolute;
  top: 942px;
  left: 1435px;
  overflow: hidden;
}

.element .overlap-36 {
  width: 1070px;
  height: 221px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .hero-image {
  background-color: var(--accent-02);
  width: 1070px;
  height: 221px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .header-17 {
  gap: 7.43px !important;
  padding: 5.95px 8.92px !important;
}

.element .header-18 {
  margin-top: -.74px !important;
  margin-right: -8.81px !important;
}

.element .header-19 {
  margin-top: -.74px !important;
}

.element .header-20 {
  margin-right: -225.05px !important;
  padding: 2.23px 0 2.97px !important;
}

.element .header-21 {
  padding: 0 348.6px 0 0 !important;
}

.element .header-22 {
  height: unset !important;
  gap: 7.43px !important;
  width: 1070px !important;
  padding: 11.89px 59.46px !important;
}

.element .header-23 {
  gap: 23.79px !important;
}

.element .header-24 {
  width: 74.33px !important;
  height: 29.73px !important;
}

.element .header-25 {
  width: 208.12px !important;
}

.element .header-26 {
  width: 211.84px !important;
}

.element .header-27 {
  border-top-width: .74px !important;
  border-bottom-width: .74px !important;
  border-left-width: .74px !important;
  border-radius: 29.73px 0 0 29.73px !important;
  padding: 5.2px 2.97px 5.2px 12.64px !important;
  box-shadow: inset 0 .74px 1.49px #eee !important;
}

.element .header-28 {
  margin-top: -.74px !important;
  font-size: 11.9px !important;
}

.element .header-29 {
  width: 35.68px !important;
  margin-top: -8869.89px !important;
  margin-left: -919.41px !important;
}

.element .header-30 {
  gap: 17.84px !important;
}

.element .header-31 {
  border-radius: 2.97px !important;
}

.element .header-32 {
  border-radius: 2.97px !important;
  gap: 3.72px !important;
  padding: 5.95px 8.92px !important;
}

.element .frame-316 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 100px;
  left: 59px;
}

.element .text-wrapper-169 {
  color: var(--neutral-90);
  letter-spacing: -.46px;
  width: 488.34px;
  height: 60.21px;
  margin-top: -.74px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 32.7px;
  font-weight: 700;
  line-height: 39.2px;
  position: relative;
}

.element .text-wrapper-170 {
  color: var(--neutral-90);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Le Monde Livre Cla Std-Regular, Helvetica;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.element .frame-317 {
  align-items: center;
  gap: 8.92px;
  width: 619.16px;
  display: flex;
  position: absolute;
  top: 250px;
  left: 59px;
}

.element .frame-318 {
  background-color: var(--neutral-90);
  border-radius: 23.79px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.43px;
  padding: 8.92px 23.79px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-171 {
  color: #fff;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.element .frame-319 {
  border-radius: 23.79px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.43px;
  padding: 8.92px 23.79px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-172 {
  color: #686462;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.49px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.element .overlap-37 {
  width: 1070px;
  height: 878px;
  position: absolute;
  top: 857px;
  left: 0;
}

.element .overlap-38 {
  width: 1070px;
  height: 798px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-320 {
  background-color: var(--neutral-20);
  flex-direction: column;
  align-items: flex-start;
  gap: 23.79px;
  width: 1070px;
  padding: 29.73px 59.46px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-321 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 5.95px;
  width: 396.91px;
  display: flex;
  position: relative;
}

.element .text-wrapper-173 {
  color: #826aed;
  letter-spacing: .42px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 14.6px;
  position: relative;
}

.element .frame-322 {
  flex: none;
  align-items: flex-start;
  gap: 2.97px;
  display: inline-flex;
  position: relative;
}

.element .your-next-favourite-wrapper {
  flex: none;
  align-items: center;
  gap: 2.97px;
  display: inline-flex;
  position: relative;
}

.element .your-next-favourite {
  color: #0000;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 20.8px;
  font-weight: 700;
  line-height: 25px;
  position: relative;
}

.element .text-wrapper-174 {
  color: #373432;
  letter-spacing: -.17px;
}

.element .text-wrapper-175 {
  color: #826aed;
  letter-spacing: -.1px;
  text-decoration: underline;
}

.element .text-wrapper-176 {
  color: #373432;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 16.6px;
  position: relative;
}

.element .frame-323 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 17.84px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-324 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 17.84px;
  display: flex;
  position: relative;
}

.element .rectangle-43 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 178.39px;
  position: relative;
}

.element .frame-325 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 7.43px;
  width: 305.49px;
  margin-right: -.25px;
  display: flex;
  position: relative;
}

.element .frame-326 {
  flex: 1;
  align-items: center;
  gap: 11.89px;
  width: 305.49px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-11 {
  background-color: var(--secondary-04);
  border-radius: 2.97px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.43px;
  margin-top: -.07px;
  margin-bottom: -.07px;
  padding: 2.23px 5.95px;
  display: inline-flex;
  position: relative;
}

.element .text-37 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 14.6px;
  position: relative;
}

.element .text-38 {
  color: #cac4c2;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 8.9px;
  font-weight: 700;
  line-height: 12.5px;
  position: relative;
}

.element .frame-327 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 26.01px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-328 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 22.3px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-177 {
  color: #373432;
  letter-spacing: 0;
  align-self: stretch;
  height: 50.54px;
  margin-top: -.74px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  line-height: 16.6px;
  position: relative;
}

.element .play-button {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 72px;
  left: 135px;
}

.element .play-button-2 {
  width: 34.19px;
  height: 34.19px;
  position: relative;
}

.element .frame-329 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.43px;
  width: 305.49px;
  height: 77.3px;
  margin-right: -.25px;
  display: flex;
  position: relative;
}

.element .text-wrapper-178 {
  color: #373432;
  letter-spacing: -.06px;
  align-self: stretch;
  height: 50.54px;
  margin-top: -.74px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.element .rectangle-44 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 178.39px;
  margin-top: -9837.42px;
  margin-left: -1133.62px;
  position: relative;
}

.element .play-button-3 {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -9765px;
  left: -998px;
}

.element .blog-text {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 462px;
  left: 59px;
}

.element .container-product {
  flex: 1;
  align-items: center;
  gap: 11.89px;
  display: flex;
  position: relative;
}

.element .image-10 {
  background-color: #fff;
  border-radius: 5.95px;
  width: 77.3px;
  height: 77.3px;
  position: relative;
  overflow: hidden;
}

.element .image-11 {
  object-fit: cover;
  width: 52px;
  height: 51px;
  position: absolute;
  top: 13px;
  left: 13px;
}

.element .container-product-2 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5.95px;
  padding: 0 23.79px 0 0;
  display: flex;
  position: relative;
}

.element .div-11 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 2.97px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-179 {
  color: #373432;
  letter-spacing: -.06px;
  width: 228.19px;
  margin-top: -.74px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.element .text-wrapper-180 {
  color: #373432;
  letter-spacing: 0;
  width: 228.19px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 16.6px;
  position: relative;
}

.element .tags {
  flex: none;
  align-items: flex-start;
  gap: 5.95px;
  display: inline-flex;
  position: relative;
}

.element .frame-330 {
  background-color: var(--accent-01);
  border-radius: 8.92px;
  flex: none;
  align-items: flex-start;
  gap: 7.43px;
  padding: 1.49px 5.95px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .text-39 {
  color: #999492;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 10.4px;
  font-weight: 500;
  line-height: 14.6px;
  position: relative;
}

.element .frame-331 {
  border-radius: 8.92px;
  flex: none;
  align-items: flex-start;
  gap: 7.43px;
  padding: 1.49px 2.97px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .upvote-instance {
  border: .74px solid !important;
  border-radius: 2.97px !important;
  gap: 1.49px !important;
  width: 61.46px !important;
  height: 61.46px !important;
  margin-right: -1px !important;
  padding: 11.89px 10.41px !important;
}

.element .upvote-2 {
  gap: 1.49px !important;
  margin-left: -1.17px !important;
}

.element .upvote-3 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -10209.3px !important;
  margin-left: -1031.43px !important;
}

.element .frame-332 {
  flex-direction: column;
  align-items: flex-start;
  gap: 29.73px;
  width: 272px;
  display: flex;
  position: absolute;
  top: 462px;
  left: 715px;
}

.element .div-12 {
  flex: none;
  align-items: center;
  gap: 5.95px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-181 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 14.6px;
  position: relative;
}

.element .arrow-forward {
  width: 14.87px;
  height: 14.87px;
  margin-top: -10176.5px;
  margin-left: -1055.09px;
  position: relative;
}

.element .frame-333 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14.87px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .blog-text-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 11.89px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-334 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 2.97px;
  display: flex;
  position: relative;
}

.element .text-wrapper-182 {
  color: var(--neutral-60);
  letter-spacing: -.06px;
  align-self: stretch;
  height: 50.54px;
  margin-top: -.74px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.element .label-container-2 {
  flex: none;
  align-items: center;
  gap: 2.97px;
  width: 111.49px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-12 {
  background-color: var(--neutral-30);
  border: .74px solid #cac4c2;
  border-radius: 20.81px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.43px;
  margin-left: -.74px;
  padding: 1.49px 5.95px;
  display: inline-flex;
  position: relative;
}

.element .text-40 {
  color: #686462;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 14.6px;
  position: relative;
}

.element .ellipse-26 {
  background-color: #cac4c2;
  border-radius: 1.49px;
  width: 2.97px;
  height: 2.97px;
  position: relative;
  transform: rotate(180deg);
}

.element .button-tall-fixed-13 {
  border-radius: 2.97px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.43px;
  padding: 4.09px 0;
  display: inline-flex;
  position: relative;
}

.element .text-41 {
  color: #373432;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 14.6px;
  position: relative;
}

.element .frame-335 {
  background-color: #fff;
  border-radius: 5.95px;
  width: 77.3px;
  height: 77.3px;
  position: relative;
}

.element .line-53 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -10129.2px;
  margin-left: -1143.04px;
  position: relative;
}

.element .label-container-3 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.97px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .line-54 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -10022.1px;
  margin-left: -1143.04px;
  position: relative;
}

.element .button-tall-fixed-14 {
  background-color: var(--neutral-30);
  border-radius: 15.61px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.43px;
  padding: 1.49px 5.95px;
  display: inline-flex;
  position: relative;
}

.element .text-42 {
  color: #686462;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 14.6px;
  position: relative;
}

.element .blog-text-3 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 566px;
  left: 59px;
}

.element .upvote-4 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -10313.4px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-4 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 670px;
  left: 59px;
}

.element .upvote-5 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -10417.4px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-5 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 774px;
  left: 59px;
}

.element .image-12 {
  object-fit: cover;
  width: 70px;
  height: 68px;
  position: absolute;
  top: 5px;
  left: 4px;
}

.element .upvote-6 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -10521.5px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-6 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 313px;
  left: 59px;
}

.element .upvote-7 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -9203.63px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-7 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 2267px;
  left: 59px;
}

.element .upvote-8 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -11157.7px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-8 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 417px;
  left: 59px;
}

.element .upvote-9 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -9307.69px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-9 {
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 2371px;
  left: 59px;
}

.element .upvote-10 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -11261.8px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-10 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 521px;
  left: 59px;
}

.element .upvote-11 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -9411.75px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-11 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 2609px;
  left: 59px;
}

.element .upvote-12 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -11499.6px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-12 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 625px;
  left: 59px;
}

.element .upvote-13 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -9515.81px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-13 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 2713px;
  left: 59px;
}

.element .upvote-14 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -11603.7px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-14 {
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 729px;
  left: 59px;
}

.element .upvote-15 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -9619.87px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-15 {
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 1735px;
  left: 59px;
}

.element .upvote-16 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -10625.5px !important;
  margin-left: -1031.43px !important;
}

.element .blog-text-16 {
  justify-content: center;
  align-items: center;
  gap: 5.95px;
  width: 606px;
  padding: 11.89px 0 14.87px;
  display: flex;
  position: absolute;
  top: 2817px;
  left: 59px;
}

.element .upvote-17 {
  width: 17.84px !important;
  height: 17.84px !important;
  margin-top: -11707.8px !important;
  margin-left: -1031.43px !important;
}

.element .frame-336 {
  flex-direction: column;
  align-items: flex-start;
  gap: 29.73px;
  width: 286px;
  display: flex;
  position: absolute;
  top: 261px;
  left: 725px;
}

.element .arrow-back {
  width: 14.87px;
  height: 14.87px;
  margin-top: -9118.83px;
  margin-left: -1049.89px;
  position: relative;
}

.element .line-55 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -9071.46px;
  margin-left: -1152.7px;
  position: relative;
}

.element .line-56 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -8964.42px;
  margin-left: -1152.7px;
  position: relative;
}

.element .line-57 {
  width: 595px;
  height: 1px;
  position: absolute;
  top: -8619px;
  left: 257px;
}

.element .line-58 {
  width: 523px;
  height: 1px;
  position: absolute;
  top: -7542px;
  left: 257px;
}

.element .line-59 {
  width: 418px;
  height: 1px;
  position: absolute;
  top: 1869px;
  left: 59px;
}

.element .line-60 {
  width: 418px;
  height: 1px;
  position: absolute;
  top: 2237px;
  left: 59px;
}

.element .newsletter-2 {
  background-color: #075a5e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17.84px;
  width: 286px;
  padding: 23.79px 29.73px;
  display: flex;
  position: absolute;
  top: 625px;
  left: 725px;
}

.element .text-wrapper-183 {
  color: #f3eff5;
  letter-spacing: -.06px;
  align-self: stretch;
  margin-top: -.74px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.element .frame-337 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 11.89px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-338 {
  border: .74px solid;
  border-color: var(--neutral-30);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 7.43px;
  width: 100%;
  padding: 10.41px 11.89px;
  display: flex;
  position: relative;
}

.element .text-wrapper-184 {
  color: var(--neutral-30);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  line-height: 16.6px;
  position: relative;
}

.element .frame-339 {
  background-color: var(--primary-02);
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.43px;
  width: 100%;
  padding: 13.38px 11.89px;
  display: flex;
  position: relative;
}

.element .text-wrapper-185 {
  color: var(--neutral-30);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  line-height: 16.6px;
  position: relative;
}

.element .frame-340 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17.84px;
  display: inline-flex;
  position: absolute;
  top: 1898px;
  left: 59px;
}

.element .arrow-back-2 {
  width: 14.87px;
  height: 14.87px;
  position: relative;
}

.element .frame-341 {
  flex: none;
  align-items: center;
  gap: 17.84px;
  width: 951.4px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .frame-wrapper-4 {
  background-color: #fff;
  border: .37px solid #cac4c2;
  border-radius: 7.43px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 17.84px;
  width: 240.08px;
  padding: 23.79px 17.84px;
  display: flex;
  position: relative;
  box-shadow: 4.46px 2.97px 11.89px #d4d4d41f;
}

.element .frame-342 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 17.84px;
  width: 100%;
  height: 228.93px;
  display: flex;
  position: relative;
}

.element .frame-343 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5.95px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-344 {
  background-image: url("rectangle-6-3.ccfd0416.png");
  background-position: 50%;
  background-size: cover;
  width: 29.73px;
  height: 29.73px;
  position: relative;
}

.element .text-wrapper-186 {
  color: #373432;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.element .frame-345 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8.92px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-346 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.43px;
  width: 100%;
  padding: 2.97px 0;
  display: flex;
  position: relative;
}

.element .text-wrapper-187 {
  color: #999492;
  letter-spacing: 0;
  flex: 1;
  margin-top: -.74px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 10.4px;
  font-weight: 500;
  line-height: 14.6px;
  position: relative;
}

.element .button-tall-fixed-15 {
  background-color: #826aed;
  border-radius: 2.97px;
  flex: none;
  align-items: flex-start;
  gap: 7.43px;
  padding: 4.46px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .text-43 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 8.9px;
  font-weight: 700;
  line-height: 10.4px;
  position: relative;
}

.element .line-61 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.26px;
  position: relative;
}

.element .frame-347 {
  background-color: var(--accent-01);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.43px;
  width: 100%;
  margin-bottom: -2.94px;
  padding: 14.87px 17.84px;
  display: flex;
  position: relative;
}

.element .frame-348 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8.92px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-188 {
  color: var(--primary-02);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -.74px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 14.6px;
  position: relative;
}

.element .element-off-the-year {
  color: #1f1c1a;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  line-height: 16.6px;
  position: relative;
}

.element .frame-349 {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-350 {
  background-image: url("rectangle-6-2.a1a34f34.png");
  background-position: 50%;
  background-size: cover;
  width: 29.73px;
  height: 29.73px;
  position: relative;
}

.element .frame-351 {
  background-color: var(--accent-01);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.43px;
  width: 100%;
  padding: 14.87px 17.84px;
  display: flex;
  position: relative;
}

.element .frame-352 {
  background-image: url("divider.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 29.73px;
  height: 29.73px;
  margin-top: -10813px;
  margin-left: -1021.14px;
  position: relative;
}

.element .line-62 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -21481.2px;
  margin-left: -1021.14px;
  position: relative;
}

.element .frame-353 {
  background-image: url("divider.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 29.73px;
  height: 29.73px;
  margin-top: -10813px;
  margin-left: -1279.06px;
  position: relative;
}

.element .line-63 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -21481.2px;
  margin-left: -1279.06px;
  position: relative;
}

.element .blog-text-17 {
  border-color: #cac4c2;
  border-top-style: solid;
  border-top-width: .74px;
  border-bottom-style: solid;
  border-bottom-width: .74px;
  align-items: center;
  gap: 11.89px;
  width: 606px;
  height: 133.79px;
  padding: 17.84px 0;
  display: flex;
  position: absolute;
  top: 2475px;
  left: 59px;
}

.element .frame-354 {
  background-color: #fff;
  border-radius: 5.95px;
  width: 89.19px;
  height: 89.19px;
  position: relative;
}

.element .button-tall-fixed-16 {
  background-color: var(--neutral-30);
  border: .74px solid #cac4c2;
  border-radius: 20.81px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.43px;
  padding: 1.49px 5.95px;
  display: inline-flex;
  position: relative;
}

.element .footers-8 {
  background-color: #1f1c1a;
  width: 1070px;
  height: 286px;
  position: absolute;
  top: 2951px;
  left: 0;
  overflow: hidden;
}

.element .address-2 {
  width: 157px;
  height: 92px;
  position: absolute;
  top: 99px;
  left: 89px;
}

.element .element-upper-rupert-2 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.9px;
  font-weight: 400;
  line-height: 17.8px;
  position: absolute;
  top: -1px;
  left: 0;
}

.element .text-wrapper-189 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.9px;
  font-weight: 400;
  line-height: 17.8px;
  position: absolute;
  top: 22px;
  left: 0;
}

.element .text-wrapper-190 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Plus Jakarta Sans, Helvetica;
  font-size: 11.9px;
  font-weight: 400;
  line-height: 17.8px;
  position: absolute;
  top: 45px;
  left: 1px;
}

.element .company-links-2 {
  align-items: flex-start;
  gap: 11.89px;
  display: inline-flex;
  position: absolute;
  top: 48px;
  left: 838px;
}

.element .text-wrapper-191 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 16.6px;
  position: relative;
}

.element .text-wrapper-192 {
  color: var(--primary-light);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.9px;
  font-weight: 500;
  line-height: 12.5px;
  position: absolute;
  top: 258px;
  left: 454px;
}

.element .divider-9 {
  width: 1070px;
  height: 1px;
  position: absolute;
  top: -11562px;
  left: -428px;
}

.element .frame-355 {
  width: 186px;
  height: 30px;
  position: absolute;
  top: -11728px;
  left: 396px;
}

.element .humble-logo-4 {
  width: 104px;
  height: 23px;
  position: absolute;
  top: -11751px;
  left: -339px;
}

.element .frame-356 {
  width: 1920px;
  height: 2350px;
  position: absolute;
  top: 13418px;
  left: 0;
}

.element .overlap-39 {
  width: 2886px;
  height: 2350px;
  position: relative;
  left: -288px;
}

.element .rectangle-45 {
  background-color: #fffcf9;
  width: 1922px;
  height: 2350px;
  position: absolute;
  top: 0;
  left: 287px;
}

.element .text-wrapper-193 {
  color: #000;
  letter-spacing: -.24px;
  height: 59px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 95px;
  left: 408px;
}

.element .create-article {
  background-color: #fff;
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 891px;
  height: 638px;
  position: absolute;
  top: 209px;
  left: 409px;
  overflow: hidden;
}

.element .frame-357 {
  flex-direction: column;
  align-items: flex-start;
  gap: 17.01px;
  display: inline-flex;
  position: absolute;
  top: 121px;
  left: 147px;
}

.element .text-wrapper-194 {
  color: #373432;
  letter-spacing: -.82px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Le Monde Livre Std-Demi, Helvetica;
  font-size: 29.2px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.element .frame-358 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.72px;
  width: 580.09px;
  display: flex;
  position: relative;
}

.element .text-wrapper-195 {
  color: #373432;
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.element .frame-359 {
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex: none;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.element .text-wrapper-196 {
  color: #373432;
  letter-spacing: -.05px;
  white-space: nowrap;
  height: 17px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: 17px;
  position: absolute;
  top: 247px;
  left: 147px;
}

.element .frame-360 {
  background-color: var(--neutral-20);
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  width: 577px;
  height: 319px;
  position: absolute;
  top: 270px;
  left: 147px;
}

.element .frame-361 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14.58px;
  display: inline-flex;
  position: absolute;
  top: 15px;
  left: 15px;
}

.element .lorem-ipsum-dolor {
  color: #373432;
  letter-spacing: 0;
  width: 547.89px;
  margin-top: -.61px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .frame-362 {
  background-color: #44bba466;
  flex: none;
  align-items: center;
  gap: 6.07px;
  padding: 3.04px 0;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-197 {
  color: #4c4c4c;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .lorem-ipsum-dolor-2 {
  color: #0000;
  letter-spacing: 0;
  width: 547.89px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .tooltip {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 63px;
  left: 96px;
}

.element .frame-wrapper-5 {
  background-color: var(--neutral-90);
  border-radius: 2.43px;
  flex: none;
  align-items: flex-start;
  padding: 2.43px 3.64px;
  display: inline-flex;
  position: relative;
}

.element .frame-363 {
  flex: none;
  align-items: flex-start;
  gap: 4.86px;
  display: inline-flex;
  position: relative;
}

.element .img-12 {
  width: 14.58px;
  height: 14.58px;
  position: relative;
}

.element .line-64 {
  align-self: stretch;
  width: 1px;
  position: relative;
}

.element .arrow {
  width: 4.86px;
  height: 2.18px;
  position: relative;
}

.element .toolbar-instance {
  gap: 14.58px !important;
  position: absolute !important;
  top: 262px !important;
  left: -15px !important;
  box-shadow: 0 2.43px 9.72px #9393931f !important;
}

.element .toolbar-2 {
  border-radius: 24.3px !important;
  gap: 6.07px !important;
  padding: 7.29px !important;
}

.element .toolbar-3 {
  width: 14.58px !important;
  height: 14.58px !important;
}

.element .toolbar-4 {
  gap: 7.29px !important;
}

.element .toolbar-5 {
  border: .61px solid !important;
  border-radius: 24.3px !important;
  gap: 6.07px !important;
  padding: 7.29px !important;
}

.element .header-33 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .61px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6.07px;
  width: 875px;
  padding: 9.72px 48.59px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .CTA-s-wrapper {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 19.44px;
  display: inline-flex;
  position: relative;
}

.element .CTA-s-4 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 14.58px;
  display: inline-flex;
  position: relative;
}

.element .frame-364 {
  background-color: var(--neutral-90);
  border-radius: 2.43px;
  flex: none;
  align-items: center;
  gap: 2.43px;
  padding: 3.64px 7.29px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-198 {
  color: var(--accent-01);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  line-height: 11.9px;
  position: relative;
}

.element .frame-365 {
  background-image: url("ellipse-1-21.e689cf32.png");
  background-position: 50%;
  background-size: cover;
  width: 24.3px;
  height: 24.3px;
  position: relative;
}

.element .frame-366 {
  align-items: center;
  gap: 7.29px;
  width: 577px;
  display: flex;
  position: absolute;
  top: 63px;
  left: 149px;
}

.element .check-wrapper {
  background-color: var(--primary-02);
  border-radius: 19.44px;
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  padding: 7.29px;
  display: inline-flex;
  position: relative;
}

.element .check {
  width: 19.44px;
  height: 19.44px;
  position: relative;
}

.element .frame-367 {
  background-color: var(--neutral-30);
  border-radius: 19.44px;
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  padding: 7.29px;
  display: inline-flex;
  position: relative;
}

.element .create-article-2 {
  background-color: #fff;
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 891px;
  height: 638px;
  position: absolute;
  top: 209px;
  left: 1348px;
  overflow: hidden;
}

.element .frame-368 {
  flex-direction: column;
  align-items: flex-start;
  gap: 19.44px;
  width: 577px;
  display: flex;
  position: absolute;
  top: 121px;
  left: 149px;
}

.element .frame-369 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14.58px;
  display: inline-flex;
  position: relative;
}

.element .frame-370 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.72px;
  width: 577.05px;
  display: flex;
  position: relative;
}

.element .nav-labels-wrapper {
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.07px;
  width: 100%;
  padding: 7.29px 9.72px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .nav-labels-instance {
  background-color: var(--tertiary-01) !important;
  border-radius: 19.44px !important;
  flex: none !important;
  gap: 6.07px !important;
  padding: 3.64px 4.86px 3.64px 7.29px !important;
}

.element .nav-labels-2 {
  gap: 6.07px !important;
}

.element .nav-labels-3 {
  color: #fff !important;
  margin-top: -.61px !important;
}

.element .nav-labels-4 {
  border: .61px solid !important;
  border-radius: 19.44px !important;
  flex: none !important;
  gap: 6.07px !important;
  padding: 3.64px 7.29px !important;
}

.element .nav-labels-5 {
  margin-top: -.61px !important;
}

.element .frame-371 {
  background-image: url("ellipse-1-20.ccfe9375.png");
  background-position: 50%;
  background-size: cover;
  width: 24.3px;
  height: 24.3px;
  position: relative;
}

.element .create-podcast {
  background-color: #fff;
  border: 8.03px solid #d9d9d9;
  border-radius: 12px;
  width: 891px;
  height: 660px;
  position: absolute;
  top: 895px;
  left: 117px;
}

.element .header-34 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .61px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6.08px;
  width: 875px;
  padding: 9.72px 48.6px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .quick-links-2 {
  flex: none;
  margin-top: -12700.7px;
  margin-right: -412.18px;
  position: relative;
}

.element .frame-372 {
  flex: none;
  align-items: center;
  gap: 19.44px;
  margin-left: -436.49px;
  display: inline-flex;
  position: relative;
}

.element .CTA-s-5 {
  flex: none;
  align-items: center;
  gap: 14.58px;
  display: inline-flex;
  position: relative;
}

.element .frame-373 {
  background-image: url("ellipse-1-19.2b6e399e.png");
  background-position: 50%;
  background-size: cover;
  width: 24.3px;
  height: 24.3px;
  position: relative;
}

.element .frame-374 {
  flex-direction: column;
  align-items: flex-start;
  gap: 19.44px;
  width: 577px;
  display: flex;
  position: absolute;
  top: 131px;
  left: 149px;
}

.element .frame-375 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14.58px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-376 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 14.58px;
  display: flex;
  position: relative;
}

.element .text-wrapper-199 {
  color: #686462;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Le Monde Livre Cla Std-Regular, Helvetica;
  font-size: 12.2px;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.element .button-base-18 {
  all: unset;
  box-sizing: border-box;
  background-color: #075a5e;
  border: .61px solid;
  border-radius: 2.43px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 2.43px;
  padding: 7.29px 19.44px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .61px 1.22px #1018280d;
}

.element .text-44 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.32px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.6px;
  position: relative;
}

.element .frame-377 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.72px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-200 {
  color: #373432;
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 12.2px;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.element .frame-378 {
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 6.08px;
  width: 100%;
  padding: 7.29px 9.72px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .button-tall-fixed-17 {
  background-color: var(--neutral-30);
  border-radius: 7.29px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.08px;
  padding: 1.22px 4.86px;
  display: inline-flex;
  position: relative;
}

.element .text-45 {
  color: #686462;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 11.9px;
  position: relative;
}

.element .frame-379 {
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 6.08px;
  width: 100%;
  padding: 7.29px 9.72px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-201 {
  color: #686462;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.61px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 11.9px;
  position: relative;
}

.element .frame-380 {
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 7.29px 9.72px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-202 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 11.9px;
  position: relative;
}

.element .frame-381 {
  background-color: var(--primary-02);
  border-radius: 19.44px;
  flex: none;
  align-items: flex-start;
  gap: 6.08px;
  padding: 7.29px;
  display: inline-flex;
  position: relative;
}

.element .check-2 {
  width: 12.89px;
  height: 19.44px;
  position: relative;
}

.element .frame-382 {
  background-color: var(--neutral-30);
  border-radius: 19.44px;
  flex: none;
  align-items: flex-start;
  gap: 6.08px;
  padding: 7.29px;
  display: inline-flex;
  position: relative;
}

.element .create-podcast-2 {
  background-color: #fff;
  border: 8.03px solid #d9d9d9;
  border-radius: 12px;
  width: 891px;
  height: 660px;
  position: absolute;
  top: 895px;
  left: 1056px;
}

.element .frame-383 {
  flex: none;
  align-items: center;
  gap: 19.44px;
  display: inline-flex;
  position: relative;
}

.element .frame-384 {
  background-image: url("ellipse-1-18.be32833e.png");
  background-position: 50%;
  background-size: cover;
  width: 24.3px;
  height: 24.3px;
  position: relative;
}

.element .frame-385 {
  justify-content: space-between;
  align-items: center;
  width: 577.17px;
  display: flex;
  position: absolute;
  top: 117px;
  left: 149px;
}

.element .text-wrapper-203 {
  color: #373432;
  letter-spacing: -.37px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 26.7px;
  font-weight: 700;
  line-height: 32.1px;
  position: relative;
}

.element .button-base-19 {
  background-color: #075a5e;
  border: .61px solid;
  border-radius: 2.43px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 2.43px;
  padding: 7.29px 19.44px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .61px 1.22px #1018280d;
}

.element .text-46 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.6px;
  position: relative;
}

.element .frame-386 {
  border: .61px dashed #cac4c2;
  border-radius: 7.29px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 577px;
  height: 243px;
  padding: 58.32px 0 0;
  display: flex;
  position: absolute;
  top: 180px;
  left: 149px;
  overflow: hidden;
}

.element .frame-387 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 19.44px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .publish-wrapper {
  background-color: #075a5e;
  border-radius: 37.64px;
  width: 75.27px;
  height: 75.27px;
  position: relative;
  box-shadow: 0 7.29px 19.44px #00000014;
}

.element .publish {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.element .frame-388 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 2.43px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-204 {
  color: #373432;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .text-wrapper-205 {
  color: var(--primary-02);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.6px;
  position: relative;
}

.element .line-65 {
  width: 577.17px;
  height: 4.86px;
  position: relative;
}

.element .frame-389 {
  justify-content: space-between;
  align-items: center;
  width: 578px;
  display: flex;
  position: absolute;
  top: 433px;
  left: 148px;
}

.element .frame-390 {
  flex: none;
  align-items: center;
  gap: 7.29px;
  display: inline-flex;
  position: relative;
}

.element .play-button-4 {
  width: 21.87px;
  height: 21.87px;
  position: relative;
}

.element .text-wrapper-206 {
  color: #999492;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  line-height: 11.9px;
  position: relative;
}

.element .create-podcast-3 {
  background-color: #fff;
  border: 8.03px solid #d9d9d9;
  border-radius: 12px;
  width: 890px;
  height: 660px;
  position: absolute;
  top: 895px;
  left: 1996px;
}

.element .header-35 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .61px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6.07px;
  width: 874px;
  padding: 9.72px 48.58px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-391 {
  flex: none;
  align-items: center;
  gap: 19.43px;
  display: inline-flex;
  position: relative;
}

.element .CTA-s-6 {
  flex: none;
  align-items: center;
  gap: 14.57px;
  display: inline-flex;
  position: relative;
}

.element .frame-392 {
  background-image: url("divider.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 24.29px;
  height: 24.29px;
  margin-top: -12700.7px;
  margin-left: -1502.11px;
  position: relative;
}

.element .frame-393 {
  justify-content: space-between;
  align-items: center;
  width: 576.87px;
  display: flex;
  position: absolute;
  top: 117px;
  left: 149px;
}

.element .button-base-20 {
  all: unset;
  box-sizing: border-box;
  background-color: #075a5e;
  border: .61px solid;
  border-radius: 2.43px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 2.43px;
  padding: 7.29px 19.43px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .61px 1.21px #1018280d;
}

.element .frame-394 {
  background-color: var(--primary-02);
  border-radius: 19.43px;
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  padding: 7.29px;
  display: inline-flex;
  position: relative;
}

.element .check-3 {
  width: 19.43px;
  height: 19.43px;
  position: relative;
}

.element .frame-395 {
  background-color: var(--neutral-30);
  border-radius: 19.43px;
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  padding: 7.29px;
  display: inline-flex;
  position: relative;
}

.element .check-4 {
  width: 19.43px;
  height: 19.43px;
  margin-top: -12754.2px;
  margin-left: -1120.78px;
  position: relative;
}

.element .line-66 {
  flex: 1;
  height: 1px;
  margin-top: -12737.2px;
  margin-left: -536.62px;
  position: relative;
}

.element .check-5 {
  width: 19.43px;
  height: 19.43px;
  margin-top: -12754.2px;
  margin-left: -1392.21px;
  position: relative;
}

.element .frame-396 {
  border: .61px dashed #cac4c2;
  border-radius: 7.29px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 579px;
  height: 245px;
  padding: 14.57px 0 0;
  display: flex;
  position: absolute;
  top: 179px;
  left: 148px;
  overflow: hidden;
}

.element .frame-397 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 19.43px;
  display: flex;
  position: relative;
}

.element .upload {
  border-bottom-style: solid;
  border-bottom-width: .61px;
  border-color: var(--x-20);
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.07px 0;
  display: flex;
  position: relative;
}

.element .drag-indicator {
  width: 14.57px;
  height: 14.57px;
  position: relative;
}

.element .frame-398 {
  background-color: #826aed;
  border-radius: 11.54px;
  width: 23.07px;
  height: 23.07px;
  position: relative;
}

.element .ellipse-27 {
  background-color: #fff;
  border-radius: 1.21px;
  width: 2px;
  height: 2px;
  position: absolute;
  top: 10px;
  left: 5px;
}

.element .ellipse-28 {
  background-color: #fff;
  border-radius: 1.21px;
  width: 2px;
  height: 2px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.element .ellipse-29 {
  background-color: #fff;
  border-radius: 1.21px;
  width: 2px;
  height: 2px;
  position: absolute;
  top: 10px;
  left: 15px;
}

.element .frame-399 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.21px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-207 {
  color: #1f1c1a;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .text-wrapper-208 {
  color: #999492;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 7.3px;
  font-weight: 500;
  line-height: 10.2px;
  position: relative;
}

.element .more-horiz {
  width: 14.57px;
  height: 14.57px;
  margin-top: -12880px;
  margin-left: -785.57px;
  position: relative;
}

.element .text-wrapper-209 {
  color: #1f1c1a;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .more-horiz-2 {
  width: 14.57px;
  height: 14.57px;
  margin-top: -12918.4px;
  margin-left: -785.57px;
  position: relative;
}

.element .more-horiz-3 {
  width: 14.57px;
  height: 14.57px;
  margin-top: -12953.6px;
  margin-left: -785.57px;
  position: relative;
}

.element .upload-2 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.07px 0;
  display: flex;
  position: relative;
}

.element .more-horiz-4 {
  width: 14.57px;
  height: 14.57px;
  margin-top: -12988.9px;
  margin-left: -785.57px;
  position: relative;
}

.element .frame-400 {
  width: 576.87px;
  height: 7.29px;
  position: relative;
}

.element .line-wrapper {
  background-image: url("line-24-2.333ab219.svg");
  background-size: 100% 100%;
  width: 56px;
  height: 7px;
  position: relative;
}

.element .line-67 {
  width: 56px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-401 {
  justify-content: space-between;
  align-items: center;
  width: 578px;
  display: flex;
  position: absolute;
  top: 432px;
  left: 148px;
}

.element .play-button-5 {
  width: 21.86px;
  height: 21.86px;
  position: relative;
}

.element .create-video-video {
  background-color: #fff;
  border: 12px solid #5e5e5e1f;
  border-radius: 12px;
  width: 898px;
  height: 667px;
  position: absolute;
  top: 1599px;
  left: 1876px;
  overflow: hidden;
}

.element .header-36 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .61px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6.07px;
  width: 874px;
  padding: 9.71px 48.53px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-402 {
  flex: none;
  align-items: center;
  gap: 19.41px;
  display: inline-flex;
  position: relative;
}

.element .CTA-s-7 {
  flex: none;
  align-items: center;
  gap: 14.56px;
  display: inline-flex;
  position: relative;
}

.element .frame-403 {
  background-image: url("divider.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 24.26px;
  height: 24.26px;
  margin-top: -13408.7px;
  margin-left: -1385.23px;
  position: relative;
}

.element .frame-404 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16.98px;
  width: 576px;
  display: flex;
  position: absolute;
  top: 131px;
  left: 149px;
  overflow: hidden;
}

.element .text-wrapper-210 {
  color: #373432;
  letter-spacing: -.82px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Le Monde Livre Std-Demi, Helvetica;
  font-size: 29.1px;
  font-weight: 400;
  line-height: 34.9px;
  position: relative;
}

.element .frame-405 {
  align-items: flex-start;
  gap: 7.28px;
  width: 163.78px;
  display: flex;
  position: relative;
}

.element .button-base-21 {
  border: .61px solid #686462;
  border-radius: 2.43px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 3.03px;
  height: 29.12px;
  padding: 7.28px 14.56px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .61px 1.21px #1018280d;
}

.element .text-47 {
  color: #686462;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.33px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.6px;
  position: relative;
}

.element .button-base-22 {
  background-color: var(--neutral-90);
  border: .61px solid #075a5e;
  border-radius: 2.43px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 2.43px;
  height: 29.12px;
  padding: 7.28px 19.41px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .61px 1.21px #1018280d;
}

.element .text-48 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.33px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.6px;
  position: relative;
}

.element .frame-406 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14.56px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-407 {
  align-items: flex-start;
  gap: 16.98px;
  width: 346.98px;
  display: flex;
  position: relative;
}

.element .frame-408 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 16.98px;
  display: flex;
  position: relative;
}

.element .frame-409 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.71px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-410 {
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 6.07px;
  width: 100%;
  padding: 7.28px 9.71px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .button-tall-fixed-18 {
  background-color: var(--neutral-30);
  border-radius: 7.28px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.07px;
  padding: 1.21px 4.85px;
  display: inline-flex;
  position: relative;
}

.element .frame-411 {
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 6.07px;
  width: 100%;
  padding: 7.28px 9.71px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .frame-412 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4.85px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-211 {
  color: #373432;
  letter-spacing: -.05px;
  align-self: stretch;
  margin-top: -.61px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.element .upload-a-picture {
  color: #686462;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 11.9px;
  position: relative;
}

.element .frame-413 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.28px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-414 {
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 6.07px;
  height: 60.66px;
  padding: 7.28px 9.71px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .img-13 {
  width: 21.84px;
  height: 21.84px;
  position: relative;
}

.element .text-wrapper-212 {
  color: #373432;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 7.3px;
  font-weight: 500;
  line-height: 10.2px;
  position: relative;
}

.element .frame-415 {
  background-image: url("frame-40828-1.eb53c0b2.png");
  background-position: 50%;
  background-size: cover;
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex: 1;
  height: 60.66px;
  position: relative;
}

.element .frame-416 {
  background-image: url("divider.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  border: .61px solid #cac4c2;
  border-radius: 2.43px;
  flex: 1;
  height: 60.66px;
  position: relative;
}

.element .frame-417 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 274.79px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-418 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 9.71px;
  display: flex;
  position: relative;
}

.element .expand-more {
  width: 14.56px;
  height: 14.56px;
  margin-top: -13941.3px;
  margin-left: -420.12px;
  position: relative;
}

.element .frame-419 {
  border-radius: 2.43px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 16.98px;
  padding: 24.26px 7.28px 14.56px;
  display: flex;
  position: relative;
}

.element .frame-420 {
  background-color: var(--neutral-60);
  border-radius: 2.43px;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.07px;
  width: 100%;
  height: 135.88px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .frame-421 {
  width: 197.75px;
  height: 4.85px;
  margin-top: -13711.8px;
  margin-left: -1101.94px;
  position: relative;
}

.element .controls-2 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 197.75px;
  padding: 4.85px;
  display: flex;
  position: relative;
}

.element .frame-422 {
  flex: none;
  align-items: center;
  gap: 4.85px;
  display: inline-flex;
  position: relative;
}

.element .controls-3 {
  flex: none;
  align-items: flex-start;
  gap: 2.43px;
  display: inline-flex;
  position: relative;
}

.element .frame-423 {
  background-image: url("divider.bc340a45.png");
  background-size: 100% 100%;
  width: 14.56px;
  height: 14.56px;
  margin-top: -13722.8px;
  margin-left: -1106.8px;
  position: relative;
}

.element .text-wrapper-213 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 7.3px;
  font-weight: 500;
  line-height: 10.2px;
  position: relative;
}

.element .frame-424 {
  background-image: url("divider.bc340a45.png");
  background-size: 100% 100%;
  width: 14.56px;
  height: 14.56px;
  margin-top: -13722.8px;
  margin-left: -2283.19px;
  position: relative;
}

.element .frame-425 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.71px;
  display: inline-flex;
  position: relative;
}

.element .frame-426 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.43px;
  width: 200.18px;
  display: flex;
  position: relative;
}

.element .text-wrapper-214 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 7.3px;
  font-weight: 700;
  line-height: 10.2px;
  position: relative;
}

.element .text-wrapper-215 {
  color: var(--neutral-60);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .frame-427 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 2.43px;
  width: 200.18px;
  display: flex;
  position: relative;
}

.element .text-wrapper-216 {
  color: var(--secondary-02);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.33px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .content-copy {
  width: 14.56px;
  height: 14.56px;
  margin-top: -13807.7px;
  margin-left: -1970.32px;
  position: relative;
}

.element .frame-428 {
  align-items: center;
  gap: 7.28px;
  width: 576px;
  display: flex;
  position: absolute;
  top: 73px;
  left: 149px;
}

.element .frame-429 {
  background-color: var(--primary-02);
  border-radius: 19.41px;
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  padding: 7.28px;
  display: inline-flex;
  position: relative;
}

.element .check-6 {
  width: 19.41px;
  height: 19.41px;
  position: relative;
}

.element .check-7 {
  width: 19.41px;
  height: 19.41px;
  margin-top: -13471.8px;
  margin-left: -1004.28px;
  position: relative;
}

.element .line-68 {
  flex: 1;
  height: 1px;
  margin-top: -13454.8px;
  margin-left: -420.73px;
  position: relative;
}

.element .frame-430 {
  background-color: var(--neutral-30);
  border-radius: 19.41px;
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  padding: 7.28px;
  display: inline-flex;
  position: relative;
}

.element .check-8 {
  width: 19.41px;
  height: 19.41px;
  margin-top: -13471.8px;
  margin-left: -1275.43px;
  position: relative;
}

.element .upload-episode {
  background-color: #fff;
  border: 12px solid #5e5e5e1f;
  border-radius: 12px;
  width: 898px;
  height: 667px;
  position: absolute;
  top: 1599px;
  left: 938px;
  overflow: hidden;
}

.element .frame-431 {
  background-image: url("ellipse-1-15.cdc784c1.png");
  background-position: 50%;
  background-size: cover;
  width: 24.26px;
  height: 24.26px;
  position: relative;
}

.element .frame-432 {
  justify-content: space-between;
  align-items: center;
  width: 576.27px;
  display: flex;
  position: absolute;
  top: 131px;
  left: 149px;
}

.element .text-wrapper-217 {
  color: #373432;
  letter-spacing: -.37px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 26.7px;
  font-weight: 700;
  line-height: 32px;
  position: relative;
}

.element .button-base-23 {
  background-color: #075a5e;
  border: .61px solid;
  border-radius: 2.43px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 2.43px;
  padding: 7.28px 19.41px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .61px 1.21px #1018280d;
}

.element .frame-433 {
  border: .61px dashed #cac4c2;
  border-radius: 7.28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 576px;
  height: 309px;
  display: flex;
  position: absolute;
  top: 187px;
  left: 149px;
  overflow: hidden;
}

.element .frame-434 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.28px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-435 {
  width: 69.15px;
  height: 69.15px;
  position: relative;
}

.element .overlap-group-17 {
  background-color: #fafafa;
  border: .61px solid #e2dfde;
  border-radius: 32.45px;
  width: 65px;
  height: 65px;
  position: relative;
  top: 2px;
  left: 2px;
}

.element .publish-2 {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.element .frame-436 {
  align-self: stretch;
  width: 100%;
  height: 4.85px;
  position: relative;
}

.element .line-69 {
  width: 576px;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-437 {
  justify-content: space-between;
  align-items: center;
  width: 577px;
  display: flex;
  position: absolute;
  top: 506px;
  left: 148px;
}

.element .frame-438 {
  flex: none;
  align-items: center;
  gap: 7.28px;
  display: inline-flex;
  position: relative;
}

.element .create-podcast-4 {
  background-color: #fff;
  border: 12px solid #5e5e5e1f;
  border-radius: 12px;
  width: 898px;
  height: 667px;
  position: absolute;
  top: 1599px;
  left: 0;
  overflow: hidden;
}

.element .quick-links-3 {
  flex: none;
  margin-top: -13408.7px;
  margin-right: -526.6px;
  position: relative;
}

.element .frame-439 {
  flex: none;
  align-items: center;
  gap: 19.41px;
  margin-left: -550.87px;
  display: inline-flex;
  position: relative;
}

.element .frame-440 {
  background-image: url("ellipse-1-14.b0a91d6f.png");
  background-position: 50%;
  background-size: cover;
  width: 24.26px;
  height: 24.26px;
  position: relative;
}

.element .frame-441 {
  justify-content: flex-end;
  align-items: flex-start;
  width: 673px;
  padding: 19.41px;
  display: flex;
  position: absolute;
  top: 78px;
  left: 100px;
}

.element .frame-442 {
  flex: 1;
  align-items: center;
  gap: 19.41px;
  display: flex;
  position: relative;
}

.element .frame-443 {
  background-color: var(--accent-03);
  border-radius: 3.57px;
  width: 146.8px;
  height: 146.8px;
  position: relative;
  overflow: hidden;
}

.element .overlap-group-18 {
  border-radius: 104.55px;
  width: 209px;
  height: 209px;
  position: relative;
  top: -31px;
  left: -31px;
}

.element .ellipse-30 {
  background-color: var(--tertiary-02);
  border-radius: 104.55px;
  width: 209px;
  height: 209px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.element .ellipse-31 {
  background-color: #6247aa8f;
  border-radius: 80.65px / 80.43px;
  width: 161px;
  height: 161px;
  position: absolute;
  top: 24px;
  left: 24px;
  transform: rotate(-180deg);
}

.element .ellipse-32 {
  background-color: #826aed;
  border-radius: 55.41px / 55.18px;
  width: 111px;
  height: 110px;
  position: absolute;
  top: 49px;
  left: 50px;
  transform: rotate(-180deg);
  box-shadow: 0 1.79px 14.3px #7575751f;
}

.element .frame-444 {
  background-color: #ffffff3d;
  border-radius: 18.77px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 69px;
  left: 86px;
  box-shadow: 0 3.64px 9.69px #00000014;
}

.element .publish-3 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: -13529px;
  left: 1121px;
}

.element .text-wrapper-218 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 84px;
  height: 28px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.6px;
  position: absolute;
  top: 113px;
  left: 62px;
}

.element .frame-445 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 12.13px;
  display: flex;
  position: relative;
}

.element .text-wrapper-219 {
  color: #373432;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: relative;
}

.element .button-tall-fixed-19 {
  background-color: var(--neutral-30);
  border-radius: 7.28px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.07px;
  padding: 1.82px 4.85px;
  display: inline-flex;
  position: relative;
}

.element .text-49 {
  color: var(--neutral-60);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  line-height: 11.9px;
  position: relative;
}

.element .frame-446 {
  flex: none;
  align-items: flex-start;
  gap: 3.64px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-220 {
  color: var(--neutral-60);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 11.9px;
  position: relative;
}

.element .frame-447 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.64px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-221 {
  color: var(--neutral-60);
  letter-spacing: 0;
  flex: 1;
  margin-top: -.61px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.6px;
  position: relative;
}

.element .edit-wrapper {
  background-color: var(--neutral-20);
  border: .61px solid #999492;
  border-radius: 19.41px;
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  padding: 7.28px;
  display: inline-flex;
  position: relative;
}

.element .edit-2 {
  width: 14.56px;
  height: 14.56px;
  position: relative;
}

.element .overlap-40 {
  background-color: #fafafa;
  border-color: #e2dfde;
  border-top-style: solid;
  border-top-width: .61px;
  width: 874px;
  height: 317px;
  position: absolute;
  top: 272px;
  left: 0;
}

.element .upload-3 {
  border: .61px solid;
  border-color: var(--x-20);
  border-radius: 2.43px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 634.51px;
  height: 73px;
  padding: 12.13px 9.71px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 119px;
}

.element .drag-indicator-2 {
  width: 14.56px;
  height: 14.56px;
  margin-top: -13756.6px;
  margin-right: -1072.32px;
  position: relative;
}

.element .play-button-6 {
  width: 21.84px;
  height: 21.84px;
  margin-top: -13760.2px;
  margin-right: -1079.6px;
  position: relative;
}

.element .frame-448 {
  flex: none;
  align-items: flex-start;
  gap: 7.28px;
  display: inline-flex;
  position: relative;
}

.element .button-base-24 {
  border: .61px solid #075a5e;
  border-radius: 2.43px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.03px;
  height: 29.12px;
  padding: 7.28px 8.49px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .61px 1.21px #1018280d;
}

.element .text-50 {
  color: #075a5e;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.33px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.6px;
  position: relative;
}

.element .button-base-25 {
  background-color: #075a5e;
  border: .61px solid;
  border-radius: 2.43px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.03px;
  height: 29.12px;
  padding: 7.28px 14.56px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .61px 1.21px #1018280d;
}

.element .overlap-group-19 {
  background-image: url("line-24-1.e0c746ea.svg");
  background-size: 100% 100%;
  width: 473px;
  height: 2px;
  position: relative;
  top: 1px;
  left: 147px;
}

.element .line-70 {
  width: 1px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .upload-4 {
  border: .61px solid;
  border-color: var(--x-20);
  border-radius: 2.43px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 634.51px;
  height: 73px;
  padding: 12.13px 9.71px;
  display: flex;
  position: absolute;
  top: 169px;
  left: 119px;
}

.element .drag-indicator-3 {
  width: 14.56px;
  height: 14.56px;
  margin-top: -13849.4px;
  margin-right: -1072.32px;
  position: relative;
}

.element .play-button-7 {
  width: 21.84px;
  height: 21.84px;
  margin-top: -13853px;
  margin-right: -1079.6px;
  position: relative;
}

.element .overlap-group-20 {
  background-image: url("line-24.fa7c2122.svg");
  background-size: 100% 100%;
  width: 473px;
  height: 2px;
  position: relative;
  top: 1px;
  left: 147px;
}

.element .text-wrapper-222 {
  color: #373432;
  letter-spacing: -.14px;
  white-space: nowrap;
  height: 20px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
  position: absolute;
  top: 41px;
  left: 119px;
}

.element .frame-449 {
  width: 1920px;
  height: 1801px;
  position: absolute;
  top: 15833px;
  left: 0;
}

.element .text-wrapper-223 {
  color: #000;
  letter-spacing: -.24px;
  height: 59px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 64px;
  left: 129px;
}

.element .profile-page-creator {
  background-color: #fff;
  border: 8px solid #5e5e5e1f;
  border-radius: 8.29px;
  width: 1011px;
  height: 724px;
  position: absolute;
  top: 226px;
  left: 956px;
  overflow: hidden;
}

.element .frame-450 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22.11px;
  width: 209px;
  padding: 0 11.06px 0 0;
  display: flex;
  position: absolute;
  top: 72px;
  left: 55px;
  overflow: hidden;
}

.element .frame-451 {
  background-image: url("ellipse-1-13.9e9e3362.png");
  background-position: 50%;
  background-size: cover;
  width: 100.89px;
  height: 100.89px;
  position: relative;
}

.element .frame-452 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 2.76px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-453 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 38.7px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-454 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.29px;
  width: 111.25px;
  height: 23.49px;
  display: flex;
  position: relative;
}

.element .text-wrapper-224 {
  color: #1f1c1a;
  letter-spacing: -.15px;
  white-space: nowrap;
  flex: 1;
  align-self: stretch;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 19.3px;
  font-weight: 700;
  line-height: 23.2px;
  position: relative;
}

.element .label-3 {
  background-color: #075a5e;
  border-radius: 2.76px;
  flex: none;
  align-items: center;
  gap: 2.76px;
  padding: 2.76px 5.53px 2.76px 2.76px;
  display: inline-flex;
  position: relative;
}

.element .img-14 {
  width: 13.82px;
  height: 13.82px;
  position: relative;
}

.element .text-wrapper-225 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.5px;
  position: relative;
}

.element .overlap-41 {
  width: 264px;
  height: 216px;
  position: absolute;
  top: 256px;
  left: 0;
}

.element .frame-455 {
  flex-direction: column;
  align-items: flex-start;
  width: 209px;
  display: flex;
  position: absolute;
  top: 1px;
  left: 55px;
}

.element .div-13 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-456 {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.91px;
  width: 100%;
  padding: 9.67px 0;
  display: flex;
  position: relative;
}

.element .img-15 {
  width: 16.58px;
  height: 16.58px;
  position: relative;
}

.element .text-wrapper-226 {
  color: #999492;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.5px;
  position: relative;
}

.element .frame-457 {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5.53px;
  width: 100%;
  padding: 9.67px 0;
  display: flex;
  position: relative;
}

.element .frame-458 {
  border-radius: 22.11px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.91px;
  width: 100%;
  padding: 9.67px 0;
  display: flex;
  position: relative;
}

.element .text-wrapper-227 {
  color: #075a5e;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.5px;
  position: relative;
}

.element .line-71 {
  width: 264px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-42 {
  width: 731px;
  height: 658px;
  position: absolute;
  top: 50px;
  left: 264px;
}

.element .overlap-43 {
  width: 731px;
  height: 658px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .line-72 {
  width: 1px;
  height: 658px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-459 {
  justify-content: space-between;
  align-items: center;
  width: 730px;
  padding: 0 55.28px 0 16.58px;
  display: flex;
  position: absolute;
  top: 22px;
  left: 1px;
}

.element .tabs {
  flex: none;
  align-items: flex-start;
  gap: 5.53px;
  display: inline-flex;
  position: relative;
}

.element .nav-labels-6 {
  gap: unset !important;
  border: .69px solid !important;
  border-radius: 22.11px !important;
  flex: none !important;
  padding: 4.15px 6.91px 4.15px 8.29px !important;
}

.element .nav-labels-7 {
  gap: 6.91px !important;
}

.element .nav-labels-8 {
  color: #373432 !important;
  margin-top: -.69px !important;
}

.element .nav-labels-9 {
  gap: unset !important;
  background-color: #1f1c1a !important;
  border-radius: 22.11px !important;
  flex: none !important;
  padding: 4.15px 6.91px 4.15px 8.29px !important;
}

.element .nav-labels-10 {
  margin-top: -.69px !important;
}

.element .frame-460 {
  justify-content: center;
  align-items: center;
  gap: 1.38px;
  width: 58.74px;
  display: flex;
  position: relative;
}

.element .text-wrapper-228 {
  color: #373432;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .frame-461 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16.58px;
  width: 525px;
  display: flex;
  position: absolute;
  top: 66px;
  left: 1px;
}

.element .frame-462 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.91px;
  width: 100%;
  padding: 8.29px 16.58px;
  display: flex;
  position: relative;
}

.element .text-wrapper-229 {
  color: #1f1c1a;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 19.3px;
  font-weight: 700;
  line-height: 23.2px;
  position: relative;
}

.element .frame-463 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.6px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-464 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-465 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 2.76px 16.58px;
  display: flex;
  position: relative;
}

.element .frame-466 {
  align-self: stretch;
  align-items: center;
  gap: 6.91px;
  width: 225.27px;
  padding: 1.38px 0;
  display: flex;
  position: relative;
}

.element .text-wrapper-230 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.5px;
  position: relative;
}

.element .frame-467 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.91px;
  width: 60.81px;
  display: flex;
  position: relative;
}

.element .text-wrapper-231 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.5px;
  position: relative;
}

.element .table-row {
  flex: none;
  align-items: center;
  width: 514.81px;
  padding: 2.76px 0 2.76px 16.58px;
  display: flex;
  position: relative;
}

.element .cell-name-2 {
  align-items: center;
  gap: 6.91px;
  width: 225.27px;
  padding: 8.29px;
  display: flex;
  position: relative;
}

.element .text-wrapper-232 {
  color: #373432;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.5px;
  position: relative;
}

.element .div-wrapper-2 {
  justify-content: center;
  align-items: center;
  gap: 6.91px;
  width: 60.81px;
  padding: 8.29px;
  display: flex;
  position: relative;
}

.element .text-wrapper-233 {
  color: #373432;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.5px;
  position: relative;
}

.element .div-14 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60.81px;
  height: 30.4px;
  padding: 8.29px;
  display: flex;
  position: relative;
}

.element .text-wrapper-234 {
  color: #373432;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -5.78px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.3px;
  font-weight: 500;
  line-height: 11.6px;
  position: relative;
}

.element .text-wrapper-235 {
  color: #373432;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-bottom: -4.4px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.3px;
  font-weight: 500;
  line-height: 11.6px;
  position: relative;
}

.element .cell-actions {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 8.29px;
  display: flex;
  position: relative;
}

.element .more-horiz-5 {
  width: 16.58px;
  height: 16.58px;
  margin-left: -1.73px;
  margin-right: -1.73px;
  position: relative;
}

.element .nav {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 0 0 16.58px;
  display: flex;
  position: relative;
}

.element .label-4 {
  background-color: #075a5e;
  border-radius: 22.11px;
  flex: none;
  align-items: flex-start;
  gap: 6.91px;
  padding: 1.38px 5.53px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-236 {
  color: var(--neutral-20);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 8.3px;
  font-weight: 700;
  line-height: 11.6px;
  position: relative;
}

.element .container-nav {
  flex: none;
  align-items: flex-start;
  gap: 4.15px;
  display: inline-flex;
  position: relative;
}

.element .img-wrapper-2 {
  background-color: var(--neutral-20);
  border-radius: 22.11px;
  flex: none;
  align-items: flex-start;
  gap: 6.91px;
  padding: 5.53px;
  display: inline-flex;
  position: relative;
}

.element .frame-468 {
  background-color: var(--accent-01);
  border-radius: 4.07px;
  width: 133px;
  height: 133px;
  position: absolute;
  top: 66px;
  left: 542px;
  overflow: hidden;
}

.element .overlap-group-21 {
  width: 160px;
  height: 162px;
  position: relative;
  top: 24px;
  left: -15px;
}

.element .ellipse-33 {
  background: linear-gradient(#294d4a 0%, #294d4a54 66.85%, #294d4a00 100%);
  border-radius: 80.16px / 80.5px;
  width: 160px;
  height: 161px;
  position: absolute;
  top: 1px;
  left: 0;
  transform: rotate(-180deg);
}

.element .ellipse-34 {
  background: linear-gradient(#afdedc 0%, #afdedcab 32.63%, #afdedc00 100%);
  border-radius: 61.85px;
  width: 124px;
  height: 124px;
  position: absolute;
  top: 1px;
  left: 19px;
  transform: rotate(-180deg);
}

.element .ellipse-35 {
  background-color: var(--primary-02);
  border-radius: 43.19px / 42.5px;
  width: 86px;
  height: 85px;
  position: absolute;
  top: 0;
  left: 39px;
  transform: rotate(-180deg);
  box-shadow: 0 2.04px 16.29px #7575751f;
}

.element .frame-469 {
  background-color: #1f1c1a;
  border-radius: 22.11px;
  align-items: center;
  gap: 2.76px;
  height: 29px;
  padding: 4.15px 8.29px 4.15px 5.53px;
  display: inline-flex;
  position: absolute;
  top: 28px;
  left: 67px;
}

.element .edit-3 {
  width: 15.2px;
  height: 15.2px;
  position: relative;
}

.element .header-37 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6.91px;
  width: 995px;
  padding: 11.06px 55.28px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .div-15 {
  flex: none;
  align-items: center;
  gap: 22.11px;
  display: inline-flex;
  position: relative;
}

.element .humble-logo-5 {
  width: 69.1px;
  height: 27.64px;
  position: relative;
}

.element .frame-470 {
  align-items: flex-start;
  width: 193.48px;
  display: flex;
  position: relative;
}

.element .frame-471 {
  flex: 1;
  align-items: flex-start;
  gap: 6.91px;
  padding: 5.53px 8.29px;
  display: flex;
  position: relative;
}

.element .text-wrapper-237 {
  color: #075a5e;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  margin-right: -8.21px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.5px;
  position: relative;
}

.element .text-wrapper-238 {
  color: #075a5e;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.5px;
  position: relative;
}

.element .frame-472 {
  align-items: flex-start;
  width: 196.94px;
  display: flex;
  position: relative;
}

.element .div-container-2 {
  background-color: #fff;
  border-color: #cac4c2;
  border-top-style: solid;
  border-top-width: .69px;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-left-style: solid;
  border-left-width: .69px;
  border-radius: 27.64px 0 0 27.64px;
  flex: 1;
  align-items: flex-start;
  padding: 4.84px 2.76px 4.84px 11.75px;
  display: flex;
  position: relative;
  box-shadow: inset 0 .69px 1.38px #eee;
}

.element .search-4 {
  flex: 1;
  align-items: flex-start;
  padding: 2.07px 0 2.76px;
  display: flex;
  position: relative;
}

.element .div-placeholder-3 {
  flex: 1;
  align-items: flex-start;
  padding: 0 324.09px 0 0;
  display: flex;
  position: relative;
}

.element .text-wrapper-239 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  margin-right: -208.83px;
  font-family: Roboto, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .search-5 {
  width: 33.17px;
  position: relative;
}

.element .CTA-s-8 {
  flex: none;
  align-items: center;
  gap: 11.06px;
  display: inline-flex;
  position: relative;
}

.element .frame-473 {
  border: .69px solid #075a5e;
  border-radius: 2.76px;
  flex: none;
  align-items: center;
  gap: 2.76px;
  padding: 4.15px 8.29px 4.15px 5.53px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-240 {
  color: #075a5e;
  letter-spacing: 0;
  width: 25.57px;
  height: 16.58px;
  margin-top: -.69px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.5px;
  position: relative;
}

.element .frame-474 {
  background-image: url("ellipse-1-12.b4fdc9f3.png");
  background-position: 50%;
  background-size: cover;
  width: 27.64px;
  height: 27.64px;
  position: relative;
}

.element .profile-page-about {
  background-color: #fff;
  border: 8px solid #5e5e5e1f;
  border-radius: 8.29px;
  width: 1011px;
  height: 724px;
  position: absolute;
  top: 180px;
  left: -103px;
  overflow: hidden;
}

.element .frame-475 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22.11px;
  width: 177px;
  padding: 0 11.06px 0 0;
  display: flex;
  position: absolute;
  top: 72px;
  left: 55px;
}

.element .frame-476 {
  width: 100.89px;
  height: 100.89px;
  position: relative;
}

.element .ellipse-36 {
  object-fit: cover;
  width: 61px;
  height: 101px;
  position: absolute;
  top: 0;
  left: 40px;
}

.element .stars-4 {
  width: 13.82px;
  height: 13.82px;
  margin-top: -14612px;
  margin-right: -1021.95px;
  position: relative;
}

.element .overlap-44 {
  width: 205px;
  height: 180px;
  position: absolute;
  top: 256px;
  left: 59px;
}

.element .frame-477 {
  flex-direction: column;
  align-items: flex-start;
  width: 205px;
  display: flex;
  position: absolute;
  top: 1px;
  left: 0;
}

.element .list {
  width: 16.58px;
  height: 16.58px;
  margin-top: -14648.1px;
  margin-right: -862.62px;
  position: relative;
}

.element .text-wrapper-241 {
  color: #1f1c1a;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.5px;
  position: relative;
}

.element .favorite-2 {
  width: 16.58px;
  height: 16.58px;
  margin-top: -14684px;
  margin-right: -862.62px;
  position: relative;
}

.element .bookmark {
  width: 16.58px;
  height: 16.58px;
  margin-top: -14719.9px;
  margin-right: -862.62px;
  position: relative;
}

.element .view-quilt {
  width: 16.58px;
  height: 16.58px;
  margin-top: -14755.9px;
  margin-right: -862.62px;
  position: relative;
}

.element .file-copy {
  width: 16.58px;
  height: 16.58px;
  margin-top: -14791.8px;
  margin-right: -862.62px;
  position: relative;
}

.element .line-73 {
  width: 169px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 36px;
}

.element .line-74 {
  width: 1px;
  height: 658px;
  position: absolute;
  top: 50px;
  left: 264px;
}

.element .humble-logo-6 {
  width: 29.38px;
  height: 27.64px;
  position: relative;
}

.element .div-placeholder-4 {
  flex: none;
  align-items: flex-start;
  margin-right: -208.83px;
  padding: 0 324.09px 0 0;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-242 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Roboto, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .div-16 {
  flex: none;
  align-items: center;
  gap: 16.58px;
  display: inline-flex;
  position: relative;
}

.element .frame-478 {
  flex: none;
  align-items: center;
  gap: 2.76px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-243 {
  color: #075a5e;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.09px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.5px;
  position: relative;
}

.element .frame-479 {
  background-image: url("ellipse-1-10.5a41684a.png");
  background-position: 50%;
  background-size: cover;
  width: 27.64px;
  height: 27.64px;
  position: relative;
}

.element .frame-480 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.29px;
  display: inline-flex;
  position: absolute;
  top: 91px;
  left: 281px;
}

.element .text-wrapper-244 {
  color: #373432;
  letter-spacing: 0;
  width: 583.91px;
  margin-top: -.69px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .lorem-ipsum-dolor-3 {
  color: #0000;
  letter-spacing: 0;
  width: 583.91px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 15.5px;
  position: relative;
}

.element .text-wrapper-245 {
  color: #373432;
  font-weight: 500;
}

.element .text-wrapper-246 {
  color: #4c4c4c;
  font-weight: 500;
}

.element .frame-481 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.06px;
  display: inline-flex;
  position: absolute;
  top: 344px;
  left: 281px;
}

.element .frame-482 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 11.06px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-20 {
  background-color: var(--neutral-20);
  border-radius: 20.73px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8.29px;
  padding: 2.76px 5.53px;
  display: inline-flex;
  position: relative;
}

.element .text-51 {
  color: #686462;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 13.5px;
  position: relative;
}

.element .frame-483 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2.76px;
  display: inline-flex;
  position: absolute;
  top: 289px;
  left: 281px;
}

.element .text-wrapper-247 {
  color: #373432;
  letter-spacing: 0;
  width: 583.91px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.5px;
  position: relative;
}

.element .profile-page-about-2 {
  background-color: #fff;
  border: 8px solid #5e5e5e1f;
  border-radius: 8.29px;
  width: 1011px;
  height: 724px;
  position: absolute;
  top: 998px;
  left: 956px;
  overflow: hidden;
}

.element .frame-484 {
  background-image: url("ellipse-1-9.fecdd9b3.png");
  background-position: 50%;
  background-size: cover;
  width: 100.89px;
  height: 100.89px;
  position: relative;
}

.element .frame-485 {
  flex-direction: column;
  align-items: flex-start;
  width: 205px;
  display: flex;
  position: absolute;
  top: 1px;
  left: 59px;
}

.element .frame-486 {
  background-image: url("ellipse-1-8.b1cbb790.png");
  background-position: 50%;
  background-size: cover;
  width: 27.64px;
  height: 27.64px;
  position: relative;
}

.element .frame-487 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 72px;
  left: 281px;
}

.element .frame-488 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.91px;
  width: 100%;
  padding: 8.29px 0 27.64px;
  display: flex;
  position: relative;
}

.element .text-wrapper-248 {
  color: #1f1c1a;
  letter-spacing: -.12px;
  flex: 1;
  margin-top: -.69px;
  font-family: Le Monde Livre Std-Bold, Helvetica;
  font-size: 24.9px;
  font-weight: 700;
  line-height: 29.9px;
  position: relative;
}

.element .container-accoun {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.82px;
  width: 659.23px;
  display: flex;
  position: relative;
}

.element .container-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-489 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 13.82px 0;
  display: flex;
  position: relative;
}

.element .group-9 {
  width: 109px;
  height: 32.76px;
  position: relative;
}

.element .frame-490 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2.76px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-249 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.5px;
  position: relative;
}

.element .button-12 {
  all: unset;
  box-sizing: border-box;
  border-radius: 2.76px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.element .button-base-26 {
  border-radius: 2.76px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.53px;
  padding: 8.29px 0;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .text-52 {
  color: var(--secondary-02);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .frame-491 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 38.01px;
  padding: 13.82px 0;
  display: flex;
  position: relative;
}

.element .frame-492 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.38px;
  margin-top: -12.58px;
  margin-bottom: -12.58px;
  display: inline-flex;
  position: relative;
}

.element .frame-493 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 5.53px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-250 {
  color: #373432;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .button-13 {
  all: unset;
  box-sizing: border-box;
  border-radius: 2.76px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-top: -10.61px;
  margin-bottom: -10.61px;
  display: inline-flex;
  position: relative;
}

.element .divider-instance {
  height: unset !important;
  left: unset !important;
  top: unset !important;
  flex: none !important;
  width: 659.23px !important;
  position: relative !important;
}

.element .frame-494 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 0 13.82px;
  display: flex;
  position: relative;
}

.element .group-10 {
  width: 95px;
  height: 35.53px;
  position: relative;
}

.element .frame-495 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5.53px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-251 {
  color: #686462;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 15.5px;
  position: relative;
}

.element .group-11 {
  width: 87.76px;
  height: 35.53px;
  position: relative;
}

.element .frame-496 {
  flex: none;
  align-items: flex-start;
  gap: 2.76px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-252 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.5px;
  position: relative;
}

.element .group-12 {
  width: 88px;
  height: 35.53px;
  position: relative;
}

.element .group-13 {
  width: 80px;
  height: 35.53px;
  position: relative;
}

.element .frame-497 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 13.82px 0 0;
  display: flex;
  position: relative;
}

.element .group-14 {
  width: 155px;
  height: 35.53px;
  position: relative;
}

.element .profile-page-about-3 {
  background-color: #fff;
  border: 8px solid #5e5e5e1f;
  border-radius: 8.29px;
  width: 1011px;
  height: 724px;
  position: absolute;
  top: 952px;
  left: -103px;
  overflow: hidden;
}

.element .stars-5 {
  width: 13.82px;
  height: 13.82px;
  margin-top: -15383.6px;
  margin-right: -1021.95px;
  position: relative;
}

.element .overlap-45 {
  width: 209px;
  height: 216px;
  position: absolute;
  top: 256px;
  left: 55px;
}

.element .frame-498 {
  flex-direction: column;
  align-items: flex-start;
  width: 209px;
  display: flex;
  position: absolute;
  top: 1px;
  left: 0;
}

.element .list-2 {
  width: 16.58px;
  height: 16.58px;
  margin-top: -15419.7px;
  margin-right: -862.62px;
  position: relative;
}

.element .favorite-3 {
  width: 16.58px;
  height: 16.58px;
  margin-top: -15455.6px;
  margin-right: -862.62px;
  position: relative;
}

.element .view-quilt-2 {
  width: 16.58px;
  height: 16.58px;
  margin-top: -15491.5px;
  margin-right: -862.62px;
  position: relative;
}

.element .file-copy-2 {
  width: 16.58px;
  height: 16.58px;
  margin-top: -15527.5px;
  margin-right: -862.62px;
  position: relative;
}

.element .currency-exchange {
  width: 16.58px;
  height: 16.58px;
  margin-top: -15563.4px;
  margin-right: -862.62px;
  position: relative;
}

.element .settings {
  width: 16.58px;
  height: 16.58px;
  margin-top: -15599.3px;
  margin-right: -862.62px;
  position: relative;
}

.element .line-75 {
  width: 169px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 40px;
}

.element .frame-499 {
  background-image: url("ellipse-1-6.86ed5d9f.png");
  background-position: 50%;
  background-size: cover;
  width: 27.64px;
  height: 27.64px;
  position: relative;
}

.element .frame-500 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.91px;
  width: 100%;
  padding: 8.29px 0 22.11px;
  display: flex;
  position: relative;
}

.element .table-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 659px;
  height: 250px;
  display: flex;
  position: absolute;
  top: 379px;
  left: 281px;
  overflow: hidden;
}

.element .table {
  border: .69px solid #cac4c2;
  border-radius: 5.53px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22.11px;
  width: 660.61px;
  height: 251.53px;
  margin-top: -.69px;
  margin-left: -.69px;
  margin-right: -.69px;
  display: flex;
  position: relative;
}

.element .table-2 {
  border-radius: 2.76px;
  flex: 1;
  align-items: flex-start;
  width: 659.23px;
  display: flex;
  position: relative;
}

.element .column {
  flex-direction: column;
  align-items: flex-start;
  width: 98.12px;
  display: flex;
  position: relative;
}

.element .head {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-radius: 5.53px 0 0;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.76px;
  width: 100%;
  padding: 13.82px 16.58px;
  display: flex;
  position: relative;
}

.element .text-wrapper-253 {
  color: #075a5e;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .icon-2 {
  width: 11.06px !important;
  height: 11.06px !important;
  position: relative !important;
}

.element .head-2 {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  align-self: stretch;
  align-items: center;
  gap: 5.53px;
  width: 100%;
  height: 51.83px;
  padding: 11.06px 16.58px;
  display: flex;
  position: relative;
}

.element .frame-501 {
  flex: 1;
  align-items: center;
  gap: 5.53px;
  height: 15.2px;
  display: flex;
  position: relative;
}

.element .text-wrapper-254 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.59px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.5px;
  position: relative;
}

.element .head-3 {
  align-self: stretch;
  align-items: center;
  gap: 5.53px;
  width: 100%;
  height: 51.83px;
  padding: 11.06px 16.58px;
  display: flex;
  position: relative;
}

.element .column-2 {
  flex-direction: column;
  align-items: flex-start;
  width: 202.47px;
  display: flex;
  position: relative;
}

.element .head-4 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.38px;
  width: 100%;
  padding: 13.82px 11.06px;
  display: flex;
  position: relative;
}

.element .text-wrapper-255 {
  color: #075a5e;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .head-5 {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  align-self: stretch;
  align-items: center;
  gap: 8.29px;
  width: 100%;
  height: 51.83px;
  padding: 11.06px;
  display: flex;
  position: relative;
}

.element .text-wrapper-256 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  flex: 1;
  height: 15.2px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.5px;
  position: relative;
}

.element .head-6 {
  align-self: stretch;
  align-items: center;
  gap: 8.29px;
  width: 100%;
  height: 51.83px;
  padding: 11.06px;
  display: flex;
  position: relative;
}

.element .column-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 74.63px;
  display: flex;
  position: relative;
}

.element .head-7 {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.38px;
  width: 100%;
  padding: 13.82px 11.06px;
  display: flex;
  position: relative;
}

.element .text-wrapper-257 {
  color: #075a5e;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  margin-left: -.46px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .icon-4 {
  width: 11.06px !important;
  height: 11.06px !important;
  margin-right: -.46px !important;
  position: relative !important;
}

.element .button-states-wrapper {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8.29px;
  width: 100%;
  height: 51.83px;
  padding: 10.37px 11.06px;
  display: flex;
  position: relative;
}

.element .button-states {
  border: .35px solid #fff;
  border-radius: 22.11px;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .button-base-instance {
  background-color: var(--primary-02) !important;
  border-radius: 2.76px !important;
  flex: none !important;
  gap: 5.53px !important;
  padding: 2.76px 5.53px !important;
}

.element .button-base-27 {
  gap: 5.53px !important;
  height: 11.06px !important;
}

.element .button-base-28 {
  color: #fff !important;
  font-family: var(--web-caption-BLD-font-family) !important;
  font-size: var(--web-caption-BLD-font-size) !important;
  font-style: var(--web-caption-BLD-font-style) !important;
  font-weight: var(--web-caption-BLD-font-weight) !important;
  letter-spacing: var(--web-caption-BLD-letter-spacing) !important;
  line-height: var(--web-caption-BLD-line-height) !important;
  margin-top: -2.16px !important;
  margin-bottom: -.78px !important;
}

.element .button-states-2 {
  border: .35px solid;
  border-color: var(--neutral-30);
  border-radius: 22.11px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .content-wrapper {
  background-color: var(--neutral-30);
  border-radius: 2.76px;
  flex: none;
  align-items: center;
  gap: 5.53px;
  padding: 2.76px 5.53px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .content-3 {
  flex: none;
  align-items: center;
  gap: 5.53px;
  height: 11.06px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-258 {
  color: #373432;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2.16px;
  margin-bottom: -.78px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 9.7px;
  font-weight: 700;
  line-height: 13.5px;
  position: relative;
}

.element .head-8 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8.29px;
  width: 100%;
  height: 51.83px;
  padding: 10.37px 11.06px;
  display: flex;
  position: relative;
}

.element .column-4 {
  flex-direction: column;
  align-items: flex-start;
  width: 114.71px;
  height: 146.5px;
  display: flex;
  position: relative;
}

.element .frame-502 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 8.29px;
  display: flex;
  position: relative;
}

.element .text-wrapper-259 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 8.3px;
  font-weight: 500;
  line-height: 11.6px;
  position: relative;
}

.element .frame-503 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.76px;
  display: inline-flex;
  position: relative;
}

.element .head-9 {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  align-self: stretch;
  align-items: center;
  gap: 8.29px;
  width: 100%;
  height: 51.83px;
  margin-bottom: -51.62px;
  padding: 11.06px;
  display: flex;
  position: relative;
}

.element .head-10 {
  align-self: stretch;
  align-items: center;
  gap: 8.29px;
  width: 100%;
  height: 51.83px;
  margin-bottom: -103.45px;
  padding: 11.06px;
  display: flex;
  position: relative;
}

.element .column-5 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 82.92px;
  display: flex;
  position: relative;
}

.element .head-11 {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  align-self: stretch;
  align-items: center;
  gap: 5.53px;
  width: 100%;
  height: 51.83px;
  padding: 11.06px;
  display: flex;
  position: relative;
}

.element .text-wrapper-260 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: -3.19px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .text-wrapper-261 {
  color: #686462;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: -1.19px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .head-12 {
  align-self: stretch;
  align-items: center;
  gap: 5.53px;
  width: 100%;
  height: 51.83px;
  padding: 11.06px;
  display: flex;
  position: relative;
}

.element .column-6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 86.38px;
  display: flex;
  position: relative;
}

.element .head-13 {
  border-color: #999492;
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-radius: 0 5.53px 0 0;
  align-self: stretch;
  width: 100%;
  height: 42.84px;
  position: relative;
}

.element .button-wrapper {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.53px;
  width: 100%;
  height: 51.83px;
  padding: 6.22px 5.53px;
  display: flex;
  position: relative;
}

.element .button-instance {
  border-radius: 2.76px !important;
  flex: none !important;
}

.element .button-14 {
  border: .69px solid !important;
  border-color: unset !important;
  background-color: #075a5e !important;
  border-radius: 2.76px !important;
  gap: 5.53px !important;
  padding: 5.53px 11.06px !important;
}

.element .button-15 {
  color: #fff !important;
  margin-top: -.69px !important;
}

.element .head-14 {
  border-bottom-style: solid;
  border-bottom-width: .69px;
  border-color: var(--neutral-30);
  align-self: stretch;
  width: 100%;
  height: 51.83px;
  position: relative;
}

.element .head-15 {
  align-self: stretch;
  width: 100%;
  height: 51.83px;
  position: relative;
}

.element .frame-504 {
  background-color: #1f1c1a;
  border-radius: 5.53px;
  flex-direction: column;
  align-items: center;
  gap: 79.47px;
  width: 659.23px;
  padding: 16.58px 0;
  display: flex;
  position: absolute;
  top: 154px;
  left: 281px;
}

.element .frame-505 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.91px;
  width: 100%;
  padding: 6.91px 22.11px;
  display: flex;
  position: relative;
}

.element .text-wrapper-262 {
  color: #fff;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 19.3px;
  font-weight: 700;
  line-height: 23.2px;
  position: relative;
}

.element .frame-506 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 22.11px;
  display: flex;
  position: relative;
}

.element .text-wrapper-263 {
  color: #fff;
  letter-spacing: 0;
  width: 615px;
  margin-top: -.69px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .text-wrapper-264 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  margin-left: -200px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 11.1px;
  font-weight: 700;
  line-height: 15.5px;
  position: relative;
}

.element .frame-507 {
  background-color: #000;
  width: 1920px;
  height: 1800px;
  position: absolute;
  top: 17634px;
  left: 0;
  overflow: hidden;
}

.element .text-wrapper-265 {
  color: #fff;
  letter-spacing: -.24px;
  height: 59px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 122px;
  left: 122px;
}

.element .profile-page {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 376px;
  height: 1022px;
  position: absolute;
  top: 904px;
  left: 1417px;
  overflow: hidden;
}

.element .overlap-group-22 {
  background-color: #fff;
  border-color: #eee;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 360px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-508 {
  justify-content: space-between;
  align-items: center;
  width: 328px;
  display: flex;
  position: relative;
  top: 9px;
  left: 15px;
}

.element .humble-logo-7 {
  width: 76px;
  height: 30px;
  position: relative;
}

.element .frame-509 {
  border: 1px solid #075a5e;
  border-radius: 4px;
  flex: none;
  align-items: center;
  gap: 4px;
  padding: 4px 12px 4px 8px;
  display: inline-flex;
  position: relative;
}

.element .edit-4 {
  width: 16px;
  height: 16px;
  position: relative;
}

.element .text-wrapper-266 {
  color: #075a5e;
  font-family: var(--mobile-caption-BLD-font-family);
  font-size: var(--mobile-caption-BLD-font-size);
  font-style: var(--mobile-caption-BLD-font-style);
  font-weight: var(--mobile-caption-BLD-font-weight);
  letter-spacing: var(--mobile-caption-BLD-letter-spacing);
  line-height: var(--mobile-caption-BLD-line-height);
  width: 37px;
  height: 24px;
  margin-top: -1px;
  position: relative;
}

.element .frame-510 {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.element .frame-511 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.element .frame-512 {
  background-color: var(--neutral-20);
  border-radius: 20px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 2px;
  display: inline-flex;
  position: relative;
}

.element .ellipse-37 {
  object-fit: cover;
  width: 32px;
  height: 32px;
  position: relative;
}

.element .frame-513 {
  background-color: #f1f1f3;
  border-radius: 32px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 6px;
  display: inline-flex;
  position: relative;
}

.element .frame-514 {
  background-image: url("menu.6a08b1ac.png");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: relative;
}

.element .tabs-wrapper {
  align-items: center;
  gap: 37px;
  display: inline-flex;
  position: absolute;
  top: 80px;
  left: 16px;
}

.element .div-17 {
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.element .nav-labels-11 {
  gap: unset !important;
  flex: none !important;
  padding: 6px 10px 6px 12px !important;
}

.element .nav-labels-12 {
  color: #373432 !important;
  font-family: var(--mobile-caption-REG-font-family) !important;
  font-size: var(--mobile-caption-REG-font-size) !important;
  font-style: var(--mobile-caption-REG-font-style) !important;
  font-weight: var(--mobile-caption-REG-font-weight) !important;
  letter-spacing: var(--mobile-caption-REG-letter-spacing) !important;
  line-height: var(--mobile-caption-REG-line-height) !important;
}

.element .nav-labels-13 {
  gap: unset !important;
  background-color: #1f1c1a !important;
  flex: none !important;
  padding: 6px 10px 6px 12px !important;
}

.element .nav-labels-14 {
  font-family: var(--mobile-caption-DMI-font-family) !important;
  font-size: var(--mobile-caption-DMI-font-size) !important;
  font-style: var(--mobile-caption-DMI-font-style) !important;
  font-weight: var(--mobile-caption-DMI-font-weight) !important;
  letter-spacing: var(--mobile-caption-DMI-letter-spacing) !important;
  line-height: var(--mobile-caption-DMI-line-height) !important;
}

.element .frame-515 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 122px;
  left: 16px;
}

.element .div-18 {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.element .frame-516 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 156px;
  display: flex;
  position: relative;
}

.element .play-button-wrapper {
  background-image: url("rectangle-17-10.e5c65545.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 112px;
  position: relative;
}

.element .play-button-8 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 44px;
  left: 66px;
}

.element .frame-517 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-518 {
  background-image: url("frame-40658.b6d76e38.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 30px;
  width: 24px;
  height: 24px;
  position: relative;
}

.element .frame-519 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  position: relative;
}

.element .text-wrapper-267 {
  color: var(--neutral-70);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.49px;
  font-family: Articulat CF-DemiBold, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
  position: relative;
}

.element .frame-520 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.95px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-268 {
  color: var(--neutral-70);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.49px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element .text-53 {
  color: var(--neutrals-10);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.49px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element .text-54 {
  color: var(--neutrals-10);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.49px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  position: relative;
}

.element .frame-521 {
  background-image: url("rectangle-17-3.6cdca415.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 112px;
  position: relative;
}

.element .frame-522 {
  background-image: url("rectangle-17-4.f0afd6c5.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 112px;
  position: relative;
}

.element .profile-page-stacks {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 376px;
  height: 844px;
  position: absolute;
  top: 950px;
  left: 1857px;
  overflow: hidden;
}

.element .humble-logo-8 {
  width: 39px;
  height: 30px;
  position: relative;
}

.element .edit-5 {
  width: 16px;
  height: 16px;
  margin-top: -16970px;
  margin-left: -1005px;
  position: relative;
}

.element .ellipse-38 {
  object-fit: cover;
  width: 32px;
  height: 32px;
  margin-top: -16972px;
  margin-left: -1094px;
  position: relative;
}

.element .arrow-drop-down {
  width: 24px;
  height: 24px;
  margin-top: -16966px;
  margin-left: -1058px;
  position: relative;
}

.element .frame-523 {
  background-image: url("divider.bc340a45.png");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  margin-top: -16980px;
  margin-left: -1168px;
  position: relative;
}

.element .frame-524 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 80px;
  left: 16px;
}

.element .container-product-wrapper {
  border-color: #cac4c2;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex: none;
  align-items: center;
  gap: 8px;
  width: 328px;
  padding: 0 0 24px;
  display: flex;
  position: relative;
}

.element .container-product-3 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  width: 328px;
  display: flex;
  position: relative;
}

.element .frame-525 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .image-13 {
  background-color: #fff;
  border-radius: 4px;
  width: 56px;
  height: 56px;
  position: relative;
  overflow: hidden;
}

.element .image-14 {
  object-fit: cover;
  width: 30px;
  height: 37px;
  position: absolute;
  top: 9px;
  left: 9px;
}

.element .container-product-4 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.element .div-19 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-269 {
  color: #373432;
  letter-spacing: -.06px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Le Monde Livre Cla Std-Demi, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  position: relative;
}

.element .frame-526 {
  border-radius: 12px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 4px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .text-55 {
  color: #999492;
  font-family: var(--mobile-caption-REG-font-family);
  font-size: var(--mobile-caption-REG-font-size);
  font-style: var(--mobile-caption-REG-font-style);
  font-weight: var(--mobile-caption-REG-font-weight);
  letter-spacing: var(--mobile-caption-REG-letter-spacing);
  line-height: var(--mobile-caption-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-527 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .button-base-29 {
  box-shadow: var(--shadow-xs);
  background-color: #075a5e;
  border: 1px solid;
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 9px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-56 {
  color: #fff;
  font-family: var(--mobile-caption-BLD-font-family);
  font-size: var(--mobile-caption-BLD-font-size);
  font-style: var(--mobile-caption-BLD-font-style);
  font-weight: var(--mobile-caption-BLD-font-weight);
  letter-spacing: var(--mobile-caption-BLD-letter-spacing);
  line-height: var(--mobile-caption-BLD-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .button-base-30 {
  box-shadow: var(--shadow-xs);
  background-color: #fff;
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 9px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-57 {
  color: #373432;
  font-family: var(--mobile-caption-BLD-font-family);
  font-size: var(--mobile-caption-BLD-font-size);
  font-style: var(--mobile-caption-BLD-font-style);
  font-weight: var(--mobile-caption-BLD-font-weight);
  letter-spacing: var(--mobile-caption-BLD-letter-spacing);
  line-height: var(--mobile-caption-BLD-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .login {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 376px;
  height: 804px;
  position: absolute;
  top: 36px;
  left: 1417px;
  overflow: hidden;
}

.element .overlap-46 {
  background-color: #fff;
  width: 360px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-528 {
  width: 328px;
  height: 30px;
  position: absolute;
  top: 12px;
  left: 16px;
}

.element .frame-529 {
  background-color: #fff;
  border: 1px solid #f3eff5;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 328px;
  padding: 24px 12px;
  display: flex;
  position: absolute;
  top: 72px;
  left: 16px;
  overflow: hidden;
  box-shadow: 8px 12px 32px #64646414;
}

.element .frame-530 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 24px;
  display: flex;
  position: relative;
}

.element .frame-531 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-270 {
  color: #1f1c1a;
  font-family: var(--mobile-h4-BLD-font-family);
  font-size: var(--mobile-h4-BLD-font-size);
  font-style: var(--mobile-h4-BLD-font-style);
  font-weight: var(--mobile-h4-BLD-font-weight);
  letter-spacing: var(--mobile-h4-BLD-letter-spacing);
  line-height: var(--mobile-h4-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-58 {
  color: #686462;
  font-family: var(--mobile-h6-REG-font-family);
  font-size: var(--mobile-h6-REG-font-size);
  font-style: var(--mobile-h6-REG-font-style);
  font-weight: var(--mobile-h6-REG-font-weight);
  letter-spacing: var(--mobile-h6-REG-letter-spacing);
  line-height: var(--mobile-h6-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-532 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-533 {
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .frame-534 {
  width: 20px;
  height: 20px;
  position: relative;
}

.element .text-59 {
  color: #999492;
  font-family: var(--mobile-paragraph-DMI-font-family);
  font-size: var(--mobile-paragraph-DMI-font-size);
  font-style: var(--mobile-paragraph-DMI-font-style);
  font-weight: var(--mobile-paragraph-DMI-font-weight);
  letter-spacing: var(--mobile-paragraph-DMI-letter-spacing);
  line-height: var(--mobile-paragraph-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-535 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .line-76 {
  object-fit: cover;
  flex: 1;
  height: 1px;
  position: relative;
}

.element .text-60 {
  color: #cac4c2;
  font-family: var(--mobile-paragraph-DMI-font-family);
  font-size: var(--mobile-paragraph-DMI-font-size);
  font-style: var(--mobile-paragraph-DMI-font-style);
  font-weight: var(--mobile-paragraph-DMI-font-weight);
  letter-spacing: var(--mobile-paragraph-DMI-letter-spacing);
  line-height: var(--mobile-paragraph-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-536 {
  border-radius: 4px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-537 {
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 11px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-271 {
  color: #999492;
  font-family: var(--mobile-paragraph-DMI-font-family);
  font-size: var(--mobile-paragraph-DMI-font-size);
  font-style: var(--mobile-paragraph-DMI-font-style);
  font-weight: var(--mobile-paragraph-DMI-font-weight);
  letter-spacing: var(--mobile-paragraph-DMI-letter-spacing);
  line-height: var(--mobile-paragraph-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .checkbox {
  background-image: url("object.8af62b8d.png");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: relative;
}

.element .text-61 {
  color: #999492;
  font-family: var(--mobile-caption-REG-font-family);
  font-size: var(--mobile-caption-REG-font-size);
  font-style: var(--mobile-caption-REG-font-style);
  font-weight: var(--mobile-caption-REG-font-weight);
  letter-spacing: var(--mobile-caption-REG-letter-spacing);
  line-height: var(--mobile-caption-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .button-16 {
  all: unset;
  background-color: var(--primary-02);
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.element .button-base-31 {
  all: unset;
  background-color: var(--primary-02);
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-62 {
  color: #fff;
  font-family: var(--mobile-paragraph-BLD-font-family);
  font-size: var(--mobile-paragraph-BLD-font-size);
  font-style: var(--mobile-paragraph-BLD-font-style);
  font-weight: var(--mobile-paragraph-BLD-font-weight);
  letter-spacing: var(--mobile-paragraph-BLD-letter-spacing);
  line-height: var(--mobile-paragraph-BLD-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-63 {
  color: var(--primary-02);
  font-family: var(--mobile-paragraph-BLD-font-family);
  font-size: var(--mobile-paragraph-BLD-font-size);
  font-style: var(--mobile-paragraph-BLD-font-style);
  font-weight: var(--mobile-paragraph-BLD-font-weight);
  letter-spacing: var(--mobile-paragraph-BLD-letter-spacing);
  line-height: var(--mobile-paragraph-BLD-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .text-64 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  position: relative;
}

.element .text-wrapper-272 {
  color: #686462;
  font-family: var(--mobile-caption-REG-font-family);
  font-size: var(--mobile-caption-REG-font-size);
  font-style: var(--mobile-caption-REG-font-style);
  font-weight: var(--mobile-caption-REG-font-weight);
  letter-spacing: var(--mobile-caption-REG-letter-spacing);
  line-height: var(--mobile-caption-REG-line-height);
}

.element .text-wrapper-273 {
  color: #686462;
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
}

.element .text-wrapper-274 {
  color: #44bba4;
  font-family: var(--mobile-caption-BLD-font-family);
  font-size: var(--mobile-caption-BLD-font-size);
  font-style: var(--mobile-caption-BLD-font-style);
  font-weight: var(--mobile-caption-BLD-font-weight);
  letter-spacing: var(--mobile-caption-BLD-letter-spacing);
  line-height: var(--mobile-caption-BLD-line-height);
}

.element .login-details {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 376px;
  height: 803px;
  position: absolute;
  top: 83px;
  left: 1857px;
  overflow: hidden;
}

.element .frame-538 {
  width: 39px;
  height: 30px;
  position: absolute;
  top: 12px;
  left: 16px;
}

.element .frame-539 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 328px;
  display: flex;
  position: absolute;
  top: 72px;
  left: 16px;
}

.element .text-wrapper-275 {
  color: #686462;
  font-family: var(--mobile-h6-REG-font-family);
  font-size: var(--mobile-h6-REG-font-size);
  font-style: var(--mobile-h6-REG-font-style);
  font-weight: var(--mobile-h6-REG-font-weight);
  letter-spacing: var(--mobile-h6-REG-letter-spacing);
  line-height: var(--mobile-h6-REG-line-height);
  align-self: stretch;
  position: relative;
}

.element .button-base-32 {
  background-color: var(--primary-02);
  box-shadow: var(--shadow-xs);
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 10px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .all-topics {
  width: 376px;
  height: 1515px;
  position: absolute;
  top: 285px;
  left: 97px;
}

.element .profile-page-2 {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 376px;
  height: 977px;
  position: absolute;
  top: 872px;
  left: 977px;
  overflow: hidden;
}

.element .frame-540 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 130px;
  left: 16px;
}

.element .frame-541 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 156px;
  display: flex;
  position: relative;
}

.element .frame-542 {
  background-color: #0003;
  background-image: url("frame-40657-3.90a932e0.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  align-self: stretch;
  width: 100%;
  height: 112px;
  position: relative;
}

.element .frame-543 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-276 {
  color: var(--neutral-70);
  font-family: var(--mobile-paragraph-DMI-font-family);
  font-size: var(--mobile-paragraph-DMI-font-size);
  font-style: var(--mobile-paragraph-DMI-font-style);
  font-weight: var(--mobile-paragraph-DMI-font-weight);
  letter-spacing: var(--mobile-paragraph-DMI-letter-spacing);
  line-height: var(--mobile-paragraph-DMI-line-height);
  align-self: stretch;
  height: 44px;
  margin-top: -1px;
  position: relative;
}

.element .frame-544 {
  background-color: #f3eff5;
  border-radius: 15px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 10px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-277 {
  color: #373432;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element .text-65 {
  color: #cac4c2;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.5px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.element .text-66 {
  color: #cac4c2;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element .frame-545 {
  background-color: #0003;
  background-image: url("frame-40657-2.984e0b30.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  align-self: stretch;
  width: 100%;
  height: 112px;
  position: relative;
}

.element .frame-546 {
  background-image: url("frame-40657-1.1077bc33.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  align-self: stretch;
  width: 100%;
  height: 112px;
  position: relative;
}

.element .frame-547 {
  background-color: #0003;
  background-image: url("frame-40657.85cd1c53.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  align-self: stretch;
  width: 100%;
  height: 112px;
  position: relative;
}

.element .create-podcast-5 {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 376px;
  height: 816px;
  position: absolute;
  top: -8px;
  left: 977px;
  overflow: hidden;
}

.element .frame-548 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.element .overlap-47 {
  width: 340px;
  height: 375px;
  position: absolute;
  top: 302px;
  left: 16px;
}

.element .frame-549 {
  background-image: url("fullscreen-1.eed38909.png");
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 325px;
}

.element .frame-550 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-551 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 328px;
  display: flex;
  position: relative;
}

.element .text-wrapper-278 {
  color: #373432;
  font-family: var(--mobile-h6-DMI-font-family);
  font-size: var(--mobile-h6-DMI-font-size);
  font-style: var(--mobile-h6-DMI-font-style);
  font-weight: var(--mobile-h6-DMI-font-weight);
  letter-spacing: var(--mobile-h6-DMI-letter-spacing);
  line-height: var(--mobile-h6-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-552 {
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .button-tall-fixed-21 {
  background-color: var(--neutral-30);
  border-radius: 12px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 8px;
  display: inline-flex;
  position: relative;
}

.element .text-67 {
  color: #686462;
  font-family: var(--mobile-caption-REG-font-family);
  font-size: var(--mobile-caption-REG-font-size);
  font-style: var(--mobile-caption-REG-font-style);
  font-weight: var(--mobile-caption-REG-font-weight);
  letter-spacing: var(--mobile-caption-REG-letter-spacing);
  line-height: var(--mobile-caption-REG-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-553 {
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-279 {
  color: #686462;
  font-family: var(--mobile-caption-REG-font-family);
  font-size: var(--mobile-caption-REG-font-size);
  font-style: var(--mobile-caption-REG-font-style);
  font-weight: var(--mobile-caption-REG-font-weight);
  letter-spacing: var(--mobile-caption-REG-letter-spacing);
  line-height: var(--mobile-caption-REG-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element .frame-554 {
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 9px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-280 {
  color: #686462;
  font-family: var(--mobile-caption-REG-font-family);
  font-size: var(--mobile-caption-REG-font-size);
  font-style: var(--mobile-caption-REG-font-style);
  font-weight: var(--mobile-caption-REG-font-weight);
  letter-spacing: var(--mobile-caption-REG-letter-spacing);
  line-height: var(--mobile-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-555 {
  align-items: center;
  gap: 8px;
  width: 328px;
  display: flex;
  position: absolute;
  top: 80px;
  left: 16px;
}

.element .frame-556 {
  background-color: var(--primary-02);
  border-radius: 15px;
  flex: none;
  align-items: flex-start;
  gap: 4.29px;
  padding: 5.14px;
  display: inline-flex;
  position: relative;
}

.element .check-9 {
  width: 13.71px;
  height: 13.71px;
  position: relative;
}

.element .frame-557 {
  background-color: var(--neutral-30);
  border-radius: 15px;
  flex: none;
  align-items: flex-start;
  gap: 4.29px;
  padding: 5.14px;
  display: inline-flex;
  position: relative;
}

.element .frame-558 {
  background-color: var(--neutral-30);
  border-radius: 13.71px;
  flex: none;
  align-items: flex-start;
  gap: 4.29px;
  padding: 5.14px;
  display: inline-flex;
  position: relative;
}

.element .frame-559 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 328px;
  display: flex;
  position: absolute;
  top: 128px;
  left: 16px;
}

.element .text-wrapper-281 {
  color: #373432;
  font-family: var(--mobile-h2-DMI-font-family);
  font-size: var(--mobile-h2-DMI-font-size);
  font-style: var(--mobile-h2-DMI-font-style);
  font-weight: var(--mobile-h2-DMI-font-weight);
  letter-spacing: var(--mobile-h2-DMI-letter-spacing);
  line-height: var(--mobile-h2-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .button-base-33 {
  all: unset;
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  background-color: #075a5e;
  border: 1px solid;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 328px;
  padding: 8px 32px;
  display: flex;
  position: absolute;
  top: 739px;
  left: 16px;
  overflow: hidden;
}

.element .text-68 {
  color: #fff;
  font-family: var(--mobile-paragraph-BLD-font-family);
  font-size: var(--mobile-paragraph-BLD-font-size);
  font-style: var(--mobile-paragraph-BLD-font-style);
  font-weight: var(--mobile-paragraph-BLD-font-weight);
  letter-spacing: var(--mobile-paragraph-BLD-letter-spacing);
  line-height: var(--mobile-paragraph-BLD-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .create-video-video-2 {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 376px;
  height: 1161px;
  position: absolute;
  top: 881px;
  left: 537px;
  overflow: hidden;
}

.element .overlap-48 {
  width: 340px;
  height: 527px;
  position: absolute;
  top: 182px;
  left: 16px;
}

.element .frame-560 {
  background-image: url("fullscreen-1.eed38909.png");
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 120px;
  left: 325px;
}

.element .text-wrapper-282 {
  color: #373432;
  font-family: var(--mobile-h6-DMI-font-family);
  font-size: var(--mobile-h6-DMI-font-size);
  font-style: var(--mobile-h6-DMI-font-style);
  font-weight: var(--mobile-h6-DMI-font-weight);
  letter-spacing: var(--mobile-h6-DMI-letter-spacing);
  line-height: var(--mobile-h6-DMI-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.element .upload-a-picture-2 {
  color: #686462;
  font-family: var(--mobile-caption-REG-font-family);
  font-size: var(--mobile-caption-REG-font-size);
  font-style: var(--mobile-caption-REG-font-style);
  font-weight: var(--mobile-caption-REG-font-weight);
  letter-spacing: var(--mobile-caption-REG-letter-spacing);
  line-height: var(--mobile-caption-REG-line-height);
  align-self: stretch;
  position: relative;
}

.element .frame-561 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .frame-562 {
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-283 {
  color: #373432;
  font-family: var(--mobile-tiny-reg-font-family);
  font-size: var(--mobile-tiny-reg-font-size);
  font-style: var(--mobile-tiny-reg-font-style);
  font-weight: var(--mobile-tiny-reg-font-weight);
  letter-spacing: var(--mobile-tiny-reg-letter-spacing);
  line-height: var(--mobile-tiny-reg-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: -8.33px;
  margin-right: -8.33px;
  position: relative;
}

.element .frame-563 {
  background-image: url("frame-40828.22eedcea.png");
  background-position: 50%;
  background-size: cover;
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: 1;
  height: 73px;
  position: relative;
}

.element .frame-564 {
  background-image: url("frame-40829.31d40c94.png");
  background-position: 50%;
  background-size: cover;
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: 1;
  height: 73px;
  position: relative;
}

.element .expand-more-wrapper {
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 9px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-284 {
  color: #373432;
  font-family: var(--mobile-h3-BLD-font-family);
  font-size: var(--mobile-h3-BLD-font-size);
  font-style: var(--mobile-h3-BLD-font-style);
  font-weight: var(--mobile-h3-BLD-font-weight);
  letter-spacing: var(--mobile-h3-BLD-letter-spacing);
  line-height: var(--mobile-h3-BLD-line-height);
  white-space: nowrap;
  height: 30px;
  position: absolute;
  top: 128px;
  left: 16px;
}

.element .frame-565 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 741px;
  left: 16px;
}

.element .frame-566 {
  background-color: var(--neutral-60);
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  width: 330px;
  height: 180px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .frame-567 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.element .frame-568 {
  width: 326px;
  height: 8px;
  position: relative;
}

.element .controls-4 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 326px;
  padding: 8px;
  display: flex;
  position: relative;
}

.element .img-16 {
  width: 21px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-285 {
  color: #fff;
  font-family: var(--mobile-tiny-reg-font-family);
  font-size: var(--mobile-tiny-reg-font-size);
  font-style: var(--mobile-tiny-reg-font-style);
  font-weight: var(--mobile-tiny-reg-font-weight);
  letter-spacing: var(--mobile-tiny-reg-letter-spacing);
  line-height: var(--mobile-tiny-reg-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .fullscreen {
  width: 20px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .frame-569 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  width: 330px;
  display: flex;
  position: relative;
}

.element .text-wrapper-286 {
  color: #686462;
  font-family: var(--mobile-tiny-MED-font-family);
  font-size: var(--mobile-tiny-MED-font-size);
  font-style: var(--mobile-tiny-MED-font-style);
  font-weight: var(--mobile-tiny-MED-font-weight);
  letter-spacing: var(--mobile-tiny-MED-letter-spacing);
  line-height: var(--mobile-tiny-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-287 {
  color: var(--neutral-60);
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-570 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 330px;
  display: flex;
  position: relative;
}

.element .text-wrapper-288 {
  color: var(--secondary-02);
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .content-copy-2 {
  width: 20px;
  height: 20px;
  margin-top: -17912px;
  position: relative;
}

.element .frame-571 {
  flex: none;
  align-items: flex-start;
  gap: 12px;
  width: 326px;
  display: flex;
  position: relative;
}

.element .button-base-34 {
  border: 1px solid;
  border-color: var(--neutral-90);
  box-shadow: var(--shadow-xs);
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-69 {
  color: var(--neutral-90);
  font-family: var(--mobile-paragraph-BLD-font-family);
  font-size: var(--mobile-paragraph-BLD-font-size);
  font-style: var(--mobile-paragraph-BLD-font-style);
  font-weight: var(--mobile-paragraph-BLD-font-weight);
  letter-spacing: var(--mobile-paragraph-BLD-letter-spacing);
  line-height: var(--mobile-paragraph-BLD-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .button-base-35 {
  all: unset;
  background-color: var(--neutral-90);
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  border: 1px solid #075a5e;
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 32px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .content-unlocked {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #d9d9d9;
  border-radius: 12px;
  width: 376px;
  height: 816px;
  position: absolute;
  top: -8px;
  left: 537px;
  overflow: hidden;
}

.element .frame-572 {
  justify-content: space-between;
  align-items: center;
  width: 328px;
  display: flex;
  position: relative;
  top: 12px;
  left: 16px;
}

.element .frame-573 {
  background-image: url("ellipse-1.36709151.png");
  background-position: 50%;
  background-size: cover;
  width: 32px;
  height: 32px;
  position: relative;
}

.element .blog-3 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5px;
  width: 360px;
  height: 200px;
  display: flex;
  position: absolute;
  top: 116px;
  left: 0;
}

.element .frame-574 {
  background-image: url("image-5.ad12be1f.png");
  background-size: 100% 100%;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.element .frame-575 {
  align-items: flex-start;
  width: 328px;
  display: flex;
  position: absolute;
  top: 64px;
  left: 16px;
}

.element .div-container-3 {
  background-color: #fff;
  border-color: #cac4c2;
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
  border-radius: 40px 0 0 40px;
  flex: 1;
  align-items: flex-start;
  padding: 4px 4px 4px 17px;
  display: flex;
  position: relative;
  box-shadow: inset 0 1px 2px #eee;
}

.element .search-6 {
  flex: 1;
  align-items: center;
  padding: 4px 0;
  display: flex;
  position: relative;
}

.element .div-placeholder-5 {
  flex: 1;
  align-items: flex-start;
  padding: 0 469px 0 0;
  display: flex;
  position: relative;
}

.element .text-wrapper-289 {
  color: #686462;
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -277px;
  position: relative;
}

.element .search-7 {
  width: 70px;
  position: relative;
}

.element .frame-576 {
  align-items: flex-start;
  gap: 16px;
  width: 328px;
  display: flex;
  position: absolute;
  top: 348px;
  left: 16px;
}

.element .content-container {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 280px;
  display: flex;
  position: relative;
}

.element .frame-577 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.element .button-tall-fixed-22 {
  background-color: #826aed;
  border-radius: 4px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 8px;
  display: inline-flex;
  position: relative;
}

.element .text-70 {
  color: #fff;
  letter-spacing: .2px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  position: relative;
}

.element .text-71 {
  color: #1f1c1a;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  position: relative;
}

.element .element-types-of-2 {
  color: #1f1c1a;
  font-family: var(--mobile-h2-DMI-font-family);
  font-size: var(--mobile-h2-DMI-font-size);
  font-style: var(--mobile-h2-DMI-font-style);
  font-weight: var(--mobile-h2-DMI-font-weight);
  letter-spacing: var(--mobile-h2-DMI-letter-spacing);
  line-height: var(--mobile-h2-DMI-line-height);
  width: 280px;
  position: relative;
}

.element .line-77 {
  object-fit: cover;
  width: 328px;
  height: 1px;
  margin-right: -48px;
  position: relative;
}

.element .frame-578 {
  width: 32px;
  height: 32px;
  position: relative;
}

.element .bookmark-add-wrapper {
  background-color: #eee;
  border-radius: 14.4px;
  width: 29px;
  height: 29px;
  position: relative;
  top: 2px;
  left: 2px;
}

.element .bookmark-add-2 {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.element .frame-579 {
  align-items: flex-start;
  gap: 26px;
  display: inline-flex;
  position: absolute;
  top: 520px;
  left: 16px;
}

.element .frame-580 {
  width: 153px;
  height: 46px;
  position: relative;
}

.element .overlap-49 {
  height: 46px;
  position: relative;
}

.element .frame-581 {
  align-items: center;
  gap: 2px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-group-23 {
  border: .36px solid #f3eff5;
  border-radius: 14.55px;
  width: 29px;
  height: 29px;
  position: relative;
  top: 1px;
  left: 1px;
}

.element .ellipse-39 {
  object-fit: cover;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.element .text-wrapper-290 {
  color: #373432;
  font-family: var(--mobile-paragraph-DMI-font-family);
  font-size: var(--mobile-paragraph-DMI-font-size);
  font-style: var(--mobile-paragraph-DMI-font-style);
  font-weight: var(--mobile-paragraph-DMI-font-weight);
  letter-spacing: var(--mobile-paragraph-DMI-letter-spacing);
  line-height: var(--mobile-paragraph-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-582 {
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 26px;
  left: 34px;
}

.element .text-72 {
  color: #999492;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  position: relative;
}

.element .text-73 {
  color: var(--neutrals-30);
  font-family: var(--mobile-caption-DMI-font-family);
  font-size: var(--mobile-caption-DMI-font-size);
  font-style: var(--mobile-caption-DMI-font-style);
  font-weight: var(--mobile-caption-DMI-font-weight);
  letter-spacing: var(--mobile-caption-DMI-letter-spacing);
  line-height: var(--mobile-caption-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-583 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 328px;
  display: flex;
  position: absolute;
  top: 590px;
  left: 16px;
}

.element .lorem-ipsum-is-10 {
  color: #686462;
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
  width: 328px;
  height: 245px;
  margin-top: -1px;
  position: relative;
}

.element .text-74 {
  width: 328px;
  height: 232px;
  position: relative;
}

.element .lorem-ipsum-is-11 {
  color: #0000;
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
  width: 328px;
  height: 279px;
  position: absolute;
  top: -22px;
  left: 0;
}

.element .text-wrapper-291 {
  color: #686462;
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
}

.element .text-wrapper-292 {
  color: #075a5e;
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
}

.element .frame-584 {
  border-radius: 4px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 328px;
  display: flex;
  position: relative;
}

.element .lorem-ipsum-is-12 {
  color: #0000;
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-293 {
  color: #373432;
  font-family: var(--mobile-h5-DMI-font-family);
  font-size: var(--mobile-h5-DMI-font-size);
  font-style: var(--mobile-h5-DMI-font-style);
  font-weight: var(--mobile-h5-DMI-font-weight);
  letter-spacing: var(--mobile-h5-DMI-letter-spacing);
  line-height: var(--mobile-h5-DMI-line-height);
  width: 321px;
  position: relative;
}

.element .lorem-ipsum-is-13 {
  color: #0000;
  font-family: var(--mobile-paragraph-REG-font-family);
  font-size: var(--mobile-paragraph-REG-font-size);
  font-style: var(--mobile-paragraph-REG-font-style);
  font-weight: var(--mobile-paragraph-REG-font-weight);
  letter-spacing: var(--mobile-paragraph-REG-letter-spacing);
  line-height: var(--mobile-paragraph-REG-line-height);
  width: 328px;
  position: relative;
}

.element .line-78 {
  object-fit: cover;
  width: 328px;
  height: 1px;
  margin-top: -17749px;
  margin-right: -454px;
  position: relative;
}

.element .frame-585 {
  flex: none;
  align-items: center;
  gap: 16px;
  width: 328px;
  display: flex;
  position: relative;
}

.element .maps-ugc-3 {
  width: 20px;
  height: 20px;
  margin-top: -17765px;
  margin-right: -386px;
  position: relative;
}

.element .ios-share-3 {
  width: 20px;
  height: 20px;
  margin-top: -17765px;
  margin-right: -307px;
  position: relative;
}

.element .frame-586 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  width: 328px;
  display: flex;
  position: relative;
}

.element .text-75 {
  color: #373432;
  font-family: var(--mobile-h6-DMI-font-family);
  font-size: var(--mobile-h6-DMI-font-size);
  font-style: var(--mobile-h6-DMI-font-style);
  font-weight: var(--mobile-h6-DMI-font-weight);
  letter-spacing: var(--mobile-h6-DMI-letter-spacing);
  line-height: var(--mobile-h6-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-294 {
  color: #373432;
  font-family: var(--mobile-caption-DMI-font-family);
  font-size: var(--mobile-caption-DMI-font-size);
  font-style: var(--mobile-caption-DMI-font-style);
  font-weight: var(--mobile-caption-DMI-font-weight);
  letter-spacing: var(--mobile-caption-DMI-letter-spacing);
  line-height: var(--mobile-caption-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-587 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 328px;
  display: flex;
  position: absolute;
  top: 1941px;
  left: 16px;
}

.element .recommended-topics-2 {
  color: #0000;
  font-family: var(--mobile-h6-DMI-font-family);
  font-size: var(--mobile-h6-DMI-font-size);
  font-style: var(--mobile-h6-DMI-font-style);
  font-weight: var(--mobile-h6-DMI-font-weight);
  letter-spacing: var(--mobile-h6-DMI-letter-spacing);
  line-height: var(--mobile-h6-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-295 {
  color: #000;
  font-family: var(--mobile-h6-DMI-font-family);
  font-size: var(--mobile-h6-DMI-font-size);
  font-style: var(--mobile-h6-DMI-font-style);
  font-weight: var(--mobile-h6-DMI-font-weight);
  letter-spacing: var(--mobile-h6-DMI-letter-spacing);
  line-height: var(--mobile-h6-DMI-line-height);
}

.element .text-wrapper-296 {
  color: #44bba4;
  font-family: var(--mobile-h6-DMI-font-family);
  font-size: var(--mobile-h6-DMI-font-size);
  font-style: var(--mobile-h6-DMI-font-style);
  font-weight: var(--mobile-h6-DMI-font-weight);
  letter-spacing: var(--mobile-h6-DMI-letter-spacing);
  line-height: var(--mobile-h6-DMI-line-height);
}

.element .button-tall-fixed-23 {
  background-color: var(--neutral-20);
  border-radius: 30px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 4px 8px;
  display: inline-flex;
  position: relative;
}

.element .text-76 {
  color: #373432;
  font-family: var(--mobile-caption-DMI-font-family);
  font-size: var(--mobile-caption-DMI-font-size);
  font-style: var(--mobile-caption-DMI-font-style);
  font-weight: var(--mobile-caption-DMI-font-weight);
  letter-spacing: var(--mobile-caption-DMI-letter-spacing);
  line-height: var(--mobile-caption-DMI-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .button-tall-fixed-24 {
  background-color: var(--neutral-20);
  border-radius: 30px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.element .frame-588 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-25 {
  background-color: var(--neutral-20);
  border-radius: 30px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 12px;
  display: inline-flex;
  position: relative;
}

.element .newsletter-3 {
  background-color: #075a5e;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 328px;
  padding: 16px 16px 24px;
  display: flex;
  position: absolute;
  top: 2104px;
  left: 16px;
}

.element .text-wrapper-297 {
  color: #fff;
  font-family: var(--mobile-h6-DMI-font-family);
  font-size: var(--mobile-h6-DMI-font-size);
  font-style: var(--mobile-h6-DMI-font-style);
  font-weight: var(--mobile-h6-DMI-font-weight);
  letter-spacing: var(--mobile-h6-DMI-letter-spacing);
  line-height: var(--mobile-h6-DMI-line-height);
  align-self: stretch;
  position: relative;
}

.element .frame-589 {
  border: 1px solid #cac4c2;
  border-radius: 4px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-298 {
  color: var(--neutral-30);
  font-family: var(--mobile-paragraph-DMI-font-family);
  font-size: var(--mobile-paragraph-DMI-font-size);
  font-style: var(--mobile-paragraph-DMI-font-style);
  font-weight: var(--mobile-paragraph-DMI-font-weight);
  letter-spacing: var(--mobile-paragraph-DMI-letter-spacing);
  line-height: var(--mobile-paragraph-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-590 {
  background-color: var(--primary-02);
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.element .text-wrapper-299 {
  color: var(--neutral-30);
  font-family: var(--mobile-paragraph-BLD-font-family);
  font-size: var(--mobile-paragraph-BLD-font-size);
  font-style: var(--mobile-paragraph-BLD-font-style);
  font-weight: var(--mobile-paragraph-BLD-font-weight);
  letter-spacing: var(--mobile-paragraph-BLD-letter-spacing);
  line-height: var(--mobile-paragraph-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-591 {
  background-color: #f6f6f6;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 16px 16px 24px;
  display: inline-flex;
  position: absolute;
  top: 2346px;
  left: 0;
}

.element .frame-592 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 328px;
  display: flex;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
}

.element .text-wrapper-300 {
  color: #075a5e;
  font-family: var(--mobile-h5-DMI-font-family);
  font-size: var(--mobile-h5-DMI-font-size);
  font-style: var(--mobile-h5-DMI-font-style);
  font-weight: var(--mobile-h5-DMI-font-weight);
  letter-spacing: var(--mobile-h5-DMI-letter-spacing);
  line-height: var(--mobile-h5-DMI-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .frame-593 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 246px;
  display: flex;
  position: relative;
}

.element .frame-594 {
  background-image: url("rectangle-17-2.6a3ffb0d.png");
  background-position: 50%;
  background-size: cover;
  width: 246px;
  height: 140px;
  position: relative;
}

.element .frame-595 {
  flex: none;
  align-items: center;
  gap: 1.95px;
  width: 190px;
  display: flex;
  position: relative;
}

.element .button-tall-fixed-26 {
  background-color: #826aed;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 6px;
  display: inline-flex;
  position: relative;
}

.element .text-77 {
  color: var(--neutrals-0);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Bold, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  position: relative;
}

.element .frame-596 {
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.element .text-78 {
  color: var(--neutrals-10);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.element .text-wrapper-301 {
  color: #5b5f5d;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  position: relative;
}

.element .text-wrapper-302 {
  color: var(--neutral-70);
  font-family: var(--mobile-paragraph-DMI-font-family);
  font-size: var(--mobile-paragraph-DMI-font-size);
  font-style: var(--mobile-paragraph-DMI-font-style);
  font-weight: var(--mobile-paragraph-DMI-font-weight);
  letter-spacing: var(--mobile-paragraph-DMI-letter-spacing);
  line-height: var(--mobile-paragraph-DMI-line-height);
  align-self: stretch;
  position: relative;
}

.element .text-79 {
  color: var(--neutrals-10);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  position: relative;
}

.element .text-80 {
  color: var(--neutrals-30);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Articulat CF-Medium, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  position: relative;
}

.element .frame-597 {
  background-image: url("rectangle-17-1.a8ea4e11.png");
  background-position: 50%;
  background-size: cover;
  width: 246px;
  height: 140px;
  position: relative;
}

.element .frame-598 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  width: 246px;
  display: flex;
  position: relative;
}

.element .text-wrapper-303 {
  color: var(--neutral-70);
  font-family: var(--mobile-paragraph-DMI-font-family);
  font-size: var(--mobile-paragraph-DMI-font-size);
  font-style: var(--mobile-paragraph-DMI-font-style);
  font-weight: var(--mobile-paragraph-DMI-font-weight);
  letter-spacing: var(--mobile-paragraph-DMI-letter-spacing);
  line-height: var(--mobile-paragraph-DMI-line-height);
  width: 246px;
  position: relative;
}

.element .frame-599 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.element .frame-600 {
  background-image: url("rectangle-17.0a29b490.png");
  background-position: 50%;
  background-size: cover;
  width: 246px;
  height: 140px;
  position: relative;
}

.element .frame-601 {
  background-color: #1f1c1a;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 16px 16px 24px;
  display: inline-flex;
  position: absolute;
  top: 2666px;
  left: 0;
}

.element .frame-602 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.element .frame-603 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 328px;
  display: flex;
  position: relative;
}

.element .frame-604 {
  flex: none;
  margin-top: -18686px;
  margin-right: -206px;
  position: relative;
}

.element .frame-605 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 8px;
  margin-left: -383px;
  display: inline-flex;
  position: relative;
}

.element .text-wrapper-304 {
  color: var(--primary-light);
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-305 {
  color: var(--primary-light);
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-606 {
  flex: none;
  margin-top: -18754px;
  margin-right: -294px;
  position: relative;
}

.element .frame-607 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.element .divider-10 {
  object-fit: cover;
  width: 328px;
  height: 1px;
  margin-top: -18866px;
  margin-right: -454px;
  position: relative;
}

.element .text-wrapper-306 {
  color: var(--primary-light);
  font-family: var(--mobile-tiny-reg-font-family);
  font-size: var(--mobile-tiny-reg-font-size);
  font-style: var(--mobile-tiny-reg-font-style);
  font-weight: var(--mobile-tiny-reg-font-weight);
  letter-spacing: var(--mobile-tiny-reg-letter-spacing);
  line-height: var(--mobile-tiny-reg-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.element .frame-608 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 19989px;
  left: 206px;
}

.element .text-wrapper-307 {
  color: #000;
  letter-spacing: -.17px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-308 {
  color: #000;
  letter-spacing: -.24px;
  width: 1507.98px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.element .group-15 {
  width: 1920px;
  height: 540px;
  position: absolute;
  top: 20274px;
  left: 0;
}

.element .mask-group-wrapper {
  background-image: url("rectangle-122.fe1b7918.svg");
  background-size: 100% 100%;
  height: 539px;
  position: relative;
}

.element .mask-group {
  width: 1920px;
  height: 539px;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.5fe78bb7.css.map */
