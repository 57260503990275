.state-default-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  height: 500px;
  justify-content: center;
  padding: 40px 75px 56px;
  position: relative;
}

.state-default-wrapper .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  margin-bottom: -4.5px;
  margin-top: -4.5px;
  position: relative;
}

.state-default-wrapper .frame-4 {
  align-self: stretch;
  height: 191.15px;
  margin-top: -0.44px;
  position: relative;
  width: 100%;
}

.state-default-wrapper .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.state-default-wrapper .frame-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.state-default-wrapper .text-wrapper-2 {
  align-self: stretch;
  font-family: var(--web-h5-DMI-font-family);
  font-size: var(--web-h5-DMI-font-size);
  font-style: var(--web-h5-DMI-font-style);
  font-weight: var(--web-h5-DMI-font-weight);
  letter-spacing: var(--web-h5-DMI-letter-spacing);
  line-height: var(--web-h5-DMI-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.state-default-wrapper .discover-the-best {
  font-family: var(--web-paragraph-REG-font-family);
  font-size: var(--web-paragraph-REG-font-size);
  font-style: var(--web-paragraph-REG-font-style);
  font-weight: var(--web-paragraph-REG-font-weight);
  letter-spacing: var(--web-paragraph-REG-letter-spacing);
  line-height: var(--web-paragraph-REG-line-height);
  position: relative;
  text-align: center;
  width: 330px;
}

.state-default-wrapper .button-2 {
  all: unset;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.state-default-wrapper .button-base-2 {
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  overflow: hidden;
  padding: 16px 14px;
  position: relative;
}

.state-default-wrapper .text-3 {
  font-family: "Articulat CF-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.state-default-wrapper.state-3-default {
  background-color: #ffffff;
}

.state-default-wrapper.state-3-hover {
  background-color: #075a5e;
}

.state-default-wrapper.state-3-default .text-wrapper-2 {
  color: #1f1c1a;
}

.state-default-wrapper.state-3-hover .text-wrapper-2 {
  color: #ffffff;
}

.state-default-wrapper.state-3-default .discover-the-best {
  color: #686462;
}

.state-default-wrapper.state-3-hover .discover-the-best {
  color: var(--neutral-30);
}

.state-default-wrapper.state-3-default .button-base-2 {
  border-color: #075a5e;
}

.state-default-wrapper.state-3-hover .button-base-2 {
  border-color: #ffffff;
}

.state-default-wrapper.state-3-default .text-3 {
  color: #075a5e;
}

.state-default-wrapper.state-3-hover .text-3 {
  color: #ffffff;
}
