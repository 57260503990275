:root {
  --accent-01: rgba(244, 255, 248, 1);
  --accent-02: rgba(224, 251, 252, 1);
  --accent-03: rgba(233, 230, 255, 1);
  --accent-04: rgba(255, 221, 210, 1);
  --accent-05: rgba(255, 252, 249, 1);
  --baseblack: rgba(0, 0, 0, 1);
  --basewhite: rgba(255, 255, 255, 1);
  --body-medium-font-family: "PlusJakarta Sans-Regular", Helvetica;
  --body-medium-font-size: 16px;
  --body-medium-font-style: normal;
  --body-medium-font-weight: 400;
  --body-medium-letter-spacing: 0px;
  --body-medium-line-height: 150%;
  --caption-01-SEMI-BLD-font-family: "Inter-SemiBold", Helvetica;
  --caption-01-SEMI-BLD-font-size: 14px;
  --caption-01-SEMI-BLD-font-style: normal;
  --caption-01-SEMI-BLD-font-weight: 600;
  --caption-01-SEMI-BLD-letter-spacing: -0.056000000834465026px;
  --caption-01-SEMI-BLD-line-height: 139.9999976158142%;
  --caption-02-SEMI-BLD-font-family: "Inter-SemiBold", Helvetica;
  --caption-02-SEMI-BLD-font-size: 12px;
  --caption-02-SEMI-BLD-font-style: normal;
  --caption-02-SEMI-BLD-font-weight: 600;
  --caption-02-SEMI-BLD-letter-spacing: 0px;
  --caption-02-SEMI-BLD-line-height: 139.9999976158142%;
  --COMPONENT-BODY-SEMIBOLD-font-family: "Inter-SemiBold", Helvetica;
  --COMPONENT-BODY-SEMIBOLD-font-size: 14px;
  --COMPONENT-BODY-SEMIBOLD-font-style: normal;
  --COMPONENT-BODY-SEMIBOLD-font-weight: 600;
  --COMPONENT-BODY-SEMIBOLD-letter-spacing: 0px;
  --COMPONENT-BODY-SEMIBOLD-line-height: 100%;
  --error-60: rgba(255, 109, 125, 1);
  --h5-font-family: "PlusJakarta Sans-Bold", Helvetica;
  --h5-font-size: 18px;
  --h5-font-style: normal;
  --h5-font-weight: 700;
  --h5-letter-spacing: 0px;
  --h5-line-height: normal;
  --inter-semi-bold-16px-font-family: "Inter-Medium", Helvetica;
  --inter-semi-bold-16px-font-size: 16px;
  --inter-semi-bold-16px-font-style: normal;
  --inter-semi-bold-16px-font-weight: 500;
  --inter-semi-bold-16px-letter-spacing: 0px;
  --inter-semi-bold-16px-line-height: 24px;
  --linearwhite: rgba(255, 255, 255, 1);
  --mobile-caption-BLD-font-family: "ArticulatCF-Bold", Helvetica;
  --mobile-caption-BLD-font-size: 12px;
  --mobile-caption-BLD-font-style: normal;
  --mobile-caption-BLD-font-weight: 700;
  --mobile-caption-BLD-letter-spacing: 0px;
  --mobile-caption-BLD-line-height: 120.00000476837158%;
  --mobile-caption-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --mobile-caption-DMI-font-size: 12px;
  --mobile-caption-DMI-font-style: normal;
  --mobile-caption-DMI-font-weight: 700;
  --mobile-caption-DMI-letter-spacing: 0px;
  --mobile-caption-DMI-line-height: 120.00000476837158%;
  --mobile-caption-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --mobile-caption-REG-font-size: 12px;
  --mobile-caption-REG-font-style: normal;
  --mobile-caption-REG-font-weight: 500;
  --mobile-caption-REG-letter-spacing: 0px;
  --mobile-caption-REG-line-height: 120.00000476837158%;
  --mobile-h2-DMI-font-family: "LeMonde Livre Std-Demi", Helvetica;
  --mobile-h2-DMI-font-size: 28px;
  --mobile-h2-DMI-font-style: normal;
  --mobile-h2-DMI-font-weight: 400;
  --mobile-h2-DMI-letter-spacing: -0.7839999866485595px;
  --mobile-h2-DMI-line-height: 120.00000476837158%;
  --mobile-h3-BLD-font-family: "LeMonde Livre Std-Bold", Helvetica;
  --mobile-h3-BLD-font-size: 25px;
  --mobile-h3-BLD-font-style: normal;
  --mobile-h3-BLD-font-weight: 700;
  --mobile-h3-BLD-letter-spacing: -0.3499999940395355px;
  --mobile-h3-BLD-line-height: 120.00000476837158%;
  --mobile-h4-BLD-font-family: "LeMonde Livre Std-Bold", Helvetica;
  --mobile-h4-BLD-font-size: 24px;
  --mobile-h4-BLD-font-style: normal;
  --mobile-h4-BLD-font-weight: 700;
  --mobile-h4-BLD-letter-spacing: -0.12px;
  --mobile-h4-BLD-line-height: 120.00000476837158%;
  --mobile-h5-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --mobile-h5-DMI-font-size: 20px;
  --mobile-h5-DMI-font-style: normal;
  --mobile-h5-DMI-font-weight: 700;
  --mobile-h5-DMI-letter-spacing: -0.1600000023841858px;
  --mobile-h5-DMI-line-height: 120.00000476837158%;
  --mobile-h6-DMI-font-family: "LeMonde Livre Cla Std-Demi", Helvetica;
  --mobile-h6-DMI-font-size: 18px;
  --mobile-h6-DMI-font-style: normal;
  --mobile-h6-DMI-font-weight: 400;
  --mobile-h6-DMI-letter-spacing: -0.07200000107288361px;
  --mobile-h6-DMI-line-height: 139.9999976158142%;
  --mobile-h6-REG-font-family: "LeMonde Livre Cla Std-Regular", Helvetica;
  --mobile-h6-REG-font-size: 18px;
  --mobile-h6-REG-font-style: normal;
  --mobile-h6-REG-font-weight: 400;
  --mobile-h6-REG-letter-spacing: 0px;
  --mobile-h6-REG-line-height: 139.9999976158142%;
  --mobile-paragraph-BLD-font-family: "ArticulatCF-Bold", Helvetica;
  --mobile-paragraph-BLD-font-size: 14px;
  --mobile-paragraph-BLD-font-style: normal;
  --mobile-paragraph-BLD-font-weight: 700;
  --mobile-paragraph-BLD-letter-spacing: 0px;
  --mobile-paragraph-BLD-line-height: 139.9999976158142%;
  --mobile-paragraph-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --mobile-paragraph-DMI-font-size: 14px;
  --mobile-paragraph-DMI-font-style: normal;
  --mobile-paragraph-DMI-font-weight: 700;
  --mobile-paragraph-DMI-letter-spacing: 0px;
  --mobile-paragraph-DMI-line-height: 139.9999976158142%;
  --mobile-paragraph-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --mobile-paragraph-REG-font-size: 14px;
  --mobile-paragraph-REG-font-style: normal;
  --mobile-paragraph-REG-font-weight: 500;
  --mobile-paragraph-REG-letter-spacing: 0px;
  --mobile-paragraph-REG-line-height: 139.9999976158142%;
  --mobile-tiny-MED-font-family: "ArticulatCF-Medium", Helvetica;
  --mobile-tiny-MED-font-size: 11px;
  --mobile-tiny-MED-font-style: normal;
  --mobile-tiny-MED-font-weight: 500;
  --mobile-tiny-MED-letter-spacing: 0px;
  --mobile-tiny-MED-line-height: 139.9999976158142%;
  --mobile-tiny-reg-font-family: "ArticulatCF-Regular", Helvetica;
  --mobile-tiny-reg-font-size: 11px;
  --mobile-tiny-reg-font-style: normal;
  --mobile-tiny-reg-font-weight: 400;
  --mobile-tiny-reg-letter-spacing: 0px;
  --mobile-tiny-reg-line-height: 139.9999976158142%;
  --neutral-10: rgba(255, 255, 255, 1);
  --neutral-100: rgba(255, 255, 255, 1);
  --neutral-20: rgba(251, 251, 251, 1);
  --neutral-30: rgba(238, 238, 238, 1);
  --neutral-40: rgba(102, 102, 102, 1);
  --neutral-50: rgba(105, 100, 98, 1);
  --neutral-60: rgba(80, 76, 74, 1);
  --neutral-70: rgba(56, 52, 50, 1);
  --neutral-80: rgba(31, 28, 26, 1);
  --neutral-90: rgba(7, 4, 2, 1);
  --neutral-dark-grey: rgba(126, 126, 126, 1);
  --neutral-grey: rgba(176, 176, 176, 1);
  --neutrals-0: rgba(255, 255, 255, 1);
  --neutrals-10: rgba(173, 175, 174, 1);
  --neutrals-30: rgba(92, 96, 94, 1);
  --neutralwhite: rgba(255, 255, 255, 1);
  --overlay-32: rgba(255, 255, 255, 0.24);
  --paragraph-BLD-font-family: "Inter-Bold", Helvetica;
  --paragraph-BLD-font-size: 16px;
  --paragraph-BLD-font-style: normal;
  --paragraph-BLD-font-weight: 700;
  --paragraph-BLD-letter-spacing: -0.06400000095367432px;
  --paragraph-BLD-line-height: 139.9999976158142%;
  --paragraph-SEMI-BLD-font-family: "Inter-SemiBold", Helvetica;
  --paragraph-SEMI-BLD-font-size: 16px;
  --paragraph-SEMI-BLD-font-style: normal;
  --paragraph-SEMI-BLD-font-weight: 600;
  --paragraph-SEMI-BLD-letter-spacing: -0.06400000095367432px;
  --paragraph-SEMI-BLD-line-height: 139.9999976158142%;
  --primary-01: rgba(41, 77, 74, 1);
  --primary-02: rgba(68, 187, 164, 1);
  --primary-03: rgba(243, 239, 245, 1);
  --primary-20: rgba(60, 125, 243, 1);
  --primary-30: rgba(81, 140, 245, 1);
  --primary-98: rgba(245, 249, 255, 1);
  --primary-light: rgba(220, 242, 237, 1);
  --secondary-02: rgba(47, 82, 224, 1);
  --secondary-03: rgba(130, 106, 237, 1);
  --secondary-04: rgba(247, 135, 100, 1);
  --secondary-05: rgba(247, 179, 43, 1);
  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --SUBHEAD-BLD-font-family: "Inter-Bold", Helvetica;
  --SUBHEAD-BLD-font-size: 18px;
  --SUBHEAD-BLD-font-style: normal;
  --SUBHEAD-BLD-font-weight: 700;
  --SUBHEAD-BLD-letter-spacing: -0.14400000214576722px;
  --SUBHEAD-BLD-line-height: 120.00000476837158%;
  --tertiary-01: rgba(16, 66, 18, 1);
  --tertiary-02: rgba(8, 3, 87, 1);
  --tertiary-05: rgba(200, 150, 62, 1);
  --ui-essentialsneutralntrl100: rgba(255, 255, 255, 1);
  --web-caption-BLD-font-family: "ArticulatCF-Bold", Helvetica;
  --web-caption-BLD-font-size: 14px;
  --web-caption-BLD-font-style: normal;
  --web-caption-BLD-font-weight: 700;
  --web-caption-BLD-letter-spacing: 0px;
  --web-caption-BLD-line-height: 139.9999976158142%;
  --web-caption-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --web-caption-DMI-font-size: 14px;
  --web-caption-DMI-font-style: normal;
  --web-caption-DMI-font-weight: 700;
  --web-caption-DMI-letter-spacing: 0px;
  --web-caption-DMI-line-height: 139.9999976158142%;
  --web-caption-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --web-caption-REG-font-size: 14px;
  --web-caption-REG-font-style: normal;
  --web-caption-REG-font-weight: 500;
  --web-caption-REG-letter-spacing: 0px;
  --web-caption-REG-line-height: 139.9999976158142%;
  --web-h2-DMI-font-family: "LeMonde Livre Std-Demi", Helvetica;
  --web-h2-DMI-font-size: 48px;
  --web-h2-DMI-font-style: normal;
  --web-h2-DMI-font-weight: 400;
  --web-h2-DMI-letter-spacing: -1.3439999771118165px;
  --web-h2-DMI-line-height: 120.00000476837158%;
  --web-h3-bold-font-family: "LeMonde Livre Std-Bold", Helvetica;
  --web-h3-bold-font-size: 44px;
  --web-h3-bold-font-style: normal;
  --web-h3-bold-font-weight: 700;
  --web-h3-bold-letter-spacing: -0.6159999895095826px;
  --web-h3-bold-line-height: 120.00000476837158%;
  --web-h5-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --web-h5-DMI-font-size: 28px;
  --web-h5-DMI-font-style: normal;
  --web-h5-DMI-font-weight: 700;
  --web-h5-DMI-letter-spacing: -0.2240000033378601px;
  --web-h5-DMI-line-height: 120.00000476837158%;
  --web-h6-DMI-font-family: "LeMonde Livre Cla Std-Demi", Helvetica;
  --web-h6-DMI-font-size: 20px;
  --web-h6-DMI-font-style: normal;
  --web-h6-DMI-font-weight: 400;
  --web-h6-DMI-letter-spacing: -0.0800000011920929px;
  --web-h6-DMI-line-height: 139.9999976158142%;
  --web-paragraph-BLD-font-family: "ArticulatCF-Bold", Helvetica;
  --web-paragraph-BLD-font-size: 16px;
  --web-paragraph-BLD-font-style: normal;
  --web-paragraph-BLD-font-weight: 700;
  --web-paragraph-BLD-letter-spacing: 0px;
  --web-paragraph-BLD-line-height: 139.9999976158142%;
  --web-paragraph-DMI-font-family: "ArticulatCF-DemiBold", Helvetica;
  --web-paragraph-DMI-font-size: 16px;
  --web-paragraph-DMI-font-style: normal;
  --web-paragraph-DMI-font-weight: 700;
  --web-paragraph-DMI-letter-spacing: 0px;
  --web-paragraph-DMI-line-height: 139.9999976158142%;
  --web-paragraph-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --web-paragraph-REG-font-size: 16px;
  --web-paragraph-REG-font-style: normal;
  --web-paragraph-REG-font-weight: 500;
  --web-paragraph-REG-letter-spacing: 0px;
  --web-paragraph-REG-line-height: 139.9999976158142%;
  --web-tiny-MED-font-family: "ArticulatCF-DemiBold", Helvetica;
  --web-tiny-MED-font-size: 12px;
  --web-tiny-MED-font-style: normal;
  --web-tiny-MED-font-weight: 700;
  --web-tiny-MED-letter-spacing: 0px;
  --web-tiny-MED-line-height: 139.9999976158142%;
  --web-tiny-REG-font-family: "ArticulatCF-Medium", Helvetica;
  --web-tiny-REG-font-size: 12px;
  --web-tiny-REG-font-style: normal;
  --web-tiny-REG-font-weight: 500;
  --web-tiny-REG-letter-spacing: 0px;
  --web-tiny-REG-line-height: 139.9999976158142%;
  --white: rgba(255, 255, 255, 1);
  --x-20: rgba(226, 223, 222, 1);
}
