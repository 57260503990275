.nav-link {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 4px;
  position: relative;
}

.nav-link .container {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 2px 0px;
  position: relative;
}

.nav-link .placeholder {
  font-family: var(--web-caption-REG-font-family);
  font-size: var(--web-caption-REG-font-size);
  font-style: var(--web-caption-REG-font-style);
  font-weight: var(--web-caption-REG-font-weight);
  letter-spacing: var(--web-caption-REG-letter-spacing);
  line-height: var(--web-caption-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.nav-link .selected {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.nav-link .disabled {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #cac4c2;
}

.nav-link .dark-false.selected {
  border-color: var(--primary-02);
}

.nav-link .selected.dark-true {
  border-color: #ffffff;
}

.nav-link .container.disabled .placeholder {
  color: #cac4c2;
}

.nav-link .container.default.dark-true .placeholder {
  color: #ffffff;
}

.nav-link .container.default.dark-false .placeholder {
  color: #1f1c1a;
}

.nav-link .container.hover.dark-false .placeholder {
  color: #00000033;
}

.nav-link .container.dark-false.selected .placeholder {
  color: var(--primary-02);
}

.nav-link .container.selected.dark-true .placeholder {
  color: #ffffff;
}

.nav-link .container.hover.dark-true .placeholder {
  color: var(--accent-02);
}
